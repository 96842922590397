/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';
import { getCopayCost } from '@/Components/Steps/RegisterWithBHCopay';
import { useHomePageActions, useHomePageState } from '@/Components/HomePage';

/**
 * This hook returns two pieces of information:
 *
 * - The estimated amount that the user's insurance will cover.
 * - The estimated maximum monthly cost to the user.
 */
export const useEstimatedCost = () => {
  const { getMaxCostByFlowConfigOrEligibility, getFlowConfig } = useHomePageActions();
  const { redirectFrom, organizationFlowID, partnerFlowID, copayCents, service } =
    useHomePageState();

  return useMemo(() => {
    const finalFlowID = Number(redirectFrom || organizationFlowID || partnerFlowID);
    const flowConfig = getFlowConfig(finalFlowID);
    const copayCentsNumber = typeof copayCents === 'string' ? parseInt(copayCents, 10) : copayCents;
    const maxCost = getMaxCostByFlowConfigOrEligibility(flowConfig, service);
    let specifyCopay = true;

    let copay = 0;
    if (copayCentsNumber && copayCentsNumber > 0) {
      copay = getCopayCost(copayCentsNumber, maxCost);
    }

    let perSession = copay;
    if (flowConfig?.secondaryInsurance?.enabled) {
      perSession = parseFloat((maxCost * 0.2).toFixed(2));
      specifyCopay = false;
    }

    let perSessionMin: number | undefined;
    if (flowConfig?.secondaryInsurance?.enabled && perSession) {
      perSessionMin = 0;
    }

    return {
      perSessionMin,
      perSession,
      maxCost,
      specifyCopay,
    };
  }, [
    service,
    copayCents,
    redirectFrom,
    organizationFlowID,
    partnerFlowID,
    getMaxCostByFlowConfigOrEligibility,
    getFlowConfig,
  ]);
};
