import { useEffect, useState } from 'react';
import * as yup from 'yup';

import { Trans, useTranslation } from '@talkspace/i18n';
import {
  Button,
  EmotionThemeProvider,
  RHFCheckbox,
  RHFInput,
  RHFPhoneInput,
  RHFSelect,
  TextDS,
  View,
  styled,
  PolicyLink,
  isPhoneNumberValid,
} from '@talkspace/react-toolkit';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormProvider, useForm } from 'react-hook-form';

import useMutationConsenterReferral from 'ts-frontend/hooks/useMutationConsenterReferral';
import { UpdateStep, TeenConsenterReferralFormStep } from '../../../Flows';
import TeenConsenterBanner from '../../TeenBanner';
import { useHomePageState, useHomePageActions } from '../../HomePage';
import { trackEvent } from '../../../utils/analytics/eventTracker';

const CONTENT_WIDTH = 344;

const Styled = {
  Container: styled(View)({ alignItems: 'center', width: CONTENT_WIDTH }),
  Title: styled(TextDS)(({ theme: { spacing } }) => {
    return {
      marginTop: spacing('space400'),
    };
  }),
  Description: styled(TextDS)(({ theme: { spacing } }) => {
    return {
      marginTop: spacing('space150'),
    };
  }),
  FormContainer: styled(View)(({ theme: { spacing } }) => {
    return {
      width: '100%',
      minWidth: CONTENT_WIDTH,
      alignItems: 'center',
      marginTop: spacing('space200'),
    };
  }),
  InputRowContainer: styled(View)(({ theme: { spacing } }) => {
    return { flexDirection: 'row', width: '100%', gap: spacing('space100') };
  }),
  InputRowItem: styled(View)({
    flex: 1,
  }),
  InputFullWidth: styled(RHFInput)({ minWidth: CONTENT_WIDTH }),
  SelectFullWidth: styled(RHFSelect)({ minWidth: CONTENT_WIDTH }),
  PhoneInput: styled(RHFPhoneInput)({ minWidth: CONTENT_WIDTH }),
  CTAButton: styled(Button)(() => {
    return { minWidth: CONTENT_WIDTH };
  }),
  CTAButtonContainer: styled(View)(({ theme: { spacing } }) => {
    return { marginTop: spacing('space400') };
  }),
  Error: styled(TextDS)(({ theme: { spacing } }) => {
    return {
      marginTop: spacing('space100'),
    };
  }),
};

const GENERIC_ERROR = 'Something went wrong. Please try again.';
const REQUIRED_ERROR = 'Required';
const INVALID_PHONE_NUMBER_ERROR = 'Invalid phone number';
const INVALID_EMAIL_ERROR = 'Invalid email';
interface TeenConsenterReferralSchema {
  teenFirstName: string;
  teenLastName: string;
  consenterFirstName: string;
  consenterLastName: string;
  consenterRelationship: string;
  consenterPhoneNumber: string;
  consenterEmail: string;
  isConsentChecked: boolean;
  isEmailUpdatesChecked: boolean;
}
const teenConsenterReferralSchema = (
  tQuickmatchCommon
): yup.SchemaOf<TeenConsenterReferralSchema> =>
  yup.object().shape({
    teenFirstName: yup
      .string()
      .required(
        tQuickmatchCommon(
          'teenConsenterReferralForm.teenFirstNameRequiredError',
          "Teen's first name is required"
        )
      ),
    teenLastName: yup
      .string()
      .required(
        tQuickmatchCommon(
          'teenConsenterReferralForm.teenLastNameRequiredError',
          "Teen's last name is required"
        )
      ),
    consenterFirstName: yup
      .string()
      .required(
        tQuickmatchCommon(
          'teenConsenterReferralForm.consenterFirstNameRequiredError',
          'Your first name is required'
        )
      ),
    consenterLastName: yup
      .string()
      .required(
        tQuickmatchCommon(
          'teenConsenterReferralForm.consenterLastNameRequiredError',
          'Your last name is required'
        )
      ),
    consenterRelationship: yup
      .string()
      .required(
        tQuickmatchCommon(
          'teenConsenterReferralForm.consenterRelationshipRequiredError',
          'Your relationship to teen is required'
        )
      ),
    consenterPhoneNumber: yup
      .string()
      .required(
        tQuickmatchCommon(
          'teenConsenterReferralForm.consenterPhoneNumberRequiredError',
          'Your phone number is required'
        )
      )
      .test(
        'is-valid-phone-number',
        tQuickmatchCommon(
          'teenConsenterReferralForm.invalidPhoneNumber',
          INVALID_PHONE_NUMBER_ERROR
        ),
        (userInput: string | undefined) => isPhoneNumberValid(userInput)
      ),
    consenterEmail: yup
      .string()
      .email(tQuickmatchCommon('teenConsenterReferralForm.invalidEmailError', INVALID_EMAIL_ERROR))
      .required(
        tQuickmatchCommon(
          'teenConsenterReferralForm.consenterEmailRequiredError',
          'Your email is required'
        )
      ),
    isConsentChecked: yup
      .boolean()
      .required(tQuickmatchCommon('teenConsenterReferralForm.requiredError', REQUIRED_ERROR))
      .oneOf([true], tQuickmatchCommon('teenConsenterReferralForm.requiredError', REQUIRED_ERROR)),
    isEmailUpdatesChecked: yup
      .boolean()
      .required(tQuickmatchCommon('teenConsenterReferralForm.requiredError', REQUIRED_ERROR)),
  });

interface Props {
  step: TeenConsenterReferralFormStep;
  updateStep: UpdateStep;
}
const TeenConsenterReferralForm = ({ step, updateStep }: Props) => {
  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');
  const [error, setError] = useState<string>();

  const { setHomePageState } = useHomePageActions();

  const onSuccess = ({ preRegisterToken }) => {
    setHomePageState({
      teenConsenterReferralUrl: `${window.origin}/?preRegisterToken=${preRegisterToken}`,
    });
    updateStep(step.buttonTarget);
  };

  const onError = () => {
    setError(tQuickmatchCommon('teenConsenterReferralForm.genericError', GENERIC_ERROR, undefined));
  };

  const {
    clientState: teenState,
    clientZipcode: teenZipcode,
    clientCountry: teenCountry,
    clientAgeNumber: teenAge,
  } = useHomePageState();

  const isNYC = teenState === 'NY';

  const { mutate: submitConsenterReferral } = useMutationConsenterReferral({
    onSuccess,
    onError,
  });

  const methods = useForm<TeenConsenterReferralSchema>({
    resolver: yupResolver(teenConsenterReferralSchema(tQuickmatchCommon)),
    defaultValues: {
      teenFirstName: '',
      teenLastName: '',
      consenterFirstName: '',
      consenterLastName: '',
      consenterRelationship: '',
      consenterPhoneNumber: '',
      consenterEmail: '',
      isConsentChecked: false,
      isEmailUpdatesChecked: false,
    },
  });
  const { formState } = methods;
  useEffect(() => {
    if (formState.isDirty) {
      trackEvent('Consenter Referral Form Field Edited', {});
    }
  }, [formState.isDirty]);
  const onSubmit = (formData: TeenConsenterReferralSchema) => {
    setError(undefined);
    // NOTE: These are set on previous steps, required for submission
    if (teenAge && teenCountry && teenState && teenZipcode) {
      submitConsenterReferral({ teenAge, teenZipcode, teenCountry, teenState, ...formData });
      trackEvent('Consenter Referral Form Submitted', {});
    } else {
      onError();
    }
  };

  const checkboxProps = {
    isLabelOnRight: true,
    alignCenter: false,
    containerStyle: { textAlign: 'start' },
  };
  return (
    <EmotionThemeProvider version="2.0.0">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <TeenConsenterBanner type="eligibleConsenter" />
          <Styled.Container>
            <Styled.Title variant="heading2xl">
              {tQuickmatchCommon(
                'teenConsenterReferralForm.title',
                'Informed consent for your teen',
                undefined
              )}
            </Styled.Title>
            <Styled.Description>
              {tQuickmatchCommon(
                'teenConsenterReferralForm.description',
                'To get your teen started, please give consent',
                undefined
              )}
            </Styled.Description>
            <Styled.FormContainer>
              <Styled.InputRowContainer>
                <Styled.InputRowItem>
                  <RHFInput
                    fieldName="teenFirstName"
                    label={tQuickmatchCommon(
                      'teenConsenterReferralForm.teenFirstName',
                      'Teen’s first name',
                      undefined
                    )}
                    placeholder=" "
                    isRequired
                  />
                </Styled.InputRowItem>
                <Styled.InputRowItem>
                  <RHFInput
                    fieldName="teenLastName"
                    label={tQuickmatchCommon(
                      'teenConsenterReferralForm.teenLastName',
                      'Teen’s last name',
                      undefined
                    )}
                    placeholder=" "
                    isRequired
                  />
                </Styled.InputRowItem>
              </Styled.InputRowContainer>
              <Styled.InputRowContainer>
                <Styled.InputRowItem>
                  <RHFInput
                    fieldName="consenterFirstName"
                    label={tQuickmatchCommon(
                      'teenConsenterReferralForm.consenterFirstName',
                      'Your first Name',
                      undefined
                    )}
                    placeholder=" "
                    isRequired
                  />
                </Styled.InputRowItem>
                <Styled.InputRowItem>
                  <RHFInput
                    fieldName="consenterLastName"
                    label={tQuickmatchCommon(
                      'teenConsenterReferralForm.consenterLastName',
                      'Your last Name',
                      undefined
                    )}
                    placeholder=" "
                    isRequired
                  />
                </Styled.InputRowItem>
              </Styled.InputRowContainer>
              <Styled.SelectFullWidth
                fieldName="consenterRelationship"
                label={tQuickmatchCommon(
                  'teenConsenterReferralForm.consenterRelationship',
                  'Your relationship to teen',
                  undefined
                )}
                options={[
                  {
                    value: 'parent',
                    label: tQuickmatchCommon(
                      'teenConsenterReferralForm.parent',
                      'Parent',
                      undefined
                    ),
                  },
                  {
                    value: 'guardian',
                    label: tQuickmatchCommon(
                      'teenConsenterReferralForm.guardian',
                      'Guardian',
                      undefined
                    ),
                  },
                ]}
                registerOptionValue
                isRequired
                dataQa="consenterRelationshipDropdown"
              />
              <Styled.PhoneInput
                fieldName="consenterPhoneNumber"
                label={tQuickmatchCommon(
                  'teenConsenterReferralForm.consenterPhoneNumber',
                  'Your phone number',
                  undefined
                )}
                placeholder=" "
                containerStyle={{ width: '100%', maxWidth: 335 }}
              />
              <Styled.InputFullWidth
                fieldName="consenterEmail"
                label={tQuickmatchCommon(
                  'teenConsenterReferralForm.consenterEmail',
                  'Your email',
                  undefined
                )}
                placeholder=" "
                isRequired
              />
              <RHFCheckbox
                fieldName="isConsentChecked"
                label={
                  <TextDS variant="bodySm">
                    {isNYC ? (
                      <Trans
                        t={tQuickmatchCommon}
                        i18nKey="teenConsenterReferralForm.consentCheckboxNYC"
                      >
                        I confirm I am the parent or legal guardian of the teen above and consent to
                        treatment for my teen. I have read and understood
                        <PolicyLink
                          type="informedConsent"
                          text="Talkspace’s Informed Consent"
                          textVariant="linkSm"
                        />
                        ,
                        <PolicyLink
                          type="noticeOfPrivacyPractices"
                          text="HIPPA Notice of Privacy Practices,"
                          textVariant="linkSm"
                        />
                        , the <PolicyLink type="teenPrivacyPolicyNYC" textVariant="linkSm" />.
                      </Trans>
                    ) : (
                      <Trans
                        t={tQuickmatchCommon}
                        i18nKey="teenConsenterReferralForm.consentCheckbox"
                      >
                        I confirm I am the parent or legal guardian of the teen above and consent to
                        treatment for my teen. I have read and understood
                        <PolicyLink
                          type="informedConsent"
                          text="Talkspace’s Informed Consent"
                          textVariant="linkSm"
                        />
                        ,
                        <PolicyLink
                          type="noticeOfPrivacyPractices"
                          text="HIPPA Notice of Privacy Practices"
                          textVariant="linkSm"
                        />
                        , the <PolicyLink type="teenPrivacyPolicy" textVariant="linkSm" />.
                      </Trans>
                    )}
                  </TextDS>
                }
                {...checkboxProps}
              />
              <RHFCheckbox
                fieldName="isEmailUpdatesChecked"
                label={
                  isNYC ? (
                    <Trans
                      t={tQuickmatchCommon}
                      i18nKey="teenConsenterReferralForm.emailCheckboxNYC"
                    >
                      I agree to receive email marketing and may opt out any time. View
                      <PolicyLink type="termsOfUseNYC" textVariant="linkSm" />
                    </Trans>
                  ) : (
                    <Trans t={tQuickmatchCommon} i18nKey="teenConsenterReferralForm.emailCheckbox">
                      I agree to receive email marketing and may opt out any time. View
                      <PolicyLink type="termsOfUse" text="Terms" textVariant="linkSm" />
                    </Trans>
                  )
                }
                {...checkboxProps}
              />
              {error && <Styled.Error colorRole="textCriticalDefault">{error}</Styled.Error>}
              <Styled.CTAButtonContainer>
                <Styled.CTAButton
                  text={tQuickmatchCommon(
                    'teenConsenterReferralForm.ctaText',
                    'Continue',
                    undefined
                  )}
                  dataQa="continueButton"
                />
              </Styled.CTAButtonContainer>
            </Styled.FormContainer>
          </Styled.Container>
        </form>
      </FormProvider>
    </EmotionThemeProvider>
  );
};

export default TeenConsenterReferralForm;
