import React, { PropsWithChildren } from 'react';
import { View, StickyDrawer, EmergencyDisclaimer } from '@talkspace/react-toolkit';
import styled from '../../core/styled';
import { useHomePageState, useHomePageActions } from '../HomePage/HomePage';

const FooterContainer = styled(View)(
  ({
    theme: {
      window: { isMobile },
    },
  }) => {
    return {
      maxWidth: isMobile ? 'auto' : 343,
    };
  }
);

const StickyDrawerQM = ({ children, noBorder }: PropsWithChildren<{ noBorder?: boolean }>) => {
  const homePageState = useHomePageState();
  const actions = useHomePageActions();
  const { step } = homePageState || {};
  const isLoggedIn = actions.getIsLoggedInUser();
  return (
    <StickyDrawer noBorder={noBorder}>
      <FooterContainer>
        {children}
        <EmergencyDisclaimer
          isLoggedIn={isLoggedIn}
          dataQa={`${step?.category || ''}EmergencyResourcesLink`}
          style={{
            width: '100%',
            marginTop: 16,
            fontWeight: 400,
            fontSize: 11,
          }}
        />
      </FooterContainer>
    </StickyDrawer>
  );
};

export default StickyDrawerQM;
