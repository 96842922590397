import { states, countries } from '@talkspace/configs';
// qm-web expects these to be in a slightly different format then in the configs

const statesFormatted = Object.entries(states).map(([value, label]) => {
  return { value, label };
});

const countriesFormatted = Object.entries(countries)
  .map(([value, label]) => {
    return { value, label };
  })
  .filter(({ value }) => value !== 'US');

function getZipCodeRange(start, end) {
  return Array.from({ length: end - start + 1 }, (_, index) =>
    (start + index).toString().padStart(5, '0')
  );
}

const SEATTLE_ZIP_CODES = [...getZipCodeRange(98101, 98199)];

const NYC_ZIP_CODES = [
  ...getZipCodeRange(10001, 10282), // Manhattan
  ...getZipCodeRange(10301, 10314), // Staten Island
  ...getZipCodeRange(10451, 10475), // Bronx
  ...getZipCodeRange(11201, 11256), // Brooklyn
  ...getZipCodeRange(11351, 11697), // Queens
];

const BALTIMORE_COUNTY_ZIP_CODES = [
  // Individual zip codes
  '21013',
  '21020',
  '21027',
  '21055',
  '21057',
  '21071',
  '21082',
  '21087',
  '21105',
  '21111',
  '21117',
  '21120',
  '21128',
  '21131',
  '21133',
  '21136',
  '21139',
  '21162',
  '21204',
  '21234',
  '21244',
  '21282',

  // Ranges
  ...getZipCodeRange(21022, 21023),
  ...getZipCodeRange(21030, 21031),
  ...getZipCodeRange(21051, 21053),
  ...getZipCodeRange(21092, 21094),
  ...getZipCodeRange(21152, 21153),
  ...getZipCodeRange(21155, 21156),
  ...getZipCodeRange(21207, 21208),
  ...getZipCodeRange(21219, 21222),
  ...getZipCodeRange(21227, 21228),
  ...getZipCodeRange(21236, 21237),
  ...getZipCodeRange(21284, 21286),
];

function isZipCodeMismatched(formData) {
  const { state, zipcode } = formData;

  if (state !== 'NY' && NYC_ZIP_CODES.includes(zipcode)) {
    return true;
  }

  if (state !== 'WA' && SEATTLE_ZIP_CODES.includes(zipcode)) {
    return true;
  }

  if (state !== 'MD' && BALTIMORE_COUNTY_ZIP_CODES.includes(zipcode)) {
    return true;
  }

  return false;
}

export { statesFormatted as states, countriesFormatted as countries, isZipCodeMismatched };
