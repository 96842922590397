import {
  Button,
  View,
  useWindowWidthState,
  TextDS,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { CloudySun } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import { useTranslation } from '@talkspace/i18n';

import { YouAreNotAloneStep, UpdateStep } from '../../../Flows';
import StickyDrawerQM from '../../StickyDrawerQM';
import styled from '@/core/styled';

const Styled = {
  Container: styled(View)(({ isMobile }: { isMobile: boolean }) => {
    return {
      maxWidth: 350,
      minHeight: isMobile ? 'calc(100vh - 260px)' : undefined,
      justifyContent: 'space-between',
    };
  }),
};

interface Props {
  step: YouAreNotAloneStep;
  updateStep: UpdateStep;
}
const YouAreNotAlone = ({ step, updateStep }: Props) => {
  const { isMobile } = useWindowWidthState();
  const { spacing } = useEmotionTheme();
  const { t: tQuickmatchFlow232 } = useTranslation('quickmatch.flow232');
  return (
    <Styled.Container isMobile={isMobile}>
      <View align="center">
        <CloudySun />
        <TextDS
          variant="heading2xl"
          style={{ marginTop: spacing('space200'), marginBottom: spacing('space200') }}
        >
          {tQuickmatchFlow232('stepYouAreNotAlone.heading1', 'You’re not alone', undefined)}
        </TextDS>
        <TextDS>
          {tQuickmatchFlow232(
            'stepYouAreNotAlone.body',
            'About 301 million people worldwide live with anxiety. The good news is 79% of people with anxiety have seen significant improvement after therapy with Talkspace.',
            undefined
          )}
        </TextDS>
      </View>
      <StickyDrawerQM noBorder>
        <Button
          stretch
          text="Continue"
          onPress={() => updateStep(step.buttonTarget)}
          dataQa="YouAreNotAloneContinueButton"
        />
      </StickyDrawerQM>
    </Styled.Container>
  );
};
export default YouAreNotAlone;
