import { useTranslation, Trans } from '@talkspace/i18n';
import {
  View,
  ExtraHuge,
  Large,
  useUniqueID,
  CouplesCircle,
  RxCircle,
  ChatCircle,
  useEmotionTheme,
  useContainerFocus,
  Spinner,
  Card,
  Tag,
  TextDS,
  SpacingView,
} from '@talkspace/react-toolkit';
import { useCallback, VFC } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import compose from 'lodash/fp/compose';
import styled from '@/core/styled';
import { redirectToClientWebSignup } from '@/Helpers/registrationHelpers';
import ChatWithConsultantButton from '@/Components/ChatWithConsultantButton';
import TherapyTypeCard from '@/Components/TherapyTypeCard/TherapyTypeCard';
import { useHandleServiceSelection, useRedirector } from './hooks';
import { ServiceSelectionProps } from './types';
import { getQMFunnelName } from '../../../Helpers/flowsHelper';
import { trackEvent } from '@/utils/analytics/events';
import { useHomePageState } from '@/Components/HomePage';

const Styled = {
  Container: styled(View)({ '&:focus': { outline: 'none' } }),
  CardWrapper: styled(View)({
    marginTop: 10,
  }),
  CardContainer: styled(View)<{ lastElement: boolean }>(({ lastElement = false }) => {
    return {
      marginTop: 20,
      marginBottom: lastElement ? 50 : 0,
    };
  }),
  TextWrapper: styled(View)({
    marginTop: 10,
  }),
  ConsultantWrapper: styled(View)({
    marginTop: 20,
    marginBottom: 50,
    display: 'block',
  }),
  CardContent: styled(View)({ textAlign: 'left', width: 311 }),
};

const ServiceSelection: VFC<ServiceSelectionProps> = ({
  step,
  hideExitToCT,
  isCreateRoomFlow,
  isReactivationFlow,
  isUpdateCoverageFlow,
  isMBHIneligibilityFlow,
  updateAndSave,
  serviceType,
  updateStep,
}) => {
  const { unifiedFlowForDirectB2B, onboardingImprovementsMilestone1 } = useFlags();
  const { t: tQuickmatchFlow90 } = useTranslation('quickmatch.flow90');
  const { partnerFlowID, isHideTeenServiceType } = useHomePageState();
  const isUnifiedFlowActiveAndTreatment =
    unifiedFlowForDirectB2B.experimentActive &&
    unifiedFlowForDirectB2B.variant === 'treatment' &&
    partnerFlowID;

  const handleServiceSelection = useHandleServiceSelection({
    isCreateRoomFlow,
    isReactivationFlow,
    isUpdateCoverageFlow,
    isMBHIneligibilityFlow,
    updateAndSave,
  });

  const { containerRef } = useContainerFocus();
  const heading1ID = useUniqueID('heading1ID');
  const {
    spacing,
    colors: {
      aquaSqueeze,
      bizarre,
      redDamask,
      catskillWhite,
      matisse,
      green,
      permaOrinoco,
      permaAtlantisNew,
      permaFlamePea,
      permaBlueStoneNew,
    },
  } = useEmotionTheme();

  const handleChatWithConsultant = useCallback(() => redirectToClientWebSignup(), []);

  const { isLoading } = useRedirector({
    handleServiceSelection,
    serviceType,
  });

  const handleTeensClick = useCallback(() => {
    if (!isUnifiedFlowActiveAndTreatment && step.buttonTarget) {
      updateStep(step.buttonTarget);
      trackEvent('Answer Questionnaire', {
        Question: 'selectService',
        Response: 'therapyTeen',
        // For backwards compatibility tracking
        Flow: 'Dispatcher',
        'Funnel Name': getQMFunnelName({
          isCreateRoomFlow,
          isReactivationFlow,
          isUpdateCoverageFlow,
          isMBHIneligibilityFlow,
        }),
      });
    } else {
      handleServiceSelection('therapyTeen');
    }
  }, [
    handleServiceSelection,
    isCreateRoomFlow,
    isMBHIneligibilityFlow,
    isReactivationFlow,
    isUpdateCoverageFlow,
    step.buttonTarget,
    updateStep,
    isUnifiedFlowActiveAndTreatment,
  ]);

  if (isLoading) {
    return <Spinner />;
  }

  return onboardingImprovementsMilestone1 ? (
    <Styled.Container
      align="center"
      style={{ maxWidth: 440, alignSelf: 'center' }}
      ref={containerRef}
      tabIndex={-1}
      aria-labelledby={heading1ID}
    >
      <TextDS variant="headingXl" id={heading1ID} style={{ marginBottom: spacing('space300') }}>
        {tQuickmatchFlow90('serviceSelection.title', 'I’d like to', undefined)}
      </TextDS>
      <SpacingView itemSpacing="space200">
        <Card
          surfaceRoleVariantName="decorativeGratitudeGreen"
          variant="noBorder"
          rowGap={spacing('space100', false)}
          onPress={() => handleServiceSelection('psychotherapy')}
          dataQa="onlineTherapy"
        >
          <View row>
            <Tag color="decorativeGratitudeGreenTint" text="Individual" />
          </View>
          <Styled.CardContent row style={{ textAlign: 'left' }}>
            <TextDS variant="decorative/headingLg">
              <Trans i18nKey="serviceSelection.serviceCardTherapy" t={tQuickmatchFlow90}>
                Work 1-on-1 with a therapist
                <br />
                for ages 18+
              </Trans>
            </TextDS>
          </Styled.CardContent>
        </Card>
        <>
          {!isHideTeenServiceType && (
            <Card
              surfaceRoleVariantName="decorativeLiftedLemon"
              variant="noBorder"
              rowGap={spacing('space100', false)}
              onPress={handleTeensClick}
              dataQa="teenTherapy"
            >
              <View row>
                <Tag color="decorativeLiftedLemonTint" text="Teens" />
              </View>
              <Styled.CardContent row style={{ textAlign: 'left' }}>
                <TextDS variant="decorative/headingLg">
                  <Trans i18nKey="serviceSelection.serviceCardTeens" t={tQuickmatchFlow90}>
                    Get specialized support
                    <br />
                    for teens 13-17
                  </Trans>
                </TextDS>
              </Styled.CardContent>
            </Card>
          )}
        </>
        <Card
          surfaceRoleVariantName="decorativeBalanceBlue"
          variant="noBorder"
          rowGap={spacing('space100', false)}
          onPress={() => handleServiceSelection('therapyCouples')}
          dataQa="couplesTherapy"
        >
          <View row>
            <Tag color="decorativeBalanceBlueTint" text="Couples" />
          </View>
          <Styled.CardContent row style={{ textAlign: 'left' }}>
            <TextDS variant="decorative/headingLg">
              <Trans i18nKey="serviceSelection.serviceCardCouples" t={tQuickmatchFlow90}>
                Work with a therapist as a<br />
                couple
              </Trans>
            </TextDS>
          </Styled.CardContent>
        </Card>
        <Card
          surfaceRoleVariantName="decorativePresentPink"
          variant="noBorder"
          rowGap={spacing('space100', false)}
          onPress={() => handleServiceSelection('psychiatry')}
          dataQa="psychiatry"
        >
          <View row>
            <Tag color="decorativePresentPinkTint" text="Medication" />
          </View>
          <Styled.CardContent row style={{ textAlign: 'left' }}>
            <TextDS variant="decorative/headingLg">
              <Trans i18nKey="serviceSelection.serviceCardPsych" t={tQuickmatchFlow90}>
                Get a psychiatric
                <br />
                evaluation or medication
              </Trans>
            </TextDS>
          </Styled.CardContent>
        </Card>
      </SpacingView>
    </Styled.Container>
  ) : (
    <Styled.Container
      align="center"
      style={{ maxWidth: 440, alignSelf: 'center' }}
      ref={containerRef}
      tabIndex={-1}
      aria-labelledby={heading1ID}
    >
      <ExtraHuge id={heading1ID} as="h1">
        What type of service are you looking for?
      </ExtraHuge>
      <Styled.TextWrapper>
        <Large variant="largeDarkGrey" as="h2">
          Talkspace offers comprehensive options to meet all your mental health needs
        </Large>
      </Styled.TextWrapper>
      <Styled.CardContainer lastElement={hideExitToCT}>
        <Styled.CardWrapper>
          <TherapyTypeCard
            title="Therapy"
            description="Individualized support from a licensed therapist for ages 18+"
            icon={<ChatCircle color={permaBlueStoneNew} width={47} />}
            bgColor={aquaSqueeze}
            focusColor={green}
            onClick={() => handleServiceSelection('psychotherapy')}
            dataQa="onlineTherapy"
          />
        </Styled.CardWrapper>
        <>
          {!isHideTeenServiceType && (
            <Styled.CardWrapper>
              <TherapyTypeCard
                title="Teen Therapy"
                description="Specialized support designed for youth ages 13-17"
                icon={<ChatCircle color={permaAtlantisNew} width={47} />}
                bgColor={permaOrinoco}
                focusColor={permaAtlantisNew}
                onClick={handleTeensClick}
                dataQa="teenTherapy"
              />
            </Styled.CardWrapper>
          )}
          <Styled.CardWrapper>
            <TherapyTypeCard
              title="Couples Therapy"
              description="Relationship support to improve your connection with your partner"
              icon={<CouplesCircle color={matisse} width={47} />}
              bgColor={catskillWhite}
              focusColor={matisse}
              onClick={() => handleServiceSelection('therapyCouples')}
              dataQa="couplesTherapy"
            />
          </Styled.CardWrapper>
        </>
        <Styled.CardWrapper>
          <TherapyTypeCard
            title="Psychiatry"
            description="Psychiatric evaluations, diagnoses, and medication"
            icon={<RxCircle color={permaFlamePea} width={47} />}
            bgColor={bizarre}
            focusColor={redDamask}
            onClick={() => handleServiceSelection('psychiatry')}
            dataQa="psychiatry"
          />
        </Styled.CardWrapper>
      </Styled.CardContainer>
      {!hideExitToCT && (
        <Styled.ConsultantWrapper>
          <Large inline>Not quite sure? </Large>
          <ChatWithConsultantButton
            roundedFocusStyle
            primaryColor={green}
            onPress={handleChatWithConsultant}
            dataQa="chatWithConsultant"
          />
        </Styled.ConsultantWrapper>
      )}
    </Styled.Container>
  );
};

/**
 * Renders a spinner until we're certain that LaunchDarkly flags have been loaded.
 */
const withFlags = (Component) => (props) => {
  const flags = useFlags();
  if (!Object.keys(flags).length) {
    return <Spinner isLoading />;
  }
  return <Component {...props} />;
};

const Composed = compose(withFlags)(ServiceSelection);

export default Composed;
