import { useTranslation } from '@talkspace/i18n';

import {
  Button,
  EmergencyDisclaimer,
  EmotionThemeProvider,
  TextDS,
  View,
  SpacingView,
  StickyDrawer,
  styled,
} from '@talkspace/react-toolkit';
import { useHistory } from 'react-router';
import { FLOW_140_TEEN_THERAPY } from '../../Flows';
import TeenConsenterFAQs from '../TeenConsenterFAQs';
import StepDescriptionItem from '../StepDescriptionItem';
import NeedHelpPhone from '../NeedHelpPhone';

const MAX_WIDTH = 375;

const Styled = {
  InfoContainer: styled(View)(({ theme: { spacing } }) => {
    return {
      maxWidth: MAX_WIDTH,
      padding: `0 ${spacing('space150')}px`,
    };
  }),
  Title: styled(TextDS)({}),
  Subtitle: styled(TextDS)(({ theme: { spacing } }) => {
    return {
      marginTop: spacing('space150'),
    };
  }),
  StepsContainer: styled(View)(({ theme: { spacing } }) => {
    return {
      marginTop: spacing('space400'),
    };
  }),
  CTAButtonContainer: styled(View)(() => {
    return {
      maxWidth: MAX_WIDTH,
    };
  }),
  CTAButton: styled(Button)(({ theme: { spacing } }) => {
    return {
      marginBottom: spacing('space100'),
    };
  }),
};

const stepsData = [
  {
    number: 1,
    title: 'Plan time to help your teen',
    description: 'Have your insurance card and billing information handy to help your teen sign up',
    titleTranslationKey: 'teenStepDescriptions.planTimeToHelpYourTeenTitle',
    descriptionTranslationKey: 'teenStepDescriptions.planTimeToHelpYourTeenDescription',
  },
  {
    number: 2,
    title: 'Your teen signs up',
    description: 'Your teen completes sign up and matches with a therapist',
    titleTranslationKey: 'teenStepDescriptions.yourTeenSignsUpTitle',
    descriptionTranslationKey: 'teenStepDescriptions.yourTeenSignsUpDescription',
  },
  {
    number: 3,
    title: 'Give consent',
    description: 'You will receive an email to give consent for your teen to get therapy',
    titleTranslationKey: 'teenStepDescriptions.receiveEmailGiveConsentTitle',
    descriptionTranslationKey: 'teenStepDescriptions.receiveEmailGiveConsentDescription',
  },
];

const AgeLocationIneligibleConsenter = () => {
  const history = useHistory();
  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');
  const onPressCTA = () => {
    history.push(`/flow/${FLOW_140_TEEN_THERAPY}/step/100`);
  };
  return (
    <EmotionThemeProvider version="2.0.0">
      <View align="center">
        <Styled.InfoContainer>
          <Styled.Title variant="headingXl">
            {tQuickmatchCommon(
              'ageLocationIneligibleConsenterStep.title',
              'Your info doesn’t match any of our free therapy programs',
              undefined
            )}
          </Styled.Title>
          <Styled.Subtitle>
            {tQuickmatchCommon(
              'ageLocationIneligibleConsenterStep.subtitle',
              'Don’t worry, you can still get your teen therapy with Talkspace. Here’s how to get started.',
              undefined
            )}
          </Styled.Subtitle>
        </Styled.InfoContainer>
        <Styled.StepsContainer>
          <SpacingView itemSpacing="space300">
            {stepsData.map((stepData) => (
              <StepDescriptionItem {...stepData} />
            ))}
          </SpacingView>
        </Styled.StepsContainer>
        <NeedHelpPhone />
        <TeenConsenterFAQs />
        <StickyDrawer>
          <Styled.CTAButtonContainer>
            <Styled.CTAButton
              stretch
              text={tQuickmatchCommon(
                'ageLocationIneligibleConsenterStep.ctaText',
                'Get started',
                undefined
              )}
              onPress={onPressCTA}
              dataQa="getStartedButton"
            />
            <EmergencyDisclaimer />
          </Styled.CTAButtonContainer>
        </StickyDrawer>
      </View>
    </EmotionThemeProvider>
  );
};

export default AgeLocationIneligibleConsenter;
