import { useState, useEffect } from 'react';
import { View, Spinner } from '@talkspace/react-toolkit';
import { AllLaunchDarklyExperiments } from 'ts-frontend/types';
import HomePage from '../HomePage/HomePage';
import {
  useRecoveredSessionActions,
  useRecoveredSessionState,
} from '../../hooks/recoveredSessionContext';
import { DEFAULT_FLOWS, getClientFlow } from '../../Flows';
import useQueryPublicAdminConfig from '../../hooks/useQueryPublicAdminConfig';
import { RouteComponentProps } from '../../core/routerLib';

interface Params {
  flowId?: string;
  stepId?: string;
}
interface HomePageSessionWrapperProps extends RouteComponentProps<Params> {
  flags: AllLaunchDarklyExperiments;
}

const HomePageSessionWrapper = (props: HomePageSessionWrapperProps) => {
  const { history, location, match, flags } = props;
  const [isReady, setIsReady] = useState(false);
  const session = useRecoveredSessionState();
  const { setSessionState, updateAndSave } = useRecoveredSessionActions();
  const { isLoading: cognitoActiveIsLoading, data: cognitoActive } =
    useQueryPublicAdminConfig('cognito_user_migration');

  useEffect(() => {
    if (!isReady) {
      if (!session.recoveredSession) {
        const { search } = location;
        const { params } = match || { params: {} };
        const paramsFlowID = Number(params.flowId);
        const stepId = Number(params.stepId) || 1;
        const flow = getClientFlow(paramsFlowID, false);

        if (!flow) {
          history.push({
            pathname: `/flow/${DEFAULT_FLOWS.CONSUMER}/step/1`,
            search,
          });
          return;
        }
        const flowVersion = flow ? flow.flowConfig.version : 0;
        if (search) updateAndSave({ flowId: paramsFlowID, flowVersion, stepId, search });
        else setSessionState({ flowId: paramsFlowID, flowVersion, stepId, search });
      }

      setIsReady(true);
    }
  }, [isReady, history, location, match, session.recoveredSession, setSessionState, updateAndSave]);

  if (isReady && !cognitoActiveIsLoading)
    return (
      <HomePage
        {...props}
        session={session}
        updateAndSave={updateAndSave}
        cognitoActive={!!cognitoActive}
        flags={flags}
      />
    );

  return (
    <View style={{ height: '100vh' }}>
      <View flex={1}>
        <Spinner isLoading />
      </View>
    </View>
  );
};

export default HomePageSessionWrapper;
