/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';
import { ServiceType } from 'ts-frontend/types';
import { OUT_OF_POCKET_OPTION } from 'ts-frontend/helpers';
import useQueryInsurancePayers from '@/hooks/useQueryInsurancePayers';
import { useMoveCoverageEnabled } from '../../../hooks';

export const useInsurancePayerOptions = ({
  service,
  shouldHideOutOfPocket,
  shouldHideOutOfNetwork,
}: {
  service: ServiceType | null;
  shouldHideOutOfPocket?: boolean;
  shouldHideOutOfNetwork?: boolean;
}) => {
  const isMoveCoverageEnabled = useMoveCoverageEnabled();

  const { data: insurancePayers, isLoading: isLoadingPayers } = useQueryInsurancePayers(service);

  return useMemo(() => {
    if (isLoadingPayers) {
      return {
        loading: true,
        options: undefined,
      };
    }
    let options = insurancePayers;

    if (isMoveCoverageEnabled || shouldHideOutOfPocket) {
      options = options?.filter((payer) => payer.id !== OUT_OF_POCKET_OPTION.id);
    }
    if (shouldHideOutOfNetwork) {
      options = options?.filter((payer) => !!payer && !payer.isOutOfNetwork);
    }

    return {
      loading: false,
      options,
    };
  }, [
    isLoadingPayers,
    insurancePayers,
    isMoveCoverageEnabled,
    shouldHideOutOfPocket,
    shouldHideOutOfNetwork,
  ]);
};
