import {
  FunctionComponent,
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  VoidFunctionComponent,
  useRef,
} from 'react';
import {
  View,
  Breadcrumbs,
  BackNextButton,
  useEmotionTheme,
  useUniqueID,
  CancellationPolicy,
  Button,
  Tiny,
} from '@talkspace/react-toolkit';
import { isStripeUsingLink, useStripeTs } from 'stripe/stripeContext';
import { useFlags } from 'launchDarkly/FlagsProvider';
import PaymentDetails from 'checkout/components/PaymentDetails';
import ReviewYourPlan from './ReviewYourPlan';
import { getCopayCost } from './registerWithBHCopayHelpers';
import { RegisterWithBHCopayViewProps } from './types';
import { apply1000pxMediaQuery } from '@/core/styled/styleHelpers';
import styled from '@/core/styled';

import { FLOW_28_B2B_COPAY_TRIZETTO_PREMERA } from '@/Flows';
import { trackOpenCancellationPolicy } from '@/utils/analytics/events';
import { useHomePageActions } from '@/Components/HomePage';
import { useEstimatedCost } from '@/hooks';

const BackButton = styled(BackNextButton)({
  position: 'fixed',
  left: 32,
  zIndex: 2,
  borderRadius: 5,
  top: 96,
  transform: 'translateZ(0)',
  WebkitTransform: 'translateZ(0)',
  ...apply1000pxMediaQuery({ top: 26 }),
  '&:focus': { zIndex: 2 },
});

const StyledTiny = styled(Tiny)(({ theme: { colors } }) => {
  return {
    minWidth: 260,
    margin: 'auto',
    textAlign: 'center',
    marginTop: 20,
    color: colors.slateGrey,
  };
});

const StyledButton = styled(Button)(({ theme: { colors } }) => {
  return {
    color: colors.green,
    width: 'auto',
    fontWeight: 700,
    fontSize: 13,
    minHeight: 0,
    margin: 0,
    padding: 0,
    height: 18,
    backgroundColor: colors.white,
    '&:hover': {
      color: colors.permaTropicalRainForest,
    },
  };
});

interface CancellationPolicyMessageParams {
  ccMessageID: string;
  setSubStep: Dispatch<SetStateAction<number>>;
  submitText: string;
}

const CancellationPolicyMessage: VoidFunctionComponent<CancellationPolicyMessageParams> = ({
  ccMessageID,
  setSubStep,
  submitText,
}) => (
  <StyledTiny id={ccMessageID}>
    By clicking “{submitText}” you are <br /> agreeing to{' '}
    <StyledButton
      dataQa="registerWithBHCopayCancellationPolicyLink"
      onPress={() => {
        setSubStep(2);
        trackOpenCancellationPolicy();
      }}
    >
      Talkspace Cancellation Policy
    </StyledButton>
    .
  </StyledTiny>
);

const RegisterWithBHCopayView: FunctionComponent<RegisterWithBHCopayViewProps> = (props) => {
  const {
    isNoMatches,
    copayCents,
    email,
    requestPending,
    checkoutErrorMessage,
    finalFlowID,
    subStep,
    setSubStep,
    handleBackButtonPress,
    handleSubmitPayment,
    onLoadStripeLink,
    resetError,
    onStripeLinkError,
    isVideoOnlyPlan,
    insurancePayer,
    sessionModality,
    allowedModalities,
    service,
    headerRef,
    skipCreditCard,
    skipPlanReview,
  } = props;

  const { getMaxCost } = useHomePageActions();
  const [useStripeLink, setUseStripeLink] = useState(true);
  const { colors } = useEmotionTheme();
  const stripe = useStripeTs();
  const ccMessageID = useUniqueID('ccMessageID');
  const breadcrumbsRef = useRef<HTMLDivElement>(null);
  const { registrationAckp0Copay: registerAckp0CopayActive } = useFlags();
  const { specifyCopay, perSession, perSessionMin } = useEstimatedCost();
  const maxCost = getMaxCost(finalFlowID, service);

  const copayDollars = copayCents && copayCents > 0 ? getCopayCost(copayCents, maxCost) : 0;

  useEffect(() => {
    setUseStripeLink(isStripeUsingLink(stripe));
  }, [stripe]);

  useEffect(() => {
    breadcrumbsRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [subStep]);

  const submitText = 'Pay $0 now';
  return (
    <View
      style={{
        ...(skipCreditCard ? {} : { maxWidth: 375 }),
        width: '100%',
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        alignSelf: 'center',
      }}
    >
      {!skipCreditCard && (
        <BackButton
          dataQa="registerWithBHCopayBackButton"
          roundedFocusStyle
          primaryColor={colors.green}
          onPress={handleBackButtonPress}
        />
      )}
      {service === 'psychiatry' && !skipPlanReview && (
        <View ref={breadcrumbsRef}>
          <Breadcrumbs
            activeIndex={subStep}
            items={[{ label: 'Review details' }, { label: 'Checkout' }]}
            style={{ margin: '20px 10px' }}
          />
        </View>
      )}
      {subStep === 0 && (
        <ReviewYourPlan
          copayCents={copayCents}
          finalFlowID={finalFlowID}
          setSubStep={setSubStep}
          isVideoOnlyPlan={isVideoOnlyPlan}
          insurancePayer={insurancePayer}
          sessionModality={sessionModality}
          allowedModalities={allowedModalities}
          service={service}
        />
      )}
      {subStep === 1 && (
        <PaymentDetails
          containerStyle={{ width: '100%', textAlign: 'left' }}
          heading1Style={{ maxWidth: 335, width: '100%', alignSelf: 'center' }}
          heading2Style={{ maxWidth: 335, width: '100%', alignSelf: 'center' }}
          paymentFormContainerStyle={{ maxWidth: 335, width: '100%', alignSelf: 'center' }}
          email={email}
          isEmailRequired
          provider="Stripe"
          submitText={submitText}
          currency="USD"
          total={copayDollars}
          totalsVariant={
            !registerAckp0CopayActive || copayDollars > 0 || !specifyCopay ? 'bh' : 'none'
          }
          maxCost={maxCost}
          isProcessing={requestPending}
          stripe={stripe}
          isNoMatches={isNoMatches}
          onSubmit={handleSubmitPayment}
          onLoadStripeLink={onLoadStripeLink}
          resetError={resetError}
          onStripeLinkError={onStripeLinkError}
          errorMessage={checkoutErrorMessage || undefined}
          additionalMessage={
            <CancellationPolicyMessage
              submitText={submitText}
              ccMessageID={ccMessageID}
              setSubStep={setSubStep}
            />
          }
          buttonDescribedBy={
            finalFlowID === FLOW_28_B2B_COPAY_TRIZETTO_PREMERA ? ccMessageID : undefined
          }
          useStripeLink={useStripeLink}
          subtitle={null} // Hide
          stickySummary={{
            topRef: headerRef,
            // Reduce <main/>'s padding to the Payment Details margin so that the margin = 30
            extraMargin: -18,
          }}
          specifyCopay={specifyCopay}
          perSession={perSession}
          perSessionMin={perSessionMin}
        />
      )}
      {subStep === 2 && <CancellationPolicy variant="liveWithFee" />}
    </View>
  );
};
export default RegisterWithBHCopayView;
