import { useEffect, useState } from 'react';
import { useTranslation } from '@talkspace/i18n';
import useQueryPublicAdminConfigJson from './useQueryPublicAdminConfigJson';
import useBHOnboardingImprovements from './useBHOnboardingImprovements';

interface MemberIDGuidance {
  label: string;
  tooltip: string;
  hint?: string;
  isPrimary?: boolean;
  description?: string;
  images?: string[];
}

const useMemberIDGuidance = (partnerID) => {
  const { t: tQuickmatchFlow132 } = useTranslation('quickmatch.flow132');
  const isBHOnboardingImprovements = useBHOnboardingImprovements();
  const defaultTooltip = isBHOnboardingImprovements
    ? tQuickmatchFlow132(
        'step108.toolTipTextV2',
        `Type the full ID, including letters and numbers. Your ID is usually on the front of your insurance card and labeled "Member ID" or "Subscriber ID." You can also find it on your insurance provider’s website by logging in and going to your profile or coverage details.`,
        undefined
      )
    : tQuickmatchFlow132(
        'step108.toolTipText',
        `Type the full ID, including letters and numbers. Your Member ID is usually printed on the front of your health insurance card. Look for a number that’s labeled "Member ID" or "Subscriber ID." You can also find your Member ID by logging into your health insurance provider’s website or mobile app. Once logged in, go to your profile or coverage details.`,
        undefined
      );
  const [memberIDGuidance, setMemberIDGuidance] = useState<MemberIDGuidance>({
    label: 'Member ID',
    tooltip: defaultTooltip,
  });
  const { data: memberIDGuidancesByPartner, isLoading: isLoadingMemberIDGuidances } =
    useQueryPublicAdminConfigJson('member_id_hints', true);

  useEffect(() => {
    let configGuidance: MemberIDGuidance | undefined;
    if (!isLoadingMemberIDGuidances && memberIDGuidancesByPartner) {
      const memberIDGuidances = memberIDGuidancesByPartner[partnerID || ''];
      configGuidance = memberIDGuidances?.find((h: MemberIDGuidance) => h.isPrimary);
    }
    if (configGuidance) {
      setMemberIDGuidance({
        ...configGuidance,
        tooltip: configGuidance.tooltip || memberIDGuidance.tooltip,
        label: configGuidance.label || memberIDGuidance.label,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingMemberIDGuidances, memberIDGuidancesByPartner, partnerID]);

  return memberIDGuidance;
};

export default useMemberIDGuidance;
