import { useTranslation } from '@talkspace/i18n';
import {
  ExtraHuge,
  Large,
  EmotionThemeProvider,
  TextDS,
  View,
  styled,
  spacing,
} from '@talkspace/react-toolkit';
import { useSetCustomAttributes } from 'launchDarkly/hooks';
import { MagnifyingGlassClipboard } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import { useEffect } from 'react';
import { AxiosError } from 'axios';
import { BHEligibilityNotCoveredStep, UpdateStep } from '../../../Flows';
import { trackBHEligibilityRejectionReason, trackEvent } from '../../../utils/analytics/events';
import { HomePageState, useHomePageActions, useHomePageState } from '../../HomePage';
import { useOnContinuePress } from '../../B2BFork/hooks';
import { MenuItems, MenuLinks } from '../../OneFormEligibility/OneFormEligibilityNoCoverage';
import bhInsuranceEligibilityErrorParser from '../../../Helpers/bhEligibilityErrorHelper';
import { activateVwoOonExperiment, setVWOInsuranceProvider } from '@/utils/vwoHelper';

const Wrapper = styled(View)(() => {
  return {
    alignItems: 'center',
    maxWidth: 430,
    marginTop: 20,
  };
});

const DiscountBanner = styled(View)(({ theme: { colorRoles } }) => {
  return {
    borderRadius: 8,
    padding: 8,
    marginTop: 8,
    background: colorRoles.surfaces.successSubtleDefault,
    color: colorRoles.surfaces.successBoldDefault,
    width: 202,
    boxSizing: 'content-box',
  };
});

type BHEligibilityNotCoveredProps = {
  updateStep: UpdateStep;
  step: BHEligibilityNotCoveredStep;
  quickEligibilityInfo: HomePageState['quickEligibilityInfo'];
};

const BHEligibilityNotCovered = ({
  updateStep,
  step,
  quickEligibilityInfo,
}: BHEligibilityNotCoveredProps) => {
  const { t: tQuickmatchFlow132 } = useTranslation('quickmatch.flow132');
  const { space150, space300 } = spacing;
  const { setHomePageState } = useHomePageActions();
  const {
    flowId,
    service,
    clientCountry,
    clientState,
    insurancePayer: initialInsurancePayer,
    experimentsVariants,
  } = useHomePageState();
  const insurancePayerInfo = quickEligibilityInfo?.insurancePayer;
  const error = quickEligibilityInfo?.error;
  const eligibilityRejectionReason = bhInsuranceEligibilityErrorParser(error as AxiosError);

  const setCustomAttribute = useSetCustomAttributes();

  const onContinuePress = useOnContinuePress({
    step,
    flowId,
    service,
    updateStep,
    clientCountry,
    clientState,
    insurancePayer: initialInsurancePayer,
    experimentsVariants,
    selectedPayer: initialInsurancePayer || null,
    shortB2BForkForm: true,
    setShowDropdownError: () => {},
    setCustomAttribute,
    setIsLoading: () => {},
    customUpdateStep: () => {
      updateStep(step.id, {
        quickStatusAction: 'continueWithoutInsurance',
        isCouldNotBeVerified: true,
        // clear insuranceEligibility for out-of-pocket, to support OOP promo and applying coupon
        insuranceEligibility: undefined,
        b2bForkResult: 'b2c',
        insurancePayer: undefined,
        collectReferralSourceOnSignUp: true,
        isB2BOutOfNetwork: true,
      });
      setVWOInsuranceProvider('out-of-network');
      setTimeout(() => {
        activateVwoOonExperiment();
      }, 500);
    },
  });

  useEffect(() => {
    if (eligibilityRejectionReason) {
      trackBHEligibilityRejectionReason(
        "Couldn't verify coverage Seen",
        'couldnt-verify-coverage',
        eligibilityRejectionReason,
        !insurancePayerInfo?.isOutOfNetwork
      );
    }
  }, [eligibilityRejectionReason, insurancePayerInfo?.isOutOfNetwork]);

  useEffect(() => {
    if ((quickEligibilityInfo?.eligibilityCheckAttempts ?? 0) >= 5) {
      setHomePageState({
        isDisabledPreviousButton: true,
      });
    }

    return () => {
      setHomePageState({
        isDisabledPreviousButton: false,
      });
    };
  }, [quickEligibilityInfo?.eligibilityCheckAttempts, setHomePageState]);

  const items: MenuItems[] = [
    {
      key: 'continueWithoutInsurancePress',
      Content: () => (
        <>
          <Large>
            {tQuickmatchFlow132('step109.buttonTitle1', 'Continue without insurance', undefined)}
          </Large>
          <DiscountBanner>
            <TextDS variant="headingSm" colorRole="textSuccessDefault">
              {tQuickmatchFlow132(
                'step109.bannerText',
                'You’re eligible for a discount!',
                undefined
              )}
            </TextDS>
          </DiscountBanner>
        </>
      ),
      onPress: () => {
        trackEvent('No Coverage Options', {
          optionType: 'Continue without insurance',
          source: 'Could not verify',
        });
        onContinuePress('out-of-network');
      },
    },
    {
      key: 'checkIfHaveCoveragePress',
      Content: () => (
        <Large>
          {tQuickmatchFlow132(
            'step109.buttonTitle2',
            'See if I’m covered by an employer',
            undefined
          )}
        </Large>
      ),
      onPress: () => {
        trackEvent('No Coverage Options', {
          optionType: 'See if I’m covered by an employer',
          source: 'Could not verify',
        });
        updateStep(step.id, {
          quickStatusAction: 'orgEap',
          isCouldNotBeVerified: false,
          insurancePayer: undefined,
        });
      },
    },
    {
      key: 'surePlanCoversTalkspacePress',
      Content: () => (
        <Large>
          {tQuickmatchFlow132(
            'step109.buttonTitle3',
            'I’m sure my plan covers Talkspace',
            undefined
          )}
        </Large>
      ),
      onPress: () => {
        trackEvent('No Coverage Options', {
          optionType: 'I’m sure my plan covers Talkspace',
          source: 'Could not verify',
        });
        updateStep(step.id, {
          quickStatusAction: 'fileUpload',
          isCouldNotBeVerified: false,
        });
      },
    },
  ];

  return (
    <EmotionThemeProvider version="2.0.0">
      <Wrapper>
        <MagnifyingGlassClipboard />
        <ExtraHuge style={{ marginTop: space300, marginBottom: space150 }}>
          {tQuickmatchFlow132(
            'step109.titleText',
            'We couldn’t verify your coverage for Talkspace',
            undefined
          )}
        </ExtraHuge>
        <Large>
          {tQuickmatchFlow132(
            'step109.titleText',
            'It looks like Talkspace may not be covered by your plan, but you are eligible for a discount.',
            undefined
          )}
        </Large>
        <MenuLinks items={items} />
      </Wrapper>
    </EmotionThemeProvider>
  );
};

export default BHEligibilityNotCovered;
