import { useState, useCallback } from 'react';

import {
  Button,
  View,
  useWindowWidthState,
  spacing,
  Image,
  TouchableView,
  Small,
  Modal,
  CloseButton,
  Big,
  EmotionThemeProvider,
  ExtraTiny,
  SpacingView,
  StickyDrawer,
  TextDS,
  EmergencyDisclaimer,
} from '@talkspace/react-toolkit';
import { Trans } from '@talkspace/i18n';
import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import { useHistory, useLocation } from 'react-router';
import { useFlags } from 'launchDarkly/FlagsProvider';
import PolicyLink from '@talkspace/react-toolkit/src/components/PolicyLink';
import { getPolicyLinkByState } from '@talkspace/react-toolkit/src/components/PolicyLink/utils';
import {
  FLOW_138_B2B_VOUCHER_GENERIC_V2,
  TeensEligibleFreeTherapyStep,
  UpdateStep,
} from '../../../Flows';
import { EXCLUDED_STATES } from '../../../Flows/utils';
import { trackEvent } from '../../../utils/analytics/events';
import styled from '@/core/styled';
import switchFlowHelper from '../../../Helpers/switchFlowHelper';
import TeenThoughtsIllustration from '../../assets/TeenThoughtsIllustration';
import TeenCelebrationIllustration from '../../assets/TeenCelebrationIllustration';
import NYCTeenspace from './NYCTeenspace.png';
import UnionIcon from './UnionIcon.png';
import Header from '../../Header/Header';
import NYCPeopleCheckBanner from '../../assets/NYCPeopleCheckBanner';
import NeedHelpPill from '../../NeedHelpPill';
import Footer from '../../Footer/Footer';
import { useHomePageState } from '../../HomePage';
import { PartnerLogo } from '../../PartnerBranding';
import FreeTherapyPlanDetails from './FreeTherapyPlanDetails';

const Styled = {
  Container: styled(View)(({ isMobile }: { isMobile: boolean }) => {
    return {
      maxWidth: 350,
      minHeight: isMobile ? 'calc(100vh - 260px)' : undefined,
    };
  }),
  Title: styled(TextDS)(({ theme: { spacing: s } }) => {
    return { marginBottom: s('space150') };
  }),
  ContinueButton: styled(Button)(({ theme: { spacing: s } }) => {
    return {
      marginBottom: s('space200'),
      marginTop: s('space300'),
      width: '100%',
      minWidth: 350,
      padding: `18px ${s('space300')}px`,
    };
  }),
  PlanInformationContainer: styled(TouchableView)(({ theme: { spacing: s } }) => {
    return {
      backgroundColor: '#F2F5FB',
      borderRadius: s('space100'),
      height: 76,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
      padding: '21px 20px 21px 21px',
    };
  }),
  PlanInformationButton: ({ ageLocationEligibility, partnerID, onPress }) => (
    <Styled.PlanInformationContainer dataQa="planInformationCard" onPress={onPress}>
      {ageLocationEligibility ? (
        <PartnerLogo size="small" partnerID={partnerID} />
      ) : (
        <Image source={NYCTeenspace} width={67} height={34} style={{ marginRight: 18 }} />
      )}
      <Small
        style={{
          margin: `0 ${spacing.space200}px`,
          textAlign: 'start',
          width: 184,
        }}
        variant="smallBolderGrey"
      >
        Learn what’s included in your free therapy plan
      </Small>
      <Image source={UnionIcon} width={14} height={14} />
    </Styled.PlanInformationContainer>
  ),
  BannerContainer: styled(View)({
    width: 349,
    alignItems: 'center',
    gap: spacing.space150,
  }),
  ModalContent: styled(View)({
    alignSelf: 'center',
    padding: '32px 20px',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    maxWidth: 390,
  }),
  PlanDetailsItem: ({ title, description }) => (
    <View flex={1} style={{ gap: spacing.space050 }}>
      <Big variant="bigBold">{title}</Big>
      <Small variant="smallGrey950">{description}</Small>
    </View>
  ),
};

interface Props {
  step: TeensEligibleFreeTherapyStep;
  updateStep: UpdateStep;
  updateAndSave: (state: Partial<RecoveredSessionState>) => void;
  isConsenter: boolean;
}

//  TODO: Delete this block after ageLocationEligibility feature flag is out and stable
const oldPlanDetailsItems = [
  {
    title: 'A dedicated therapist to support you',
    description:
      'Once you’ve been matched with a therapist, they will support you through private messaging and monthly live video sessions - for free!',
  },
  {
    title: 'One monthly live video session',
    description:
      'Connect with your therapist in real-time by scheduling a free 30 minute live video, audio or instant chat session every month.',
  },
  {
    title: 'Messaging with your therapist',
    description:
      'Write your therapist anytime from your secure and confidential therapy chat room. You can message your therapist anytime and get daily responses.',
  },
  {
    title: 'Self-guided therapy exercises',
    description:
      'With the free Talkspace Go app, you’ll have access to bite-sized exercises, daily reflection prompts, and interactive classes, led by licensed therapists.',
  },
];

// Having this as a constant allows us to combine JSX elements and spread the planDetailsItems array.
// Spreading children is not allowed by React
const OLD_MODAL_ITEMS = [
  <Styled.Title>Your free therapy plan</Styled.Title>,
  <Styled.BannerContainer>
    <NYCPeopleCheckBanner />
    <ExtraTiny>Provided by the NYC Department of Health</ExtraTiny>
  </Styled.BannerContainer>,
  ...oldPlanDetailsItems.map((item) => (
    <>
      <Styled.PlanDetailsItem key={item.title} {...item} />
    </>
  )),
  <View flex={1} style={{ alignSelf: 'stretch' }}>
    <NeedHelpPill />
  </View>,
];
// TODO: END Delete this block

const TeensEligibleFreeTherapy = ({ step, updateStep, updateAndSave, isConsenter }: Props) => {
  const { isMobile } = useWindowWidthState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { ageLocationEligibility } = useFlags();
  const history = useHistory();
  const location = useLocation();

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const renderHeader = useCallback(
    () => <Header closeButton={<CloseButton onPress={closeModal} />} />,
    [closeModal]
  );
  const { clientState, eligiblePlan: { planID, partnerID, partnerName } = {} } = useHomePageState();
  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');

  const searchParams = new URLSearchParams(location.search);
  const qmPartnerCode = searchParams.get('qmPartnerCode');
  const newFlowSearch = `?qmPartnerCode=${qmPartnerCode}`;

  const onPressCallback = useCallback(() => {
    const age = JSON.parse(sessionStorage.getItem('TSQM_BasicInformation') || '{}')?.age;

    const isAdult = age >= 18;
    const shouldSwitchFlow = isAdult && clientState && EXCLUDED_STATES.includes(clientState);
    if (shouldSwitchFlow) {
      switchFlowHelper(FLOW_138_B2B_VOUCHER_GENERIC_V2, history, updateAndSave, {
        stepId: 1,
        newFlowSearch,
      });
    } else {
      updateStep(step.buttonTarget);
    }
  }, [history, updateAndSave, step.buttonTarget, updateStep, clientState, newFlowSearch]);

  return (
    <EmotionThemeProvider version="2.0.0">
      <Styled.Container isMobile={isMobile}>
        <View align="stretch">
          <Styled.Title variant="heading2xl">
            {isConsenter ? (
              <Trans t={tQuickmatchCommon} i18nKey="teenEligibleFreeTherapyStep.titleConsenter">
                Your teen is eligible for free therapy!
              </Trans>
            ) : (
              <Trans t={tQuickmatchCommon} i18nKey="teenEligibleFreeTherapyStep.title">
                You’re eligible for free therapy!
              </Trans>
            )}
          </Styled.Title>
          <TextDS variant="body">
            {isConsenter ? (
              <Trans
                t={tQuickmatchCommon}
                i18nKey="teenEligibleFreeTherapyStep.descriptionConsenter"
              >
                Now, let’s get your consent and hand it off to your teen to complete sign up.
              </Trans>
            ) : (
              <Trans t={tQuickmatchCommon} i18nKey="teenEligibleFreeTherapyStep.description">
                Great! Next, let’s find out how we can best support you. Your answers are
                confidential.
              </Trans>
            )}
          </TextDS>
          <View style={{ marginTop: spacing.space400 }} align="center" justify="center">
            {ageLocationEligibility ? (
              <TeenCelebrationIllustration />
            ) : (
              <TeenThoughtsIllustration />
            )}
          </View>
        </View>
        <View style={{ marginTop: 40 }}>
          <Styled.PlanInformationButton
            ageLocationEligibility={ageLocationEligibility}
            partnerID={partnerID}
            onPress={() => {
              trackEvent('View Plan Details', {
                planID,
                partnerID,
                partnerName,
                state: clientState,
                isConsenter,
              });
              setIsModalOpen(true);
            }}
          />
          <StickyDrawer>
            <Styled.ContinueButton
              text={tQuickmatchCommon(
                'teenEligibleFreeTherapyStep.continueButtonText',
                'Continue',
                undefined
              )}
              onPress={onPressCallback}
              dataQa="teensEligibleFreeTherapyContinueButton"
            />
            {!isConsenter && (
              <TextDS variant="bodyXs" colorRole="textSubtlest">
                <Trans
                  t={tQuickmatchCommon}
                  i18nKey="teenEligibleFreeTherapyStep.termsDisclaimerText"
                >
                  By clicking “Continue” you confirm that you are {{ minimum_age: 13 }} or older,
                  and accept the
                  <PolicyLink type={getPolicyLinkByState(clientState)} />
                </Trans>
              </TextDS>
            )}
            <EmergencyDisclaimer />
          </StickyDrawer>
        </View>
        <Modal isVisible={isModalOpen}>
          <Modal.FullScreenPanel onBackdropPress={closeModal} renderHeader={renderHeader}>
            {ageLocationEligibility ? (
              <FreeTherapyPlanDetails />
            ) : (
              <Styled.ModalContent>
                <SpacingView centerContent inheritJustify itemSpacing="space300">
                  {OLD_MODAL_ITEMS}
                </SpacingView>
                <Footer />
              </Styled.ModalContent>
            )}
            <StickyDrawer>
              <Button
                style={{ maxWidth: 350, width: '100%', marginTop: 0 }}
                text="Close"
                onPress={closeModal}
                dataQa="modalCloseButton"
              />
            </StickyDrawer>
          </Modal.FullScreenPanel>
        </Modal>
      </Styled.Container>
    </EmotionThemeProvider>
  );
};
export default TeensEligibleFreeTherapy;
