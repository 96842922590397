import { ResponsiveLayoutWithHeader, Spinner } from '@talkspace/react-toolkit';
import { useEffect } from 'react';

type SkipPaymentProps = {
  email: string;
  isLoading: boolean;
  handleSubmitSkipPayment: (email: string) => void;
};
const SkipPayment = ({ email, isLoading, handleSubmitSkipPayment }: SkipPaymentProps) => {
  useEffect(() => {
    if (!isLoading && email) {
      handleSubmitSkipPayment(email);
    }
  }, [email, isLoading, handleSubmitSkipPayment]);

  if (isLoading || !email) {
    return (
      <ResponsiveLayoutWithHeader
        renderHeader={() => null}
        hideHeader
        contentWrapperStyle={{
          height: '100%',
          marginTop: '20vh',
        }}
      >
        <Spinner />
      </ResponsiveLayoutWithHeader>
    );
  }

  return null;
};

export default SkipPayment;
