import { useMutation } from 'react-query';
import apiHelper from '@/core/api/apiHelper';
import apiWrapper from '@/core/api/apiWrapper';

export interface ConsenterReferralData {
  teenAge: number;
  teenZipcode: string;
  teenCountry: string;
  teenState: string;
  teenFirstName: string;
  teenLastName: string;
  consenterFirstName: string;
  consenterLastName: string;
  consenterRelationship: string;
  consenterPhoneNumber: string;
  consenterEmail: string;
  isConsentChecked: boolean;
  isEmailUpdatesChecked: boolean;
}

type ConsenterReferralResponseData = {
  preRegisterToken: string;
};

const submitConsenterReferralData = async ({
  teenAge,
  teenZipcode,
  teenCountry,
  teenState,
  teenFirstName,
  teenLastName,
  consenterFirstName,
  consenterLastName,
  consenterRelationship,
  consenterPhoneNumber,
  consenterEmail,
  isConsentChecked,
  isEmailUpdatesChecked,
}: ConsenterReferralData): Promise<ConsenterReferralResponseData> => {
  const data = (await apiWrapper.post(
    `${apiHelper().quickMatchApiEndpoint}api/v4/eligibility/consenter-referral/submit`,
    {
      teenAge,
      teenZipcode,
      teenCountry,
      teenState,
      teenFirstName,
      teenLastName,
      consenterFirstName,
      consenterLastName,
      consenterRelationship,
      consenterPhoneNumber,
      consenterEmail,
      isConsentChecked,
      isEmailUpdatesChecked,
    }
  )) as unknown as ConsenterReferralResponseData;
  return data;
};

const useMutationConsenterReferral = ({
  onSuccess,
  onError,
}: {
  onSuccess: (res: { preRegisterToken: string }) => void;
  onError: () => void;
}) =>
  useMutation<ConsenterReferralResponseData, Error, ConsenterReferralData>({
    mutationFn: submitConsenterReferralData,
    onSuccess,
    onError,
  });

export default useMutationConsenterReferral;
