/* eslint-disable import/prefer-default-export */
import { useMemo } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { ServiceType } from 'ts-frontend/types';
import {
  FLOW_132_CONSUMER_THERAPY,
  FLOW_67_CONSUMER_COUPLES_THERAPY,
  FLOW_68_CONSUMER_PSYCHIATRY,
  FLOW_140_TEEN_THERAPY,
  FLOW_200_EXPERIMENTS,
  FLOW_200_CONSUMER_THERAPY_EXPERIMENT_HUB,
  FLOW_128_NYC_TEENS,
} from '@/Flows';
import type { QMFlow } from '@/Flows/types';
import { useHomePageActions, useHomePageState } from '@/Components/HomePage';

export const useAvailableFlowsServiceTypeHash = (): Record<ServiceType, QMFlow> => {
  const flags = useFlags();
  const { getFlow } = useHomePageActions();
  const { partnerFlowID } = useHomePageState();
  const { unifiedFlowForDirectB2B } = flags;
  const isUnifiedFlowActiveAndTreatment =
    unifiedFlowForDirectB2B.experimentActive &&
    unifiedFlowForDirectB2B.variant === 'treatment' &&
    partnerFlowID;
  const isWhitelistedExperimentActive = FLOW_200_EXPERIMENTS.some((experimentName) => {
    const experiment = flags[experimentName];
    switch (typeof experiment) {
      case 'boolean':
        return experiment === true;
      case 'string':
        return experiment !== 'disabled';
      default:
        return experiment?.experimentActive && experiment.variant !== 'control';
    }
  });

  return useMemo(() => {
    if (isWhitelistedExperimentActive) {
      return {
        psychotherapy: getFlow(FLOW_200_CONSUMER_THERAPY_EXPERIMENT_HUB) as QMFlow,
        therapyCouples: getFlow(FLOW_67_CONSUMER_COUPLES_THERAPY) as QMFlow,
        therapyTeen: (!isUnifiedFlowActiveAndTreatment
          ? getFlow(FLOW_128_NYC_TEENS)
          : getFlow(FLOW_140_TEEN_THERAPY)) as QMFlow,
        psychiatry: getFlow(FLOW_68_CONSUMER_PSYCHIATRY) as QMFlow,
      };
    }
    return {
      psychotherapy: getFlow(FLOW_132_CONSUMER_THERAPY) as QMFlow,
      therapyCouples: getFlow(FLOW_67_CONSUMER_COUPLES_THERAPY) as QMFlow,
      therapyTeen: (!isUnifiedFlowActiveAndTreatment
        ? getFlow(FLOW_128_NYC_TEENS)
        : getFlow(FLOW_140_TEEN_THERAPY)) as QMFlow,
      psychiatry: getFlow(FLOW_68_CONSUMER_PSYCHIATRY) as QMFlow,
    };
  }, [isWhitelistedExperimentActive, getFlow, isUnifiedFlowActiveAndTreatment]);
};
