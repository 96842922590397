import { InsuranceEligibilityPayer, ServiceType } from 'ts-frontend/types';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { B2BForkResult, useHomePageState } from '../Components/HomePage';

interface UseBHOnboardingImprovementsProps {
  b2bForkResult?: B2BForkResult;
  insurancePayer?: InsuranceEligibilityPayer;
  service?: ServiceType | null;
}

const useBHOnboardingImprovements = (props?: UseBHOnboardingImprovementsProps): boolean => {
  const { onboardingImprovementsMilestone1 } = useFlags();
  const homePageState = useHomePageState(true);
  if (homePageState === null && !props) {
    return false;
  }
  const { insurancePayer, b2bForkResult, service } = homePageState || props || {};

  return !!(
    onboardingImprovementsMilestone1 &&
    insurancePayer &&
    service === 'psychotherapy' &&
    b2bForkResult === 'b2b'
  );
};

export default useBHOnboardingImprovements;
