import { useFlags } from 'launchDarkly/FlagsProvider';

interface OonCouponData {
  couponValue: number | null;
  coupon: string | null;
}
interface UseOonCouponExperimentOptions {
  isLoggedInUser?: boolean;
}

const useOonCouponExperiment = ({
  isLoggedInUser,
}: UseOonCouponExperimentOptions): OonCouponData => {
  const { oonDiscount } = useFlags();
  if (
    !isLoggedInUser &&
    oonDiscount?.experimentActive &&
    oonDiscount.variant !== 'control' &&
    oonDiscount.coupon &&
    oonDiscount.couponValue
  ) {
    return {
      couponValue: oonDiscount.couponValue,
      coupon: oonDiscount.coupon,
    };
  }
  return {
    couponValue: null,
    coupon: null,
  };
};

export default useOonCouponExperiment;
