/**
 * Flow: 147
 * Description: Teens DTE therapy - Experimental Optimized Flow from 128;
 *
 * Notes:
 *
 */

import { HomePageState } from '../Components/HomePage/types';
import getParamByName from '../utils/queryString';
// This import in particular needs user interaction, therefore, "safe" to be a cycle
// eslint-disable-next-line import/no-cycle
import { getClientFlow } from './index';
// eslint-disable-next-line import/no-cycle
import { FLOW_138_B2B_VOUCHER_GENERIC_V2 } from './Flow138';
// eslint-disable-next-line import/no-cycle
import { FLOW_7_B2B_COPAY_GENERIC } from './Flow7';

import { EligibilityType, QMFlow, UpdateStepObj } from './types';
import {
  allRegistrationPagesSteps,
  initFlowConfig,
  registrationStepsValidation,
  languageMatchingButtons,
  languageMatchingSelfServe,
  languageMatchingFilterHeading1,
  languageMatchingFilterHeading2,
  languageMatchingFilterButtons,
  routeToLanguageMatchingIfExperiment,
  skipToStepIfAnswerValue,
  languageMatchingSkipIfNoLanguages,
  skipToStepIfValidConsenterReferralLink,
} from './utils';
import { LANGUAGE_ID_ENGLISH } from '../Helpers/matchingLanguages';

const OFFER_ID = 62;
const INITIAL_OFFER_STEP_ID = 24;

export const getRegistrationStepIDByFlowID = (
  state: HomePageState,
  answer?: UpdateStepObj
): number => {
  const { organizationFlowID, partnerFlowID } = state;
  let { qmPartnerCode, cpPartnerCode } = state;
  let accessCodeType = getParamByName('accessCodeType') as AccessCodeType | null;

  if (
    !(accessCodeType || qmPartnerCode || cpPartnerCode) &&
    (answer?.accessCodeType || answer?.accessCode)
  ) {
    accessCodeType = answer.accessCodeType || null;
    // Attempt to find the cpPartnerCode first
    if (accessCodeType && accessCodeType === 'cpPartnerCode') {
      cpPartnerCode = answer.accessCode || '';
    }
    // Default to qmPartnerCode
    if (!cpPartnerCode) {
      qmPartnerCode = answer.accessCode || '';
    }
  }
  const flowID = organizationFlowID || partnerFlowID;
  let flow = flowID && getClientFlow(flowID);
  if (cpPartnerCode || accessCodeType === 'cpPartnerCode') {
    flow = getClientFlow(FLOW_7_B2B_COPAY_GENERIC);
  }
  if (qmPartnerCode || accessCodeType === 'qmPartnerCode') {
    flow = getClientFlow(FLOW_138_B2B_VOUCHER_GENERIC_V2);
  }
  if (flow) {
    const registrationStepIndexes = flow.steps
      .map((step) => registrationStepsValidation.findIndex((validationFun) => validationFun(step)))
      .filter((index) => index > -1);
    if (registrationStepIndexes.length > 0) {
      return INITIAL_OFFER_STEP_ID + registrationStepIndexes[0];
    }
  }
  return INITIAL_OFFER_STEP_ID;
};

/** Service Specific Funnels: Therapy */
export const FLOW_147_TEENSPACE_OPTIMIZED = 147;

const progressMax = 8;
const flow: QMFlow = {
  flowId: FLOW_147_TEENSPACE_OPTIMIZED,
  flowConfig: {
    ...initFlowConfig,
    serviceType: 'therapyTeen',
    isB2B: true,
    isTeen: true,
    eligibilityType: EligibilityType.zipCodeEligibility,
    version: 3,
  },
  steps: [
    {
      id: 1,
      category: 'teensHereToHelp',
      isInterstitial: true,
      buttonTarget: 2,
      nextQuestionActions: [skipToStepIfValidConsenterReferralLink(3)],
    },
    {
      id: 2,
      analyticsId: 54,
      category: 'teenZipcodeEligibility',
      buttonTarget: 3,
      response_category_id: 9,
    },
    {
      id: 192,
      analyticsId: 54,
      category: 'ageLocationIneligible',
      response_category_id: 8,
      hideBackButton: false,
      heading1: 'Let’s explore other options',
      heading2:
        'The information you entered didn’t match any city or state programs we have (yet!)',
      buttons: [
        {
          text: 'Check insurance coverage',
          externalTarget: null,
          internalTarget: 15,
          answerValue: 'Check insurance coverage',
        },
        {
          text: 'See if I’m covered by an employer',
          externalTarget: null,
          internalTarget: 1,
          answerValue: 'See if I’m covered by an employer',
        },
      ],
      noFooter: true,
    },
    {
      id: 3,
      category: 'teensEligibleFreeTherapy',
      isInterstitial: true,
      buttonTarget: 11,
      noFooter: true,
    },
    {
      id: 11,
      prompt: 'Preferred provider gender',
      analyticsId: 57,
      response_category_id: 3,
      category: 'select1',
      heading2: 'What gender do you prefer in a therapist?',
      selfServe: {
        field: 'therapistGender',
      },
      buttons: [
        {
          text: 'No preference',
          externalTarget: null,
          internalTarget: 12,
          answerValue: undefined,
        },
        {
          text: 'Female',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 2,
        },
        {
          text: 'Male',
          externalTarget: null,
          internalTarget: 12,
          answerValue: 1,
        },
        {
          text: "I'm not sure",
          externalTarget: null,
          internalTarget: 12,
          answerValue: undefined,
        },
      ],
      progressCurrent: 5,
      progressMax,
      nextQuestionActions: {
        12: [routeToLanguageMatchingIfExperiment(190, 12)],
      },
    },
    {
      id: 190,
      prompt: 'What’s your preferred language?',
      analyticsId: 140,
      response_category_id: 7,
      category: 'selectDynamic',
      dataDependencies: [{ key: 'matchingLanguages', options: { forceRefetch: true } }],
      heading1: 'What’s your preferred language?',
      heading2: 'Based on the state you selected, there are providers who speak these languages:',
      buttons: languageMatchingButtons(191),
      skipQuestionActions: [languageMatchingSkipIfNoLanguages(12)],
      nextQuestionActions: {
        191: [
          (state, answer, flags) => {
            if (answer.response_value === LANGUAGE_ID_ENGLISH) {
              return 12;
            }
            return undefined;
          },
        ],
      },
      progressCurrent: 6,
      progressMax,
    },
    {
      id: 191,
      // This prompt does not show to users, but is used in analytics, the "[language]" part is intentional
      prompt: 'Are you willing to wait for a [language]-speaking provider?',
      analyticsId: 141,
      response_category_id: 7,
      category: 'selectDynamic',
      selfServe: languageMatchingSelfServe(190, 191),
      heading1: languageMatchingFilterHeading1(190),
      heading2: languageMatchingFilterHeading2(190),
      buttons: languageMatchingFilterButtons(12),
      skipQuestionActions: [skipToStepIfAnswerValue(12, `${LANGUAGE_ID_ENGLISH}`)],
      progressCurrent: 7,
      progressMax,
    },
    {
      id: 12,
      category: 'teenThankYouForSharing',
      isInterstitial: true,
      buttonTarget: INITIAL_OFFER_STEP_ID,
      progressCurrent: 8,
      progressMax,
      nextQuestionActions: [getRegistrationStepIDByFlowID],
    },
    ...allRegistrationPagesSteps({
      initialStepID: INITIAL_OFFER_STEP_ID,
      offersPageSettings: {
        offerID: OFFER_ID,
        previousStepsUntilMatches: 2,
      },
      registerWithVoucherSettings: { isNYCTeen: true },
    }),
  ],
};

export default flow;
