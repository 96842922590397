import { TextDS } from '@talkspace/react-toolkit';
import { Trans, useTranslation } from '@talkspace/i18n';

import {
  Check,
  CircleExclamation,
  StarShooting,
} from '@talkspace/react-toolkit/src/designSystems/icons';
import Banner from '@talkspace/react-toolkit/src/designSystems/components/Banner';

type BannerType = 'teenspaceInfo' | 'eligibleConsenter' | 'ineligibleConsenterReferralLink';

interface Props {
  type: BannerType;
}
const TeenBanner = ({ type }: Props) => {
  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');
  switch (type) {
    case 'teenspaceInfo':
      return (
        <Banner centered variant="info" Icon={StarShooting}>
          <TextDS variant="bodySm">
            <Trans t={tQuickmatchCommon} i18nKey="teenBanner.teenspaceInfo">
              Teenspace is
              <TextDS inline variant="headingSm">
                {' '}
                completely FREE{' '}
              </TextDS>
              in many cities
            </Trans>
          </TextDS>
        </Banner>
      );
    case 'eligibleConsenter':
      return (
        <Banner centered variant="success" Icon={Check}>
          <TextDS variant="bodySm">
            <Trans t={tQuickmatchCommon} i18nKey="teenBanner.eligibleConsenter">
              Teenspace is
              <TextDS inline variant="headingSm">
                {' '}
                completely FREE{' '}
              </TextDS>
              for your teen
            </Trans>
          </TextDS>
        </Banner>
      );
    case 'ineligibleConsenterReferralLink':
      return (
        <Banner centered variant="warning" Icon={CircleExclamation}>
          <TextDS variant="headingSm">
            <Trans t={tQuickmatchCommon} i18nKey="teenBanner.ineligibleConsenterReferralLink">
              Oops! Looks like that link doesn’t work. Don’t worry, you can still sign up.
            </Trans>
          </TextDS>
        </Banner>
      );
    default:
      return null;
  }
};

export default TeenBanner;
