import React, { VFC } from 'react';
import moment from 'moment';
import {
  BookingAvatars,
  BookingDates,
  BookingPolicyMessages,
  BookingTimeZone,
  Button,
  CancellationPolicyMessage,
  View,
} from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import { TherapistTimeslot } from 'ts-frontend/types';
import { useReturningUserData } from './useReturningUserData';
import appConfigs from '../../../../utils/configs';
import { ReturningUserData } from '@/Components/HomePage';
import { BookingConfirmationStep, UpdateStep } from '@/Flows';

const getTherapistTimeslot = ({
  appointmentStartTime,
  appointmentEndTime,
}: ReturningUserData): TherapistTimeslot => {
  return {
    start: appointmentStartTime,
    end: appointmentEndTime,
  };
};

const BookingConfirmationContent: VFC<{
  step: BookingConfirmationStep;
  updateStep: UpdateStep;
  onCancellationPolicy: () => void;
}> = ({ step, updateStep, onCancellationPolicy }) => {
  const returningUserData = useReturningUserData();
  const { t: tBookingScreen } = useTranslation('bookingScreen');
  const cdnEndpoint = appConfigs?.endpoints?.cdnEndpoint;
  const userTimezone = moment.tz.guess();
  const modality = 'video';
  const selectedTimeslot = getTherapistTimeslot(returningUserData);

  const handleAgreeClicked = () => {
    updateStep(step.buttonTarget);
  };

  return (
    <>
      <View align="center" justify="space-between" style={{ alignItems: 'center' }}>
        <BookingAvatars
          therapistUserID={returningUserData.therapistID}
          shouldShowBookingSuccess={false}
          selectedTimeslot={selectedTimeslot}
          cdnBaseUrl={cdnEndpoint}
        />
      </View>

      <BookingDates
        modality={modality}
        sessionStart={returningUserData.appointmentStartTime}
        sessionDuration={returningUserData.appointmentDuration}
        isTherapist={false}
      />
      <View flex={1} align="center">
        <BookingTimeZone timezone={userTimezone} />
        <BookingPolicyMessages />
        <View style={{ paddingTop: '20px', width: '70%' }}>
          <Button
            onPress={handleAgreeClicked}
            text={tBookingScreen('available.agree', 'I agree', undefined)}
            dataQa="inRoomSchedulingConfirmBookingIAgree"
            stretch
          />
          <CancellationPolicyMessage
            onCancellationPolicyPress={onCancellationPolicy}
            style={{ marginTop: 22 }}
          />
        </View>
      </View>
    </>
  );
};

export default BookingConfirmationContent;
