import { DSIcon, View } from '../../../components';
import { useEmotionTheme } from '../../..';
import { DEFAULT_HEADER_HEIGHT } from '../../../components/ResponsiveLayoutWithHeader';

export type BannerVariant = 'info' | 'success' | 'warning' | 'critical';
interface Props {
  variant: BannerVariant;
  Icon?: DSIcon;
  centered?: boolean;
  children: React.ReactNode;
}

const Banner = ({ variant, Icon, centered, children }: Props) => {
  const { colorRoles, spacing } = useEmotionTheme();

  const variantToColor: Record<BannerVariant, string> = {
    info: colorRoles.surfaces.infoSubtle,
    success: colorRoles.surfaces.successSubtleDefault,
    warning: colorRoles.surfaces.warningSubtleDefault,
    critical: colorRoles.surfaces.criticalSubtleDefault,
  };
  return (
    <View
      row
      align={centered ? 'center' : 'start'}
      justify={centered ? 'center' : 'start'}
      style={{
        backgroundColor: variantToColor[variant],
        padding: spacing('space150'),
        position: 'absolute',
        top: DEFAULT_HEADER_HEIGHT,
        left: 0,
        right: 0,
      }}
    >
      {Icon && (
        <View style={{ marginRight: spacing('space100') }}>
          <Icon colorType={variant} />
        </View>
      )}
      {children}
    </View>
  );
};

export default Banner;
