import * as React from 'react';
import Svg, { Path } from 'svgs';

function CloudySun() {
  const titleText = 'Sun covered by clouds';
  return (
    <Svg
      width={120}
      height={120}
      viewBox="0 0 120 120"
      fill="none"
      title={titleText}
      aria-label={titleText}
    >
      <Path
        d="M15.172 52.835l4.1-.8c1.54-.304 1.1-2.627-.457-2.34l-4.099.8c-1.537.303-1.106 2.624.456 2.34zM17.482 40.364l-5.799-1.699c-1.516-.437-2.18 1.845-.67 2.287l5.799 1.699c1.533.447 2.165-1.85.67-2.287zM20.49 30.745l-4.683-4.614c-1.123-1.104-2.796.589-1.673 1.697l4.683 4.614c1.127 1.108 2.794-.592 1.673-1.697zM22.723 18.793l3.177 5.51c.785 1.355 2.845.166 2.065-1.191l-3.177-5.51c-.793-1.366-2.85-.175-2.065 1.19zM37.657 15.401l.3 3.6c.133 1.584 2.506 1.377 2.376-.199l-.3-3.6c-.134-1.581-2.505-1.366-2.376.2zM50.388 15.044a22.439 22.439 0 00-1.795 4.541c-.175.635.2 1.291.834 1.465 1.717.455 1.196-1.35 3.068-4.892a1.192 1.192 0 00-2.107-1.114zM59.782 20.163l-2.699 3.498c-.607.786-.036 1.92.943 1.92 1.016 0 1.166-.977 3.643-3.963.964-1.25-.927-2.704-1.886-1.455zM66.122 41.355c3.158.373 6.102 2.595 7.326 5.532.608 1.452 2.806.538 2.2-.919-1.569-3.762-5.199-6.502-9.247-6.98-1.599-.152-1.818 2.185-.28 2.367zM18.096 74.642c-2.498 1.995-1.349 6.13.356 6.13.998 0 1.573-1.18.907-1.964-.534-.625-.423-1.788.223-2.304 1.233-.982-.253-2.844-1.486-1.862zM93.514 86.374c2.557-1.77 4.035-5.036 3.68-8.125-.076-.653-.672-1.11-1.321-1.047a1.192 1.192 0 00-1.047 1.32c.258 2.24-.814 4.608-2.668 5.892-1.297.896.06 2.853 1.356 1.96zM61.007 75.084c-.732-.797-2.067-.28-2.07.802-.005 1.18-.95 2.402-2.25 2.901-3.014 1.161-6.228-.958-8.754-2.663-1.304-.88-2.64 1.09-1.332 1.976 3.284 2.215 7.002 4.428 10.94 2.912 1.564-.602 2.797-1.824 3.391-3.252 3.326 1.094 6.522-2.145 4.639-3.321a1.19 1.19 0 00-1.642.378c-.266.425-.79.714-1.402.77-.62.052-1.181-.132-1.52-.503z"
        fill="#005C65"
      />
      <Path
        d="M106.292 72.804c5.735-7.611.299-21.117-13.372-21.07a18.464 18.464 0 00-4.229.124c-1.437-5.781-4.047-9.7-7.97-13.743-.976-.91-2.011-1.97-3.674-3.047-4.247-2.747-9.672-3.811-14.662-2.949-13.576-16.24-41.128-7.085-41.128 13.674 0 2.386.413 4.713 1.189 6.94-.437.567-.878 1.08-1.551 2.56-2.162 3.099-2.898 8.346-.946 12.698-.14.015-.28.03-.42.05-4.121-.68-8.643 2.614-9.721 6.57-.916 3.36-.969 6.576.573 9.716.28.57 1.801 2.151 1.803 2.65.024 5.805 6.997 12.778 12.802 8.721 5.314 7.914 15.812 11.165 25.264 9.931 9.452-1.233 17.997-6.245 25.6-11.995 5.48 2.825 11.555 4.852 17.712 4.522 6.158-.33 12.395-3.335 15.483-8.672 3.088-5.338 2.197-13.002-2.753-16.68zM85.145 53.682c.052.76.796 1.282 1.541 1.056 3.934-1.219 8.278-.845 11.92 1.026 7.845 4 8.635 12.718 3.581 16.28-.852.6-.575 1.937.453 2.142 2.567.512 4.662 4.55 4.577 7.498-.159 5.49-7.204 12.74-16.981 13.144-4.743.177-9.569-1.365-13.255-4.287a1.195 1.195 0 00-1.427-.04c-8.742 6.16-17.002 9.628-24.55 10.313-9.25.837-18.26-2.37-23.498-8.378a1.192 1.192 0 00-1.064-.398c-2.937.418-5.805.171-7.676-.646-2.028-.886-3.566-3.252-3.43-5.274.023-.353-.11-.7-.367-.943-2.642-2.515-3.417-6.702-1.844-9.962 1.55-3.222 5.42-5.312 9-4.868.902.115 1.588-.787 1.255-1.623-3.85-9.658 1.256-14.835 1.305-14.993.162-.12 4.343-5.508 11.762-5.29 4.807.118 9.56 2.803 12.106 6.836.767 1.213 2.657.254 2.11-1.086-2.282-5.597.79-13.977 6.448-17.58 1.496-.955 3.2-1.644 5.066-2.047l.002-.001c11.864-2.512 22.124 6.967 22.966 19.12zM59.792 32.739c-8.077 2.635-12.455 10.78-12.266 17.719-6.967-6.136-16.847-5.64-23.236.245C18.85 30.63 45.606 17.636 59.792 32.74z"
        fill="#005C65"
      />
    </Svg>
  );
}

export default CloudySun;
