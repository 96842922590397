import { FunctionComponent, useCallback, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import {
  A11yFullAddress,
  Button,
  useEmotionTheme,
  StickyDrawer,
  ExtraHuge,
  Large,
  View,
  TextDS,
} from '@talkspace/react-toolkit';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from '@talkspace/i18n';
import { InsuranceAssociatedAddressStep, UpdateStep } from '@/Flows/types';
import { HomePageState, useHomePageActions } from '@/Components/HomePage';
import { states } from '@/Helpers/locales';
import countriesHelper from '@/Helpers/countriesHelper';
import { countriesDropdownOptions, countryTestFunction } from '../../OneFormEligibility/util';
import styled from '@/core/styled';

interface InsuranceAssociatedAddressFormProps {
  step: InsuranceAssociatedAddressStep;
  updateStep: UpdateStep;
  clientCountry: HomePageState['clientCountry'];
  clientState: HomePageState['clientState'];
  insuranceDetails?: HomePageState['insuranceDetails'];
  insuranceEligibility?: HomePageState['insuranceEligibility'];
  quickEligibilityInfo?: HomePageState['quickEligibilityInfo'];
  isUpdatingStep: boolean;
}

export enum InsuranceAssociatedAddressFormFieldNames {
  addressLine1 = 'addressLine1',
  addressLine2 = 'addressLine2',
  city = 'city',
  clientState = 'clientState',
  zipcode = 'zipcode',
  country = 'country',
}

export interface InsuranceAssociatedAddressFormFields {
  [InsuranceAssociatedAddressFormFieldNames.addressLine1]: string | undefined;
  [InsuranceAssociatedAddressFormFieldNames.addressLine2]: string | undefined;
  [InsuranceAssociatedAddressFormFieldNames.city]: string | undefined;
  [InsuranceAssociatedAddressFormFieldNames.clientState]: string | undefined;
  [InsuranceAssociatedAddressFormFieldNames.zipcode]: string | undefined;
  [InsuranceAssociatedAddressFormFieldNames.country]: string | undefined;
}

export interface InsuranceAssociatedAddressFormYupContext {
  flowId?: number;
  isAddressRequired?: boolean;
  specificCountryCodesToSupport?: string[];
  removeAddress?: boolean;
  addressLine1ErrorMessage?: string;
}

export const Styled = {
  Heading: styled(ExtraHuge)(() => {
    return {
      marginBottom: 10,
    };
  }),
  Subheading: styled(Large)(({ theme: { colors } }) => {
    return {
      fontSize: 16,
      color: colors.darkGray,
      marginBottom: 32,
    };
  }),
  Form: styled.form({
    width: 335,
    outline: 'none',
    display: 'flex',
    flexDirection: 'column',
    margin: '30px 10px 0px 10px',
    alignSelf: 'center',
  }),
  Footer: styled(View)({
    maxWidth: 335,
    marginTop: 25,
  }),
};

const InsuranceAssociatedAddressForm: FunctionComponent<InsuranceAssociatedAddressFormProps> = ({
  step,
  updateStep,
  clientCountry,
  clientState,
  insuranceDetails,
  insuranceEligibility,
  quickEligibilityInfo,
  isUpdatingStep,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { setHomePageState } = useHomePageActions();
  const { colors } = useEmotionTheme();
  const { t: tQuickmatchFlow232 } = useTranslation('quickmatch.flow232');

  const disableContinue = isSubmitting || isUpdatingStep;

  const insuranceAssociatedAddressFormSchema: yup.SchemaOf<InsuranceAssociatedAddressFormFields> =
    yup.object().shape({
      // address is required for certain organization flows and all file-based, manual, and trizetto eligibility flows
      addressLine1: yup
        .string()
        .required('Enter your home address')
        .test({
          name: 'address-line-test',
          test: async (addressLine1Value, context) => {
            const errorMessage =
              context.options.context?.addressLine1ErrorMessage || 'Address is missing or invalid.';
            const schema = yup.string().optional();
            return (
              (await schema.isValid(addressLine1Value)) ||
              context.createError({
                message: errorMessage,
              })
            );
          },
        }),
      // never required
      addressLine2: yup.string().optional(),
      city: yup.string().required('Enter your city'),
      // Dropdown alternative to state
      clientState: yup
        .string()
        .required('Select your state')
        .test(
          'is-valid-state',
          'State is invalid.',
          (
            userInput: InsuranceAssociatedAddressFormFields['clientState'],
            testContext: yup.TestContext<InsuranceAssociatedAddressFormFields>
          ) => {
            const hasSelectedUS = testContext.parent.country === 'US';
            const clientStateInput = testContext.parent.clientState;
            const stateRegex = /^[a-zA-Z]{2}$/g;
            if (hasSelectedUS) {
              if (userInput && userInput.length > 0) {
                return stateRegex.test(userInput);
              }
              if (clientStateInput.length > 0) {
                return stateRegex.test(clientStateInput);
              }
            }
            return true;
          }
        ),
      zipcode: yup.string().required('Enter your zip code'),
      // required for some flows
      // some flows have configs that dictate whether or not the services are for specific countries residents only (flowConfig.specificCountryCodesToSupport)
      country: yup
        .string()
        .required('Select your country')
        .test(
          'is-valid-country',
          'Services are not covered for selected country',
          (
            userInput?: string,
            testContext?: yup.TestContext<InsuranceAssociatedAddressFormYupContext>
          ) => {
            const { isAddressRequired } = testContext?.options.context || {};
            return countryTestFunction(userInput, testContext, isAddressRequired);
          }
        ),
    });

  const methods = useForm<
    InsuranceAssociatedAddressFormFields,
    InsuranceAssociatedAddressFormYupContext
  >({
    resolver: yupResolver(insuranceAssociatedAddressFormSchema),
    context: {},
    // defaultValues seem to be necessary for proper error focus when using a resolver
    defaultValues: {
      addressLine1: insuranceDetails?.streetAddress || '',
      city: insuranceDetails?.city || '',
      zipcode: insuranceDetails?.zip || '',
      country: clientCountry || '',
      clientState: clientState || '',
    } as InsuranceAssociatedAddressFormFields,
  });

  const { handleSubmit } = methods;

  const onSubmit = useCallback(
    (formData: InsuranceAssociatedAddressFormFields) => {
      setIsSubmitting(true);
      const insuranceAssociatedAddressFormData = JSON.parse(JSON.stringify(formData));

      const { state, country } = countriesHelper.getStateAndCountryOverrides(
        insuranceAssociatedAddressFormData.country,
        insuranceAssociatedAddressFormData.clientState
      );

      insuranceAssociatedAddressFormData.country = country;
      insuranceAssociatedAddressFormData.clientState = state;

      setHomePageState({
        clientCountry: country,
        clientState: state,
        insuranceDetails: {
          ...insuranceDetails,
          streetAddress: insuranceAssociatedAddressFormData.addressLine1,
          city: insuranceAssociatedAddressFormData.city,
          state,
          zip: insuranceAssociatedAddressFormData.zipcode,
        },
        insuranceEligibility: {
          ...insuranceEligibility,
          isEligible: insuranceEligibility?.isEligible ?? false,
          streetAddress: insuranceAssociatedAddressFormData.addressLine1,
          city: insuranceAssociatedAddressFormData.city,
          state,
          zip: insuranceAssociatedAddressFormData.zipcode,
        },
        quickEligibilityInfo: {
          state,
          country,
          insuranceEligibility: {
            ...quickEligibilityInfo?.insuranceEligibility,
            streetAddress: insuranceAssociatedAddressFormData.addressLine1,
            city: insuranceAssociatedAddressFormData.city,
            zip: insuranceAssociatedAddressFormData.zipcode,
            isEligible: quickEligibilityInfo?.insuranceEligibility?.isEligible ?? false,
            isVideoOnlyPlan: quickEligibilityInfo?.insuranceEligibility?.isVideoOnlyPlan ?? false,
            verificationSucceeded:
              quickEligibilityInfo?.insuranceEligibility?.verificationSucceeded ?? false,
          },
        },
      });

      updateStep(step.buttonTarget, undefined);
    },
    [
      insuranceDetails,
      insuranceEligibility,
      quickEligibilityInfo?.insuranceEligibility,
      setHomePageState,
      step.buttonTarget,
      updateStep,
    ]
  );

  return (
    <FormProvider {...methods}>
      <Styled.Form onSubmit={handleSubmit(onSubmit)}>
        <Styled.Heading>
          {tQuickmatchFlow232('step110.titleText', 'Check your coverage', undefined)}
        </Styled.Heading>
        <Styled.Subheading>
          {tQuickmatchFlow232(
            'step110.subtitleText',
            `This should be the address that’s associated with your insurance plan`,
            undefined
          )}
        </Styled.Subheading>
        <A11yFullAddress
          addressLine1Name={InsuranceAssociatedAddressFormFieldNames.addressLine1}
          addressLine2Name={InsuranceAssociatedAddressFormFieldNames.addressLine2}
          cityName={InsuranceAssociatedAddressFormFieldNames.city}
          stateName={InsuranceAssociatedAddressFormFieldNames.clientState}
          zipcodeName={InsuranceAssociatedAddressFormFieldNames.zipcode}
          countryName={InsuranceAssociatedAddressFormFieldNames.country}
          states={states}
          countries={countriesDropdownOptions}
          qaAttributeUniqueNaming="InsuranceAssociatedAddressForm"
        />
        <Styled.Footer>
          <TextDS variant="bodySm" colorRole="textSubtle">
            {tQuickmatchFlow232(
              'step101.footerText',
              'Talkspace is committed to protecting your privacy and follows HIPAA, state and federal laws.',
              undefined
            )}
          </TextDS>
        </Styled.Footer>
        <StickyDrawer>
          <Button
            dataQa="insuranceAssociatedAddressFormContinueButton"
            text={tQuickmatchFlow232('step110.buttonText1', 'Continue', undefined)}
            roundedFocusStyle
            disabled={disableContinue}
            isLoading={disableContinue}
            primaryColor={colors.green}
            style={{
              alignSelf: 'center',
              width: '100%',
            }}
          />
        </StickyDrawer>
      </Styled.Form>
    </FormProvider>
  );
};

export default InsuranceAssociatedAddressForm;
