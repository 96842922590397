import { ErrorScreen, View, Button, Big, useEmotionTheme, spacing } from '@talkspace/react-toolkit';
import { WebPageError } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import appConfigs from '@/utils/configs';
import styled from '@/core/styled';

export type ActionButtonProps = {
  text: string;
  dataQa: string;
  onClick: () => void;
};

export interface Props {
  actionButton: ActionButtonProps;
}

const ActionButton = styled(Button)(() => {
  const { space500 } = spacing;

  return {
    alignSelf: 'center',
    width: '100%',
    fontWeight: 'bold',
    marginTop: space500,
  };
});

const LogInButton = styled(Button)(({ theme: { colors } }) => {
  return {
    color: colors.green,
    backgroundColor: 'transparent',
    border: 'none',
    width: 'auto',
    alignSelf: 'center',
    transition: 'all 200ms ease-in-out',
    '&:hover': {
      color: colors.permaTropicalRainForest,
    },
  };
});

const GeneralErrorScreen = ({ actionButton }: Props) => {
  const { colors } = useEmotionTheme();

  const handleLogInButtonPress = () => {
    window.location.href = `${appConfigs.endpoints.clientWebEndpoint}/login`;
  };

  return (
    <View style={{ width: '100%' }}>
      <ErrorScreen
        illustration={<WebPageError />}
        hasButton={false}
        hideHeader
        title="Something went wrong"
        description="Please try again, or log in if you’ve already signed up."
        safeAreaVariant="none"
        contentWrapperStyle={{ height: '100%' }}
      >
        <ActionButton
          dataQa={actionButton.dataQa}
          text={actionButton.text}
          roundedFocusStyle
          primaryColor={colors.green}
          onPress={actionButton.onClick}
        />
        <LogInButton
          dataQa="GeneralErrorScreenLogInButton"
          primaryColor={colors.green}
          roundedFocusStyle
          onPress={handleLogInButtonPress}
        >
          <Big style={{ color: colors.green }}>Log in</Big>
        </LogInButton>
      </ErrorScreen>
    </View>
  );
};

export default GeneralErrorScreen;
