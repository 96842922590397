import { ComponentProps, useCallback } from 'react';
import camelCase from 'lodash/camelCase';
import { EligibilityWarningSuccess } from '@talkspace/react-toolkit';
import { trackUpdateCoverageEvent } from '@/utils/analytics/events';

type EligibilityWarningSuccessContainerProps = Pick<
  ComponentProps<typeof EligibilityWarningSuccess>,
  | 'isNewProvider'
  | 'onPressCTA'
  | 'sessionsCanceled'
  | 'sessionsTransferred'
  | 'isSameRoom'
  | 'isPsych'
  | 'createRoomReturnProps'
  | 'onPressSecondaryCTA'
> & { updateCoverageRoomID?: number | undefined; previousTherapistId?: number | null };

const EligibilityWarningSuccessContainer = (props: EligibilityWarningSuccessContainerProps) => {
  const {
    isSameRoom,
    updateCoverageRoomID,
    previousTherapistId,
    onPressSecondaryCTA,
    onPressCTA,
    sessionsCanceled,
    sessionsTransferred,
  } = props;
  const ctaText = isSameRoom ? 'Done' : 'Go to new room';
  const secondaryCtaText = isSameRoom ? undefined : 'Finish services in old room';
  const hasPreviousProvider = !!previousTherapistId && Number(previousTherapistId) !== 0;
  const hasPreviousRoom = !!updateCoverageRoomID && Number(updateCoverageRoomID) !== 0;
  const onPressSecondaryCTAIfHasPreviousRoom = hasPreviousRoom ? onPressSecondaryCTA : undefined;

  const onPress = useCallback(() => {
    if (onPressCTA) {
      if (updateCoverageRoomID) {
        trackUpdateCoverageEvent(updateCoverageRoomID || 0, {
          providerAvailable: hasPreviousProvider,
          hasRemainingSessions: sessionsCanceled || sessionsTransferred,
        }).then(() => {
          onPressCTA();
        });
      }
      onPressCTA();
    }
  }, [
    updateCoverageRoomID,
    hasPreviousProvider,
    sessionsCanceled,
    sessionsTransferred,
    onPressCTA,
  ]);
  return (
    <EligibilityWarningSuccess
      {...props}
      sessionsCanceled={sessionsCanceled}
      sessionsTransferred={sessionsTransferred}
      onPressCTA={onPress}
      ctaText={ctaText}
      secondaryCtaText={secondaryCtaText}
      dataQa={`${camelCase(ctaText)}Button`}
      showFaq={false}
      hasPreviousProvider={hasPreviousProvider}
      onPressSecondaryCTA={onPressSecondaryCTAIfHasPreviousRoom}
    />
  );
};

export default EligibilityWarningSuccessContainer;
