import { FunctionComponent, useState, useCallback, useEffect, useMemo } from 'react';
import {
  Button,
  View,
  Text,
  Large,
  RedX,
  useEmotionTheme,
  Spinner,
  Standard,
  Link,
} from '@talkspace/react-toolkit';
import { AxiosError } from 'axios';
import styled from '@/core/styled';
import TherapistSelectedConfirmation from '../Components/TherapistSelectedConfirmation/TherapistSelectedConfirmation';
import appConfigs from '../utils/configs';
import { createRoom } from '../Helpers/apiService';
import ReactFrameService from '../utils/reactFrame/ReactFrameService';
import { HomePageState } from '../Components/HomePage/types';
import { useHomePageActions } from '../Components/HomePage';
import { RegistrationErrors, INSURANCE_INFO_ERROR_CODE } from '@/Helpers/registrationHelpers';
import { setUpdateCoverageComplete } from '@/utils/registrationHelpers';

interface EligibilityStartTherapyContainerProps {
  therapistFirstName: string;
  therapistId: number;
  updateCoverageRoomID?: number;
  voucherCode: string;
  quickmatchResponses: any;
  presentingProblems: any;
  funnelVariation: HomePageState['funnelVariation'];
  clientDateOfBirth?: string;
  appointment: HomePageState['appointment'];
  clientGender?: number;
  isNoMatches?: boolean;
  redirectFrom: number;
  isCreateRoomFlow: boolean;
  isReactivationFlow: boolean;
  isUpdateCoverageFlow: boolean;
  isMBHIneligibilityFlow: boolean;
  sessionsCanceled: boolean | undefined;
  sessionsTransferred: boolean | undefined;
  providerAvailable?: boolean;
  hasRemainingSessions?: boolean;
  oldRoomID?: number;
  setCreateRoomReturnProps: (props: {
    oldRoomHasSessions: boolean;
    sessionsCanceled: boolean;
    sessionsTransferred: boolean;
    therapistID: number;
  }) => void;
  previousTherapistId: number | null;
  existingUserEmail: string;
  therapistIDFromCreateRoom: number | undefined;
}

const REACT_APP_CDN_ENDPOINT = appConfigs.endpoints.cdnEndpoint;

const ErrorResultWrapper = styled(View)({
  alignSelf: 'center',
  alignItems: 'center',
});

const ErrorCloseButton = styled(Button)(({ theme: { colors } }) => {
  return { marginTop: 40, marginBottom: 60, backgroundColor: colors.white };
});

const EligibilityStartTherapyContainer: FunctionComponent<
  EligibilityStartTherapyContainerProps
> = ({
  therapistFirstName,
  therapistId,
  updateCoverageRoomID,
  voucherCode,
  quickmatchResponses,
  presentingProblems,
  funnelVariation,
  clientDateOfBirth,
  appointment,
  clientGender,
  isNoMatches,
  redirectFrom,
  isCreateRoomFlow,
  isReactivationFlow,
  isUpdateCoverageFlow,
  isMBHIneligibilityFlow,
  sessionsCanceled,
  sessionsTransferred,
  providerAvailable,
  hasRemainingSessions,
  setCreateRoomReturnProps,
  oldRoomID,
  previousTherapistId,
  existingUserEmail,
  therapistIDFromCreateRoom,
}) => {
  const { colors } = useEmotionTheme();
  const [isError, setIsError] = useState(false);
  const [error, setError] = useState('');
  const [newRoomID, setNewRoomID] = useState<number | undefined>(undefined);
  const { setIsRegistrationError } = useHomePageActions();

  const handleError = useCallback(
    (e) => {
      const err = e as AxiosError<{ errors: { code: string; message: string }[] }>;
      const errors = err?.response?.data?.errors || [];

      if (errors.length && errors[0].code !== 'paramsError') {
        errors.forEach(({ code, message }) => {
          if (code === INSURANCE_INFO_ERROR_CODE) {
            setError(RegistrationErrors[message]);
          }
        });
      }

      setIsError(true);
      setIsRegistrationError(true);
    },
    [setIsRegistrationError]
  );

  const createRoomParams = useMemo(() => {
    return {
      therapistId,
      updateCoverageRoomID,
      voucherCode,
      quickmatchResponses: quickmatchResponses.filter(
        (quickmatchResponse) =>
          quickmatchResponse.payfirst_step_prompt || quickmatchResponse.response_category_id
      ),
      presentingProblems,
      funnelVariation,
      clientDateOfBirth,
      appointment,
      gender: clientGender || undefined,
      isPendingMatch: !!isNoMatches,
      email: existingUserEmail,
    };
  }, [
    therapistId,
    updateCoverageRoomID,
    voucherCode,
    quickmatchResponses,
    presentingProblems,
    funnelVariation,
    clientDateOfBirth,
    appointment,
    clientGender,
    isNoMatches,
    existingUserEmail,
  ]);

  const createRoomBeforeConfirm = useCallback(async () => {
    try {
      const createRoomResponse = await createRoom(createRoomParams);
      setCreateRoomReturnProps({
        oldRoomHasSessions: createRoomResponse.oldRoomHasSessions,
        sessionsCanceled: createRoomResponse.sessionsCanceled,
        sessionsTransferred: createRoomResponse.sessionsTransferred,
        therapistID: createRoomResponse.therapistID,
      });
      setUpdateCoverageComplete();
      setNewRoomID(createRoomResponse.roomID);
    } catch (err) {
      handleError(err);
    }
  }, [createRoomParams, setCreateRoomReturnProps, handleError]);

  useEffect(() => {
    createRoomBeforeConfirm();
  }, [createRoomBeforeConfirm]);

  const handleStartTherapy = async () => {
    if (newRoomID) {
      return ReactFrameService.instance().closePopup({
        navigateTo: 'room',
        metadata: {
          roomID: newRoomID,
        },
      });
    }
    return undefined;
  };

  if (!newRoomID && !isError) {
    return <Spinner />;
  }

  if (isError) {
    return (
      <ErrorResultWrapper>
        <RedX size={74} />
        <Text
          style={{
            fontWeight: 800,
            fontSize: 29,
            marginTop: 25,
            textAlign: 'center',
          }}
        >
          Something Went Wrong
        </Text>
        {error === RegistrationErrors.duplicateInsuranceUser ? (
          <Text
            dataQa="insuranceErrorInfo"
            style={{
              fontSize: 16,
              color: colors.permaRiverBed,
              marginTop: 13,
              marginBottom: 42,
              width: 300,
            }}
          >
            You can’t add this EAP plan. <br />
            Contact our customer support team if you need assistance <br />
            <Link
              dataQa="customerSupportLink"
              stopPropagation
              target="_blank"
              href="https://help.talkspace.com/hc/en-us/requests/new"
              style={{ marginLeft: 0, marginRight: 0 }}
              primaryColor={colors.green}
              roundedFocusStyle
            >
              <Standard variant="standardBoldGreen" inline>
                Submit a request - Talkspace Help Center{' '}
              </Standard>
            </Link>
          </Text>
        ) : (
          <Text
            style={{
              fontSize: 16,
              color: colors.permaRiverBed,
              marginTop: 13,
              marginBottom: 42,
              width: 200,
              textAlign: 'center',
            }}
          >
            Please check your internet connection and try again.
          </Text>
        )}
        <ErrorCloseButton onPress={() => ReactFrameService.instance().closePopup()}>
          <Large variant="largeBoldWideGreen">Close</Large>
        </ErrorCloseButton>
      </ErrorResultWrapper>
    );
  }
  return (
    <TherapistSelectedConfirmation
      buttonHandler={handleStartTherapy}
      therapistImageURL={`${REACT_APP_CDN_ENDPOINT}images/application/therapist/440/${therapistIDFromCreateRoom}.png`}
      previousTherapistImageURL={`${REACT_APP_CDN_ENDPOINT}images/application/therapist/440/${previousTherapistId}.png`}
      defaultImageURL={`${REACT_APP_CDN_ENDPOINT}images/application/therapist/440/1.png`}
      buttonText="Start Treatment"
      title="It's time to start treatment."
      subtitleFirstLine={
        therapistIDFromCreateRoom === previousTherapistId
          ? `${therapistFirstName} is ready to help you on your journey towards feeling better.`
          : ''
      }
      subtitleSecondLine="If you had been previously matched with a therapist, that room and subscription will remain active alongside your new room unless you cancel it."
      hasRemainingSessions={hasRemainingSessions}
      providerAvailable={therapistIDFromCreateRoom === previousTherapistId}
      newRoomID={newRoomID}
      oldRoomID={oldRoomID}
      isNewProvider={therapistIDFromCreateRoom !== previousTherapistId}
      isUpdateCoverageFlow={isUpdateCoverageFlow}
      isMBHIneligibilityFlow={isMBHIneligibilityFlow}
      sessionsCanceled={sessionsCanceled}
      sessionsTransferred={sessionsTransferred}
    />
  );
};

export default EligibilityStartTherapyContainer;
