import { SimpleQuestions, TextDS, useEmotionTheme, View } from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import { useCallback } from 'react';
import { useHistory } from '@/core/routerLib';
import { FLOW_147_TEENSPACE_OPTIMIZED, FLOW_167_CONSENTER_TEEN_REFERRAL } from '../../../Flows';
import switchFlowHelper from '../../../Helpers/switchFlowHelper';
import TeenConsenterBanner from '../../TeenBanner';

interface Props {
  updateAndSave: (state: Partial<RecoveredSessionState>) => void;
}

type Option = {
  value: 'teen' | 'parentOrGuardian';
  label: string;
};
const ConsenterTeenBranching = ({ updateAndSave }: Props) => {
  const { t: tQuickmatchFlow90 } = useTranslation('quickmatch.flow90');
  const { spacing } = useEmotionTheme();
  const history = useHistory();

  const options: Option[] = [
    {
      value: 'teen',
      label: tQuickmatchFlow90('step3.labelTeen', 'I’m a teen signing up myself', undefined),
    },
    {
      value: 'parentOrGuardian',
      label: tQuickmatchFlow90(
        'step3.labelParentOrGuardian',
        'I’m a parent or guardian signing up my teen',
        undefined
      ),
    },
  ];
  const onPress = useCallback(
    ({ value }: Option) => {
      switch (value) {
        case 'teen':
          switchFlowHelper(FLOW_147_TEENSPACE_OPTIMIZED, history, updateAndSave, {
            stepId: 1,
          });
          break;
        case 'parentOrGuardian':
          switchFlowHelper(FLOW_167_CONSENTER_TEEN_REFERRAL, history, updateAndSave, {
            stepId: 1,
          });
          break;
        default:
          break;
      }
    },
    [history, updateAndSave]
  );
  return (
    <View>
      <TeenConsenterBanner type="teenspaceInfo" />
      <TextDS
        variant="heading2xl"
        style={{ margin: `${spacing('space400')}px 0 ${spacing('space300')}px` }}
      >
        {tQuickmatchFlow90('step3.question', "Who's signing up for therapy today?", undefined)}
      </TextDS>
      <SimpleQuestions questions={options} onPress={onPress} />
    </View>
  );
};

export default ConsenterTeenBranching;
