/* eslint-disable import/prefer-default-export */
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useEffect, useState } from 'react';
import {
  FLOW_132_CONSUMER_THERAPY,
  FLOW_200_CONSUMER_THERAPY_EXPERIMENT_HUB,
} from '../../../Flows';
import { UseVWOTogglerOptions } from '../types';

const toggleBanner = ({
  show,
  banner,
  widgetID,
}: {
  show: boolean;
  banner: HTMLElement | null;
  widgetID: string | null;
}) => {
  if (banner) {
    // Remove hidden if show is true
    const method = show ? 'remove' : 'add';
    banner.classList[method]?.('hidden');
  }
  if (widgetID) {
    // Add margin if show is true
    const method = show ? 'add' : 'remove';
    document.body.classList[method](`vwo-notification-bar-margin-${widgetID}`);
  }
};

export const useVWOToggler = ({
  isCouldNotBeVerified,
  hideVWOBar,
  b2bForkResult,
  service,
  isLoggedInUser,
  isB2BOutOfNetwork,
  flowId,
  stepId,
  teenEligibility,
}: UseVWOTogglerOptions) => {
  const { oonDiscount } = useFlags();
  const oonDiscountCoupon =
    oonDiscount?.experimentActive && oonDiscount.variant !== 'control' && oonDiscount.coupon
      ? oonDiscount.coupon
      : 'INS';
  const [isVWOBannerVisible, setIsVWOBannerVisible] = useState(false);

  useEffect(() => {
    // Use styles at body level to prevent banners from showing then disappearing with individual level logic below; also remove white space left by hidden banners
    const shouldHideAllBanners =
      !isCouldNotBeVerified &&
      (hideVWOBar ||
        (b2bForkResult === 'b2b' && !isB2BOutOfNetwork) ||
        service === 'psychiatry' ||
        isLoggedInUser);

    if (shouldHideAllBanners) {
      document.body.classList.add('no-vwo');
    } else {
      document.body.classList.remove('no-vwo');
    }

    const shouldHideStandardBanner = (() => {
      // standard banner for out-of-pocket, not for out-of-network
      if (isB2BOutOfNetwork) {
        return true;
      }
      if (
        !isCouldNotBeVerified &&
        (hideVWOBar ||
          b2bForkResult === 'b2b' ||
          service === 'psychiatry' ||
          teenEligibility ||
          isLoggedInUser)
      ) {
        return true;
      }
      return false;
    })();

    // There can be more than one banner at a time
    const VWOBarContainers = document.querySelectorAll<HTMLDivElement>('[id^="vwo-widget-"]');
    if (!VWOBarContainers?.length) {
      return;
    }
    for (const container of VWOBarContainers) {
      // make sure child element is correct by selecting with class name
      const VWOBar = container.querySelector<HTMLDivElement>('.vwo-notification-bar');
      const widgetID = container.getAttribute('vwo-widget-id');

      // OON Banner has different hiding conditions
      if (VWOBar?.innerText.includes(oonDiscountCoupon)) {
        if (
          (flowId === FLOW_132_CONSUMER_THERAPY ||
            flowId === FLOW_200_CONSUMER_THERAPY_EXPERIMENT_HUB) &&
          isB2BOutOfNetwork
        ) {
          // Show banner for Flow 132 & 200, OON
          toggleBanner({ show: true, banner: VWOBar, widgetID });
        } else {
          toggleBanner({ show: false, banner: VWOBar, widgetID });
        }
      } else if (shouldHideStandardBanner) {
        toggleBanner({ show: false, banner: VWOBar, widgetID });
      } else {
        toggleBanner({ show: true, banner: VWOBar, widgetID });
      }
    }

    setIsVWOBannerVisible(!shouldHideAllBanners && !shouldHideStandardBanner);
  }, [
    flowId,
    stepId, // recheck on stepId change to account for potential VWO delay
    hideVWOBar,
    b2bForkResult,
    service,
    isLoggedInUser,
    isCouldNotBeVerified,
    isB2BOutOfNetwork,
    oonDiscountCoupon,
    teenEligibility,
  ]);

  return isVWOBannerVisible;
};
