import { useContext, useEffect, createContext, useCallback, useRef } from 'react';
import { Spinner, View, useObjectState } from '@talkspace/react-toolkit';
import { useHistory } from 'react-router';
import { saveSession } from '../Helpers/apiService';
import apiWrapper from '../core/api/apiWrapper';
import ReactFrameService from '../utils/reactFrame/ReactFrameService';
import sessionStorage from '../core/storage/sessionStorage';
import {
  recoveredStateFromHomePageState,
  RecoveredStateFromHomePageState,
} from '../Components/HomePage/types';
import { getClientFlow } from '@/Flows';

const homepageStateKeys = recoveredStateFromHomePageState.reduce((curr, key) => {
  return { ...curr, [key]: undefined };
}, {} as RecoveredStateFromHomePageState);

const recoveredSessionInitialState: RecoveredSessionState = {
  stepAnswers: [],
  recoveredFields: {
    dateOfBirth: undefined,
    freeText: undefined,
    basicInformation: undefined,
    memberDetails: undefined,
    insuranceDetails: undefined,
    registrationInformation: undefined,
  },
  preRegisterUserDataID: undefined,
  search: '',
  flowId: null,
  stepId: null,
  flowVersion: 0,
  existingCoupon: null,
  recoveredSession: false,
  dispatcherState: null,
  isReady: false,
  experimentsVariants: undefined,
  ...homepageStateKeys,
};

type RecoveredField = keyof RecoveredFields;

export const RecoveredSessionStateContext = createContext<RecoveredSessionState | undefined>(
  undefined
);
RecoveredSessionStateContext.displayName = 'RecoveredSessionStateContext';

interface RecoveredSessionActions {
  setSessionState: (state: Partial<RecoveredSessionState>) => void;
  updateAndSave: (state: Partial<RecoveredSessionState>) => void;
  setRecoveredField: <T extends RecoveredField>(field: T, data: RecoveredFields[T]) => void;
}

export const RecoveredSessionActionsContext = createContext<RecoveredSessionActions | undefined>(
  undefined
);
RecoveredSessionActionsContext.displayName = 'RecoveredSessionActionsContext';

const getSessionStorageItems = (): RecoveredFields => {
  const obj: RecoveredFields = {};
  obj.basicInformation = sessionStorage.getItem('TSQM_BasicInformation');
  obj.dateOfBirth = sessionStorage.getItem('TSQM_DateOfBirth');
  obj.insuranceDetails = sessionStorage.getItem('TSQM_InsuranceDetails');
  obj.memberDetails = sessionStorage.getItem('TSQM_MemberDetails');
  obj.freeText = sessionStorage.getItem('TSQM_FreeText');
  obj.registrationInformation = sessionStorage.getItem('TSQM_RegistrationInformation');
  return obj;
};

function RecoveredSessionProvider({ children }) {
  const isRecoverSessionPath = window.location.pathname === '/recover-session';
  const [state, setSessionState] = useObjectState<RecoveredSessionState>(
    recoveredSessionInitialState,
    'recoveredSessionState'
  );

  const history = useHistory();
  const queuedSaveSession = useRef(false);
  const stateRef = useRef(state);
  // Don't wrap in a hook, or else we will have a potentially outdated version on functions that use this ref
  // See https://stackoverflow.com/a/55028488/7551248
  stateRef.current = state;

  useEffect(() => {
    if (queuedSaveSession.current) {
      saveSession({
        qmState: state,
        flowId: state.flowId,
        stepId: state.stepId,
        flowVersion: state.flowVersion,
      });
      queuedSaveSession.current = false;
    }
  }, [state]);

  const setRecoveredField = useCallback(
    <T extends RecoveredField>(field: T, value: RecoveredFields[T]) => {
      // Prevent unnecessary API calls
      if (!stateRef.current.isReady || stateRef.current.recoveredFields[field] === value) return;
      const newState = {
        recoveredFields: {
          ...stateRef.current.recoveredFields,
          [field]: value,
        },
      };
      setSessionState(newState);
      queuedSaveSession.current = true;
    },
    [setSessionState]
  );

  const updateAndSave = useCallback(
    (update: Partial<RecoveredSessionState>) => {
      const newRecoveredFields = getSessionStorageItems();
      const newState = { ...update };
      newState.recoveredFields = { ...stateRef.current.recoveredFields, ...newRecoveredFields };
      setSessionState(newState);
      queuedSaveSession.current = true;
    },
    [setSessionState]
  );

  const recoverSessionStorageFields = useCallback(() => {
    if (state.recoveredFields.basicInformation)
      sessionStorage.setItem('TSQM_BasicInformation', state.recoveredFields.basicInformation);
    if (state.recoveredFields.dateOfBirth)
      sessionStorage.setItem('TSQM_DateOfBirth', state.recoveredFields.dateOfBirth);
    if (state.recoveredFields.insuranceDetails)
      sessionStorage.setItem('TSQM_InsuranceDetails', state.recoveredFields.insuranceDetails);
    if (state.recoveredFields.memberDetails)
      sessionStorage.setItem('TSQM_MemberDetails', state.recoveredFields.memberDetails);
    if (state.recoveredFields.freeText)
      sessionStorage.setItem('TSQM_FreeText', state.recoveredFields.freeText);
    if (state.recoveredFields.registrationInformation)
      sessionStorage.setItem(
        'TSQM_RegistrationInformation',
        state.recoveredFields.registrationInformation
      );
  }, [
    state.recoveredFields.basicInformation,
    state.recoveredFields.dateOfBirth,
    state.recoveredFields.freeText,
    state.recoveredFields.insuranceDetails,
    state.recoveredFields.memberDetails,
    state.recoveredFields.registrationInformation,
  ]);

  useEffect(() => {
    recoverSessionStorageFields();
  }, [recoverSessionStorageFields]);

  useEffect(() => {
    apiWrapper
      .initialize()
      .then(({ session, redirectToExternal, redirectToInternal }) => {
        if (redirectToInternal) {
          history.push(redirectToInternal);
        } else if (redirectToExternal) {
          window.location.href = redirectToExternal;
        }
        if (session) {
          const { qmFlowVersion: recoveredFlowVersion, qmFlowID, dispatcherResponse } = session;

          const flow = qmFlowID && getClientFlow(qmFlowID, false);
          // check for override pattern URL param like ?flowId=x&override=true
          // in this case if the recovered session flowID is equal to the override one a session will be recovered.
          const urlSearchParams = new URLSearchParams(window.location.search);
          const flowId = urlSearchParams.get('flowId');
          const isOverrideRecoverSearchParams =
            flowId && urlSearchParams.get('override') === 'true';
          const shouldNotRecoverFlow =
            !ReactFrameService.instance().isInFrame() ||
            (isOverrideRecoverSearchParams && session.qmFlowID !== Number(flowId));

          const { experimentsVariants = {} } = dispatcherResponse?.data || {};

          // we recover a session if found and the saved version equals to the current flow version and there is no override pattern
          const isFlowRecover =
            session.qmState &&
            flow &&
            flow.flowConfig.version === recoveredFlowVersion &&
            (isRecoverSessionPath || !shouldNotRecoverFlow);

          // we recover a session if its not a flow session recover (like from eligibility widget)
          const isQMSessionRecover =
            session.qmState && !flow && !(qmFlowID && getClientFlow(qmFlowID)?.flowConfig.disabled);

          if (isFlowRecover || isQMSessionRecover) {
            setSessionState({
              ...session.qmState,
              recoveredSession: true,
              isReady: true,
            });
          } else {
            setSessionState({
              experimentsVariants,
              recoveredSession: false,
              isReady: true,
            });
          }
        } else {
          setSessionState({
            recoveredSession: false,
            isReady: true,
          });
        }
      })
      .catch((err) => {
        // TODO add a reload error screen
        setSessionState({
          recoveredSession: false,
          isReady: true,
        });
      });
    // This is only needed in the initial mount of this component
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actions = {
    updateAndSave,
    setSessionState,
    setRecoveredField,
  };

  if (!state.isReady) {
    return (
      <View style={{ height: '100vh' }}>
        <View flex={1}>
          <Spinner isLoading />
        </View>
      </View>
    );
  }

  return (
    <RecoveredSessionStateContext.Provider value={state}>
      <RecoveredSessionActionsContext.Provider value={actions}>
        {children}
      </RecoveredSessionActionsContext.Provider>
    </RecoveredSessionStateContext.Provider>
  );
}

export const withRecoveredSession = (Component) => (props) =>
  (
    <RecoveredSessionProvider>
      <Component {...props} />
    </RecoveredSessionProvider>
  );

export const useRecoveredSessionState = (): RecoveredSessionState => {
  const context = useContext(RecoveredSessionStateContext);
  if (context === undefined) {
    throw new Error('RecoveredSessionState must be used within a ContextProvider');
  }
  return context;
};

export const useRecoveredSessionActions = (): RecoveredSessionActions => {
  const context = useContext(RecoveredSessionActionsContext);
  if (context === undefined) {
    throw new Error('RecoveredSessionActions must be used within a ContextProvider');
  }
  return context;
};

export default RecoveredSessionProvider;
