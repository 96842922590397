import { QMFlow } from './types';
import { initFlowConfig } from './utils';

// This flow is used by the parent or guardian to give consent upfront and refer their teen to the app
export const FLOW_167_CONSENTER_TEEN_REFERRAL = 167;

const flow: QMFlow = {
  flowId: FLOW_167_CONSENTER_TEEN_REFERRAL,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
    isTeen: false,
    isConsenter: true,
  },
  steps: [
    {
      id: 1,
      category: 'teenZipcodeEligibility',
      buttonTarget: 2,
      analyticsId: 54,
      response_category_id: 9,
    },
    {
      id: 2,
      category: 'teensEligibleFreeTherapy',
      isInterstitial: true,
      buttonTarget: 3,
      noFooter: true,
    },
    {
      id: 3,
      category: 'teenConsenterReferralGetStarted',
      isInterstitial: true,
      noFooter: true,
      buttonTarget: 4,
    },
    {
      id: 4,
      category: 'teenConsenterReferralForm',
      buttonTarget: 5,
    },
    {
      id: 5,
      category: 'teenConsenterReferralSuccess',
    },
    {
      id: 192,
      category: 'ageLocationIneligibleConsenter',
      noFooter: true,
    },
  ],
};

export default flow;
