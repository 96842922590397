import { useTranslation } from '@talkspace/i18n';
import { useWatch } from 'react-hook-form';
import { RHFInput, RHFSelect } from '@talkspace/react-toolkit';
import { useEffect } from 'react';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { BHEligibilityFormFieldName } from 'ts-analytics/types';
import { useContinue, useFormSaver } from '../QuickEligibility/hooks';
import { useEligibilityService, EligibilityServiceFormData } from '@/hooks/useEligibilityService';
import StatusModal from '../QuickEligibility/StatusModal';
import { QuickEligibilityProps } from '../QuickEligibility/types';
import EligibilityForm, {
  AA_TOOLTIP_STYLE,
  AdditionalFieldsParams,
  Styled,
} from '../QuickEligibility/EligibilityForm';
import { EMPLOYEE_OPTIONS_BH_QUICK_ELIGIBILITY } from '../../OneFormEligibility/util';
import { OneFormEligibilityFieldNames } from '../../OneFormEligibility/types';
import { useInsurancePayerOptions } from '../../B2BFork/hooks';
import { useHomePageState } from '../../HomePage';
import countriesHelper from '../../../Helpers/countriesHelper';
import useMemberIDGuidance from '../../../hooks/useMemberIDGuidance';
import {
  trackBHEligibilityCTAClick,
  trackBHEligibilityScreenSeen,
} from '../../../utils/analytics/events';

const MAX_ATTEMPTS = 5;

const EditInsuranceInfo = ({
  insurancePayer,
  quickEligibilityInfo,
  updateStep,
  step,
  setReferralSource,
  referralSource,
  partnerFlowID,
  service,
  isEditInsuranceInfoScreen,
  showEmployeeRelation,
}: QuickEligibilityProps) => {
  useFormSaver();
  const { updateCoveragePart2 } = useFlags();

  const { t: tQuickmatchFlow132 } = useTranslation('quickmatch.flow132');

  const { result, done, error, errorType, canRetry, attempts, isSubmitting, submit } =
    useEligibilityService({
      maxAttempts: MAX_ATTEMPTS,
      previousAttempts: quickEligibilityInfo?.eligibilityCheckAttempts,
    });

  const hasTried =
    attempts - (quickEligibilityInfo?.eligibilityCheckAttempts || 0) > 0 ||
    attempts === MAX_ATTEMPTS;
  const shouldRetry = hasTried && !isSubmitting && canRetry;
  const statusModalIsVisible = (isSubmitting || (hasTried && canRetry)) && !done;

  const data = useWatch() as any as EligibilityServiceFormData;

  const memberIDGuidance = useMemberIDGuidance(insurancePayer?.partnerID || '');

  useEffect(() => {
    trackBHEligibilityScreenSeen('Edit Insurance Info Seen', 'edit-insurance-info');
  }, []);

  useContinue({
    result,
    data,
    done,
    errorType,
    updateStep,
    step,
    attempts,
    insurancePayer,
    error,
    service,
  });

  const { isHideNonBhItems } = useHomePageState();

  const { options: insurancePayerOptions } = useInsurancePayerOptions({
    service,
    shouldHideOutOfPocket: isHideNonBhItems,
    shouldHideOutOfNetwork: isHideNonBhItems,
  });

  const handleOnSubmit = (formData: EligibilityServiceFormData) => {
    const parsedFormData = JSON.parse(JSON.stringify(formData));

    const { state: clientState, country } = countriesHelper.getStateAndCountryOverrides(
      parsedFormData.country,
      parsedFormData.state
    );

    parsedFormData.country = country;
    parsedFormData.state = clientState;

    const updatedFields = Object.keys(data).filter((key) => {
      const isDifferentInsurance =
        key === 'insurance' &&
        data.insurance?.label !== quickEligibilityInfo?.insurancePayer?.label;
      const isDifferentFieldValue =
        key !== 'gender' && key !== 'insurance' && data[key] !== quickEligibilityInfo?.[key];
      return isDifferentInsurance || isDifferentFieldValue;
    });

    trackBHEligibilityCTAClick('Continue', 'edit-insurance-info', {
      updatedFields: updatedFields as BHEligibilityFormFieldName[],
    });

    return submit(parsedFormData);
  };

  const insuranceFieldTop = (props: AdditionalFieldsParams) => {
    const { setValue } = props;
    return (
      <RHFSelect
        fieldName="insurance"
        label={tQuickmatchFlow132('step101.inputLabelInsurance', 'Insurance', undefined)}
        options={insurancePayerOptions}
        allowMobileSearch
        registerOptionValue
        onChange={(newSelectedPayerValue) => {
          const newSelectedPayer = insurancePayerOptions?.find(
            (option) => option.value === newSelectedPayerValue
          );
          return newSelectedPayer && setValue('insurance', newSelectedPayer);
        }}
        placeholder={tQuickmatchFlow132(
          'step101.inputPlaceholderLocation',
          'Select your location',
          undefined
        )}
        labelStyle={{ paddingBottom: 4 }}
        labelContainerStyle={{ alignItems: 'flex-end', marginBottom: 0 }}
      />
    );
  };

  const additionalFields = (props: AdditionalFieldsParams) => {
    const { setValue, isManualSignUpFlow } = props;
    const employeeRelationQuestion = showEmployeeRelation
      ? [
          <RHFSelect
            fieldName={OneFormEligibilityFieldNames.employeeRelation}
            label={
              updateCoveragePart2.variant === 'treatment'
                ? 'Relationship to insurance holder'
                : 'Primary insurance holder'
            }
            options={EMPLOYEE_OPTIONS_BH_QUICK_ELIGIBILITY}
            tooltip="Are you the primary person insured, or are you their spouse/partner or child/dependent?"
            tooltipLabel="Employee relation tooltip"
          />,
        ]
      : [];
    return [
      <RHFInput
        fieldName="memberID"
        label={memberIDGuidance.label}
        tooltip={memberIDGuidance.tooltip}
        placeholder={memberIDGuidance.hint}
        hint={memberIDGuidance.description}
        isRequired
        data-qa="member-id-input"
        onChange={(e) => {
          const newValue = e.target.value.replace(/\s/gi, '').toLowerCase();
          if (newValue !== e.target.value) {
            setValue('memberID', newValue);
          }
        }}
        labelStyle={{ alignSelf: 'flex-end' }}
        tooltipStyle={AA_TOOLTIP_STYLE}
        isDisabled={isManualSignUpFlow && 'memberID' in data && !!data.memberID}
      />,
      ...employeeRelationQuestion,
    ];
  };

  const eligibilityForm = EligibilityForm({
    done,
    handleOnSubmit,
    isSubmitting,
    data,
    referralSource,
    setReferralSource,
    additionalFields,
    insuranceFieldTop,
    isEditInsuranceInfoScreen,
    partnerFlowID,
  });

  if (result) {
    return null;
  }

  return (
    <Styled.View>
      <StatusModal
        visible={statusModalIsVisible}
        isCheckingCoverage={isSubmitting}
        showRetryButton={shouldRetry}
        onHandleRetry={() => submit(data)}
      />
      {eligibilityForm}
    </Styled.View>
  );
};

export default EditInsuranceInfo;
