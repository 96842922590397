import { useQuery } from 'react-query';
import { OUT_OF_POCKET_OPTION } from 'ts-frontend/helpers';
import { InsuranceEligibilityPayer, PayerOptionType, ServiceType } from 'ts-frontend/types';
import { getInsurancePayers } from '../Helpers/apiService';
import { insurancePayersQueryKey } from './queryKeys';

const payersToRemove: Pick<InsuranceEligibilityPayer, 'id' | 'label'>[] = [
  { id: 500, label: 'Health Management Administrators - HMA' },
  { id: 536, label: 'HMA Inc. (AZ / NV / HI)' },
  { id: 647, label: 'Medica' },
];

const fetchInsurancePayers =
  (service: ServiceType | null) => async (): Promise<PayerOptionType[]> => {
    let insurancePayers = await getInsurancePayers();

    // skip payers in the list
    insurancePayers = insurancePayers.filter(
      ({ id: payerID }) => !payersToRemove.find((el) => el.id === payerID)
    );

    if (service === 'psychiatry') {
      // NYC-8326: Choosing psych as a service - should not allow out of network flow
      insurancePayers = insurancePayers.filter(({ isOutOfNetwork, id }) => id && !isOutOfNetwork);
    }

    return [
      OUT_OF_POCKET_OPTION,
      ...insurancePayers.sort((a, b) => {
        if (!!a && !a.isOutOfNetwork && !!b && !b.isOutOfNetwork)
          return a.label.localeCompare(b.label);
        return Number(!!b && !b.isOutOfNetwork) - Number(!!a && !a.isOutOfNetwork);
      }),
    ];
  };

const useQueryInsurancePayers = (service: ServiceType | null) =>
  useQuery<PayerOptionType[], Error>({
    queryKey: insurancePayersQueryKey(service),
    queryFn: fetchInsurancePayers(service),
    cacheTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    retry: false,
  });

export default useQueryInsurancePayers;
