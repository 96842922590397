import { useState } from 'react';

import { useTranslation } from '@talkspace/i18n';
import {
  Button,
  EmotionThemeProvider,
  Input,
  TextDS,
  View,
  styled,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import {
  ArrowUpRightFromSquare,
  Check,
  Link as LinkIcon,
} from '@talkspace/react-toolkit/src/designSystems/icons';
import { TeenConsenterReferralSuccessStep } from '../../../Flows';
import TeenConsenterBanner from '../../TeenBanner';
import TeenConsenterFAQs from '../../TeenConsenterFAQs';
import { useHomePageState } from '../../HomePage';
import { trackEvent } from '../../../utils/analytics/eventTracker';

const Styled = {
  Container: styled(View)({ alignItems: 'center' }),
  Title: styled(TextDS)(({ theme: { spacing } }) => {
    return {
      marginTop: spacing('space400'),
    };
  }),
  Description: styled(TextDS)(({ theme: { spacing } }) => {
    return {
      marginTop: spacing('space150'),
    };
  }),
  ReferralLinkContainer: styled(View)(({ theme: { spacing } }) => {
    return {
      position: 'relative',
      width: 345,
      margin: `${spacing('space400')}px 0 ${spacing('space500')}px`,
    };
  }),
  ReferralLinkInput: styled(Input)(({ theme: { colorRoles, spacing } }) => {
    return {
      width: '100%',
      borderRadius: spacing('space100'),
      border: `1px solid ${colorRoles.borders.borderDefault}`,
      padding: `${spacing('space200')}px ${spacing('space150')}px`,
      paddingLeft: 34,
      paddingRight: 115,
    };
  }),
  LinkIcon: styled(View)(() => {
    return {
      position: 'absolute',
      left: 10,
      top: 15,
    };
  }),
  CopyButton: styled(Button)(({ theme: { spacing } }) => {
    return {
      position: 'absolute',
      right: 10,
      top: 10,
      padding: `${spacing('space075')}px ${spacing('space200')}px`,
    };
  }),
};

interface Props {
  step: TeenConsenterReferralSuccessStep;
}
const TeenConsenterReferralSuccess = ({ step }: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  const { spacing } = useEmotionTheme();

  const { teenConsenterReferralUrl } = useHomePageState();

  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');
  const handleCopy = () => {
    if (teenConsenterReferralUrl) {
      navigator.clipboard.writeText(teenConsenterReferralUrl).then(() => {
        setIsCopied(true);
        trackEvent('Teen Consenter Referral Link Copied', {});
      });
    }
  };

  const showShareButton = !!navigator.share; // If browser supports the navigator share API

  const handleShare = () => {
    const textToShare = tQuickmatchCommon(
      'teenConsenterReferralSuccess.shareText',
      `I’ve referred you for free therapy with Talkspace! Get started here. ${teenConsenterReferralUrl}`,
      { referral_link: teenConsenterReferralUrl }
    );
    if (showShareButton) {
      trackEvent('Teen Consenter Referral Share Clicked', {});
      navigator.share({
        text: textToShare,
      });
    }
  };
  return (
    <EmotionThemeProvider version="2.0.0">
      <Styled.Container>
        <TeenConsenterBanner type="eligibleConsenter" />
        <Styled.Title variant="heading2xl">
          {tQuickmatchCommon(
            'teenConsenterReferralSuccess.title',
            "Great! Let's invite your teen to get started.",
            undefined
          )}
        </Styled.Title>
        <Styled.Description>
          {tQuickmatchCommon(
            'teenConsenterReferralSuccess.description',
            'Send your teen the link, then plan some time to sit down with them and help them sign up',
            undefined
          )}
        </Styled.Description>
        <Styled.ReferralLinkContainer>
          <Styled.ReferralLinkInput
            dataQa="referralLinkInput"
            value={teenConsenterReferralUrl}
            readOnly
          />
          <Styled.LinkIcon>
            <LinkIcon />
          </Styled.LinkIcon>
          <Styled.CopyButton
            dataQa="copyReferralLinkButton"
            text={
              isCopied
                ? tQuickmatchCommon(
                    'teenConsenterReferralSuccess.copyButtonText',
                    'Copied',
                    undefined
                  )
                : tQuickmatchCommon(
                    'teenConsenterReferralSuccess.copyButtonTextCopied',
                    'Copy',
                    undefined
                  )
            }
            sizeDS="slim"
            onPress={handleCopy}
            stretch={false}
            Icon={isCopied ? () => <Check colorType="inverse" /> : undefined}
            style={{ width: 102 }}
          />
          {showShareButton && (
            <Button
              stretch
              Icon={() => <ArrowUpRightFromSquare colorType="inverse" />}
              text={tQuickmatchCommon(
                'teenConsenterReferralSuccess.shareButtonText',
                'Share with your teen',
                undefined
              )}
              onPress={handleShare}
              style={{ marginTop: spacing('space400') }}
            />
          )}
        </Styled.ReferralLinkContainer>
        <TeenConsenterFAQs />
      </Styled.Container>
    </EmotionThemeProvider>
  );
};

export default TeenConsenterReferralSuccess;
