import React, { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import capitalize from 'lodash/capitalize';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  A11yFullAddress,
  Button,
  Large,
  Tiny,
  TextDS,
  useEmotionTheme,
  View,
  Spinner,
  Link,
  RHFSelect,
  RHFInput,
  RHFPhoneInput,
  RHFDateInput,
  RHFCheckbox,
  useScrollToFirstInvalidField,
  selectLoadOptions,
} from '@talkspace/react-toolkit';
import { FieldError, FormProvider, useForm } from 'react-hook-form';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useHistory, useLocation } from 'react-router';
import moment from 'moment';
import { getReferralOptions } from '@talkspace/react-toolkit/src/constants/referralOptions';
import getCombinedReferralOptions from '@talkspace/react-toolkit/src/getCombinedReferralOptions';
import {
  SESSION_STORAGE_MEMBER_DETAILS_KEY,
  getSessionStorageValuesFromJson,
} from 'ts-frontend/utils';
import { CommonServiceType, PayerOptionType, ServiceType } from 'ts-frontend/types';
import { getGDPRProps, storeGDPRProps, isGDPRCountry } from 'ts-frontend/helpers/gdpr';
import GDPRConsentCheckbox from '@talkspace/react-toolkit/src/components/GDPRConsentCheckbox';
import { useDynamicIntegrationsConfig } from 'ts-frontend/hooks/useDynamicIntegrationsConfig';
import { isOneFormEligibilityStep } from '@/Helpers/stepTypeHelper';
import styled from '../../core/styled';
import Title from '@/Components/Title';
import Description from '@/Components/Description';
import {
  EligibilityType,
  FlowConfig,
  OneFormConfigSetType,
  OneFormEligibilityStep,
} from '../../Flows/types';
import { states } from '../../Helpers/locales';
import { isTeensFlow } from '../../Helpers/flowsHelper';
import AlertBox from '../AlertBox/AlertBox';
import SkipOption from '../SkipOption/SkipOption';
import AuthorizationCode from './OneFormEligibilityAuthorizationCode';
import OneFormEligibilityError from './OneFormEligibilityError';
import OneFormEligibilityNoCoverage from './OneFormEligibilityNoCoverage';
import OneFormEligibilityRetryModal from './OneFormEligibilityRetryModal';
import OneFormEligibilityUploadInsuranceCardImages from './OneFormEligibilityUploadInsuranceCardImages';
import OneFormEligibilityUploadIDCardImages from './OneFormEligibilityUploadIDCardImages';
import OneFormEligibilitySubmitManualRequest from './OneFormEligibilitySubmitManualRequest';
import OneFormEligibilitySubmitManualRequestSuccess from './OneFormEligibilitySubmitManualRequestSuccess';
import {
  submitOneFormData,
  useDispatcherDefaultEligibilityParams,
  getReferralSourceDefaultValue,
} from './OneFormEligibilityHelpers';
import FullName from './OneFormEligibilityFullName';
import PsychiatryRxMessage from './PsychiatryRxMessage';
import { DEFAULT_FLOWS, getStepFromFlowID } from '../../Flows';
import {
  OneFormEligibilityFieldNames,
  OneFormEligibilityFields,
  OneFormEligibilityYupContext,
  OneFormEligibilityProps,
} from './types';
import { b2bOutOfNetworkEligibilityConfig, b2bOutOfNetworkFlowConfig } from '../../Flows/utils';
import FlowRedirectionModal from '../FlowRedirectionModal/FlowRedirectionModal';
import useManualCoverageVerification, {
  ManualCoverageRequestStep,
} from '../../hooks/useManualCoverageVerification';
import {
  useCollectDateOfBirth,
  useCollectInsuranceDetails,
  useCollectFullName,
  useDescription,
  useHeading,
  useSessionStorage,
} from './hooks';
import ReactFrameService from '@/utils/reactFrame/ReactFrameService';
import {
  SERVICE_OPTIONS,
  EMPLOYEE_OPTIONS,
  PSYCH_TOO_YOUNG_ERROR,
  MAX_RETRIES,
  countriesDropdownOptions,
  setSessionMemberMemberDetails,
  responsiveSchema,
  getFormValuesFromSessionStorage,
  setSessionBasicInformation,
} from './util';
import oneFormConfigSets from './oneFormConfigSets';
import OneFormNYCTeen from '../assets/OneFormNYCTeen';
import { useHandleServiceSelection } from '../Steps/ServiceSelection/hooks';
import { ageErrorCopy, isUnder18 } from '../../Helpers/ageHelper';
import switchFlowHelper from '../../Helpers/switchFlowHelper';
import useQueryPublicSchoolList from '../../hooks/useQueryPublicSchoolList';
import { useMoveCoverageEnabled } from '../../hooks';
import { useHomePageActions } from '@/Components/HomePage';
import countriesHelper from '@/Helpers/countriesHelper';
import { useInsurancePayerOptions } from '../B2BFork/hooks';
import DateOfBirthUnderageAlert from '../DateOfBirthUnderageAlert';
import useMemberIDGuidance from '@/hooks/useMemberIDGuidance';

const reactFrameService = ReactFrameService.instance();

const StyledContainer = styled(View)({
  height: '100%',
  width: '100%',
  margin: 'auto',
  paddingTop: 38,
});

const EmployeeInfoStyle = styled(View)({
  textAlign: 'left',
  marginTop: 10,
  marginBottom: 22,
});

const StyledForm = styled.form({
  width: 335,
  outline: 'none',
  display: 'flex',
  flexDirection: 'column',
  margin: '30px 10px 0px 10px',
  alignSelf: 'center',
});

const StyledAlertBox = styled(AlertBox)({
  width: '100%',
  maxWidth: 335,
  alignSelf: 'center',
});

const EmailMarketingLabel = () => {
  const { colors } = useEmotionTheme();
  return (
    <>
      I agree to receive email marketing messages from Talkspace. I may opt out at any time. View
      <Link
        dataQa="termsOfUseLink"
        target="_blank"
        href="https://www.talkspace.com/public/terms"
        style={{ textDecoration: 'underline', fontWeight: 700 }}
        roundedFocusStyle
        primaryColor={colors.green}
      >
        Terms of Use
      </Link>
      and
      <Link
        dataQa="privacyPolicyLink"
        target="_blank"
        href="https://www.talkspace.com/public/privacy-policy"
        style={{ textDecoration: 'underline', fontWeight: 700 }}
        roundedFocusStyle
        primaryColor={colors.green}
      >
        Privacy Policy
      </Link>
      .
    </>
  );
};

const OneFormEligibility = ({
  flowConfig,
  flowId,
  step,
  updateStep,
  service,
  isCreateRoomFlow,
  isReactivationFlow,
  isUpdateCoverageFlow,
  isMBHIneligibilityFlow,
  setRecoveredField,
  overrideFlowID,
  isB2BOutOfNetwork,
  payer = null,
  scrollPage,
  setClientDateOfBirth,
  setBlurParentContent,
  setReferralSource,
  setIsCouldNotBeVerified,
  updateAndSave,
  setInsuranceDetails,
  insuranceDetails,
  isAutoSubmit: allowAutoSubmit,
  isUpdatingStep,
  referralSource,
}: OneFormEligibilityProps) => {
  const history = useHistory();
  const {
    bhNoInsurance,
    authCodeExpirationField,
    registrationAckp0Copay: registrationAckp0CopayActive,
    updateCoveragePart2,
  } = useFlags();
  const [newCorrectedFlowID, setNewCorrectedFlowID] = useState(false);

  const location = useLocation();
  const [authCode, setAuthCode] = useState<string>();
  const [referralOptionsFull, setReferralOptionsFull] = useState<any>(
    getReferralOptions({
      includeExistingUser:
        updateCoveragePart2?.variant === 'treatment' &&
        (isUpdateCoverageFlow || isMBHIneligibilityFlow),
    })
  );

  const { getFlowConfig, setHomePageState } = useHomePageActions();

  const dynamicIntegrationsConfig = useDynamicIntegrationsConfig();

  const { emailDisabled, isDirectoryIntegration } = getSessionStorageValuesFromJson(
    SESSION_STORAGE_MEMBER_DETAILS_KEY,
    ['emailDisabled', 'isDirectoryIntegration']
  );

  useEffect(() => {
    if (isDirectoryIntegration && dynamicIntegrationsConfig?.data) {
      setReferralOptionsFull(getCombinedReferralOptions(dynamicIntegrationsConfig.data));
    }
  }, [dynamicIntegrationsConfig?.data, isDirectoryIntegration]);

  const { isGDPR, consentGDPRProcessing, consentGDPRTransferring } = getGDPRProps();

  const isMoveCoverageEnabled = useMoveCoverageEnabled();

  const { options: insurancePayerOptions = [], loading: isLoadingInsurancePayers } =
    useInsurancePayerOptions({ service, shouldHideOutOfPocket: true });

  const [bhPartnerID, setBhPartnerID] = useState<number | undefined>();
  const [servicesForFlow, setServicesForFlow] = useState<
    Array<{ key: string; value: string; label: string }>
  >([]);

  const [hasServiceType, setHasServiceType] = useState<boolean>(false);

  const [secondaryPayerOptions, setSecondaryPayerOptions] = useState<Array<PayerOptionType>>([]);

  const onFlowRedirect = () => {
    history.push(`/flow/90`);
    setNewCorrectedFlowID(false);
  };

  const handleEnableRetryRef = useRef<(shouldEnableRetry: boolean) => void | undefined>();
  const { colors } = useEmotionTheme();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const disableContinue = useMemo(
    () => isSubmitting || (!bhPartnerID && isLoadingInsurancePayers),
    [bhPartnerID, isLoadingInsurancePayers, isSubmitting]
  );
  const [retryCounter, setRetryCounter] = useState(0);
  const [isError, setIsError] = useState(false);
  const [isPayerOutage, setIsPayerOutage] = useState(false);
  const [generalError, setGeneralError] = useState(undefined);
  const [displayEmployeeInfo, setDisplayEmployeeInfo] = useState(false);
  const [employeeSameAddress, setEmployeeSameAddress] = useState(false);
  const [enableRetry, setEnableRetry] = useState(false);
  const { flowConfig: finalFlowConfig, stepConfig: finalStepConfig } = useMemo(() => {
    let overrideFlowConfig: FlowConfig | null = null;
    let overrideStepConfig: OneFormEligibilityStep | null = null;
    if (isB2BOutOfNetwork) {
      overrideFlowConfig = b2bOutOfNetworkFlowConfig;
      overrideStepConfig = b2bOutOfNetworkEligibilityConfig;
    } else if (overrideFlowID) {
      overrideFlowConfig = getFlowConfig(overrideFlowID, true) || null;
      overrideStepConfig = getStepFromFlowID(overrideFlowID, isOneFormEligibilityStep);
    }
    if (overrideFlowConfig && overrideStepConfig) {
      return {
        flowConfig: overrideFlowConfig,
        stepConfig: overrideStepConfig,
      };
    }
    return {
      flowConfig,
      stepConfig: step.oneFormConfigSet
        ? { ...oneFormConfigSets[step.oneFormConfigSet], ...step }
        : step,
    };
  }, [flowConfig, overrideFlowID, step, isB2BOutOfNetwork, getFlowConfig]);

  const [authCodeExpirationEnabled, setAuthCodeExpirationEnabled] = useState(false);
  const {
    eligibilityType,
    serviceKeywords,
    isManualFlow,
    shouldHideServiceSelection,
    specificCountryCodesToSupport = ['US'],
    collectEmployeeInfo,
    secondaryInsurance,
  } = finalFlowConfig;

  const hasSecondaryInsurance = !!secondaryInsurance?.enabled && !secondaryInsurance.separatePage;

  if (!collectEmployeeInfo) {
    if (!EMPLOYEE_OPTIONS.some((option) => option.value === 'student')) {
      EMPLOYEE_OPTIONS.push({
        key: 'employeeRelationship',
        value: 'student',
        label: 'Student',
      });
    }
    if (!EMPLOYEE_OPTIONS.some((option) => option.value === 'child')) {
      EMPLOYEE_OPTIONS.push({
        key: 'employeeRelationship',
        value: 'child',
        label: 'Child',
      });
    }
  }

  const sessionValues = useMemo(
    () => getFormValuesFromSessionStorage({ finalFlowConfig, finalStepConfig }),
    [finalFlowConfig, finalStepConfig]
  );
  const hasAddress =
    (sessionValues?.addressLine1 || insuranceDetails?.streetAddress) &&
    (sessionValues?.city || insuranceDetails?.city) &&
    (sessionValues?.zipcode || insuranceDetails?.zip);
  const isAutoSubmit = !!(allowAutoSubmit && hasAddress);
  const [isLoadingAutoSubmit, setIsLoadingAutoSubmit] = useState(!!isAutoSubmit);

  const {
    hasEmployeeId,
    authCodeLabel,
    authCodeTooltip,
    hasNumberOfSessions,
    numberOfSessionsTooltip,
    authCodeMaxLength,
    optionalOrganization,
    removePhoneNumber,
    removeEmail,
    removeEmployeeRelation,
    removeReferralSource,
    removeOrganization,
    removeAddress,
    isGroupIdRequired,
    removeGroupID,
    onlyFirstName,
    address1Label,
    address1Hint,
    optionalTruthCheckbox,
    address1ErrorMessage,
    address2Label,
    address2Placeholder,
    emailHint,
    oneFormConfigSet,
    hasAuthCodeExpiration,
    authCodeExpirationMaxDays,
    authCodeExpirationTooltip,
    showMarketingConsent,
  } = finalStepConfig;

  const heading = useHeading({ eligibilityType, isB2BOutOfNetwork });
  const description = useDescription({ isB2BOutOfNetwork });
  const collectInsuranceDetails = useCollectInsuranceDetails({
    eligibilityType,
    isB2BOutOfNetwork,
  });
  const collectFullName = useCollectFullName({ eligibilityType, isB2BOutOfNetwork });
  const collectDateOfBirth = useCollectDateOfBirth({ eligibilityType, isB2BOutOfNetwork });
  const isAddressRequired = eligibilityType !== EligibilityType.organization && !removeAddress;
  const collectTeenSchool =
    service === 'therapyTeen' && eligibilityType === EligibilityType.zipCodeEligibility;
  const showStateAsDropdown = removeAddress;

  const { dispatcherKeyword, dispatcherOrgEmail, dispatcherOrgName } =
    useDispatcherDefaultEligibilityParams(overrideFlowID || flowId);
  const isZipCodeEligibility = eligibilityType === EligibilityType.zipCodeEligibility;

  const { data: schoolList = [], isLoading: isLoadingSchoolList } =
    useQueryPublicSchoolList(collectTeenSchool);

  const methods = useForm<OneFormEligibilityFields, OneFormEligibilityYupContext>({
    resolver: yupResolver(responsiveSchema),
    context: {
      hasEmployeeId,
      hasAuthCode: !!authCodeLabel,
      authCodeMaxLength,
      hasNumberOfSessions: !!hasNumberOfSessions,
      flowId: overrideFlowID || flowId,
      optionalOrganization: optionalOrganization || removeOrganization,
      isGroupIdRequired,
      collectInsuranceDetails,
      isAddressRequired,
      isGDPR,
      hasServiceType,
      specificCountryCodesToSupport,
      removePhoneNumber,
      removeEmail,
      removeEmployeeRelation,
      removeReferralSource,
      removeAddress,
      displayEmployeeInfo,
      employeeSameAddress,
      onlyFirstName,
      optionalTruthCheckbox: optionalTruthCheckbox || isLoadingAutoSubmit,
      addressLine1ErrorMessage: address1ErrorMessage,
      onlyTeens: isZipCodeEligibility,
      authCodeExpirationEnabled,
      authCodeExpirationMaxDays,
      collectTeenSchool,
      showMarketingConsent,
    },
    // defaultValues seem to be necessary for proper error focus when using a resolver
    defaultValues: {
      memberID: insuranceDetails?.memberID || '',
      groupID: '',
      firstName: '',
      lastName: '',
      dateOfBirth: '',
      phone: '',
      addressLine1: insuranceDetails?.streetAddress || '',
      city: insuranceDetails?.city || '',
      zipcode: insuranceDetails?.zip || '',
      country: '',
      clientState: '',
      email: '',
      employeeID: '',
      serviceType: null,
      authorizationCode: '',
      authorizationCodeExpiration: '',
      authCodeExpirationMaxDays: 365,
      numberOfSessions: null,
      organizationName: '',
      employeeRelation: insuranceDetails?.dependentRelationship
        ? {
            value: insuranceDetails?.dependentRelationship,
            label: capitalize(insuranceDetails?.dependentRelationship),
            key: 'employeeRelation',
          }
        : null,
      heardAbout: getReferralSourceDefaultValue({
        referralSource,
        isUpdateCoverageFlow,
        isMBHIneligibilityFlow,
        experimentActive: updateCoveragePart2?.variant === 'treatment',
      }),
      verificationCheckbox: false,
      employeeFirstName: '',
      employeeLastName: '',
      employeeSameAddressCheckbox: false,
      employeeAddressLine1: '',
      employeeCity: '',
      employeeState: '',
      employeeZipcode: '',
      employeeCountry: '',
      socialSecurity: '',
      secondaryMemberID: '',
      secondaryPayerID: '',
      // set the values stored in the session
      ...sessionValues,
      ...(service && { serviceType: SERVICE_OPTIONS.find((s) => s.value === service) }),
      // user the dispatcher values if provided
      ...(dispatcherOrgName && { organizationName: dispatcherOrgName }),
      ...(dispatcherOrgEmail && { email: dispatcherOrgEmail }),
      ...(isGDPR
        ? {
            consentGDPRProcessing: Boolean(consentGDPRProcessing),
            consentGDPRTransferring: Boolean(consentGDPRTransferring),
          }
        : {}),
    } as OneFormEligibilityFields,
  });
  const {
    handleSubmit,
    formState: { errors, isValid },
    getValues,
    setError,
    watch,
  } = methods;

  const { firstName, lastName, dateOfBirth, memberID, clientState, serviceType, heardAbout } =
    getValues();

  const shouldShowHeardAbout = !removeReferralSource && heardAbout?.value !== 'manualSignUp';

  const {
    isManualCoverageRequest,
    setIsManualCoverageRequest,
    manualCoverageRequestStep,
    manualCoverageRequestError,
    insuranceImageFront,
    setInsuranceImageFront,
    insuranceImageBack,
    setInsuranceImageBack,
    setIDImageFront,
    setIDImageBack,
    email,
    setEmail,
    goToNextStep,
    onSubmitManualCoverageRequest,
  } = useManualCoverageVerification({
    firstName,
    lastName,
    dateOfBirth,
    memberID: memberID as string,
    state: clientState as string,
    partnerID: bhPartnerID as number,
    serviceType: (serviceType?.value || service || finalFlowConfig.serviceType) as ServiceType,
  });

  const isManualCoverageRequestOrPayerOutage = useMemo(
    () => isManualCoverageRequest || isPayerOutage,
    [isManualCoverageRequest, isPayerOutage]
  );

  const isNonOutageError = useMemo(() => isError && !isPayerOutage, [isError, isPayerOutage]);

  const shouldShowDateOfBirthUnderageAlert = useMemo(
    () =>
      errors.dateOfBirth?.message === ageErrorCopy(flowId) &&
      flowConfig.serviceType !== 'psychiatry' &&
      oneFormConfigSet === OneFormConfigSetType.nycTeen,
    [oneFormConfigSet, flowConfig.serviceType, errors.dateOfBirth, flowId]
  );

  const shouldShowRetryModal = useMemo(
    () => bhNoInsurance && !isManualCoverageRequestOrPayerOutage && collectInsuranceDetails,
    [bhNoInsurance, isManualCoverageRequestOrPayerOutage, collectInsuranceDetails]
  );

  const handleServiceSelection = useHandleServiceSelection({
    isCreateRoomFlow,
    isMBHIneligibilityFlow,
    isReactivationFlow,
    isUpdateCoverageFlow,
    updateAndSave,
  });

  const memberIDGuidance = useMemberIDGuidance(bhPartnerID);

  useEffect(() => {
    if (isManualCoverageRequestOrPayerOutage) {
      scrollPage({ top: 0 });
    }
  }, [isManualCoverageRequestOrPayerOutage, scrollPage]);

  useEffect(() => {
    setAuthCodeExpirationEnabled(!!hasAuthCodeExpiration && authCodeExpirationField);
  }, [authCodeExpirationField, hasAuthCodeExpiration]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const authCodeValue = queryParams.get('authCode');
    if (authCodeValue) {
      setAuthCode(authCodeValue);
      methods.resetField('authorizationCode', { defaultValue: authCodeValue });
      methods.resetField('organizationName', { defaultValue: '' });
    }
  }, [location, methods]);

  const serviceTypeSelection = watch(OneFormEligibilityFieldNames.serviceType);
  const dateOfBirthValue = watch(OneFormEligibilityFieldNames.dateOfBirth);
  const employeeRelationValue = watch(OneFormEligibilityFieldNames.employeeRelation);
  const employeeSameAddressCheckboxValue = watch(
    OneFormEligibilityFieldNames.employeeSameAddressCheckbox
  );
  const organizationName = watch(OneFormEligibilityFieldNames.organizationName);
  const secondaryPayerID = watch(OneFormEligibilityFieldNames.secondaryPayerID);
  const consentGDPRProcessingValue = watch(OneFormEligibilityFieldNames.consentGDPRProcessing);
  const consentGDPRTransferringValue = watch(OneFormEligibilityFieldNames.consentGDPRTransferring);
  const errorsNotEmptyAndNotNYCTeen =
    !!Object.keys(errors).length && oneFormConfigSet !== OneFormConfigSetType.nycTeen;

  useEffect(() => {
    if (insurancePayerOptions) {
      setServicesForFlow(
        SERVICE_OPTIONS.filter(
          (option: { key: string; value: string; label: string; disabled?: boolean }) =>
            eligibilityType === EligibilityType.trizetto
              ? insurancePayerOptions
                  .find((insurancePayer) => insurancePayer.flowID === (overrideFlowID || flowId))
                  ?.serviceTypes?.includes(option.value as CommonServiceType)
              : Object.keys(serviceKeywords || []).includes(option.value)
        )
      );

      // Add blank option to allow user to clear selection
      setSecondaryPayerOptions(
        secondaryPayerID
          ? [{ id: 0, value: '', label: '' } as PayerOptionType].concat(insurancePayerOptions)
          : insurancePayerOptions
      );
    }
  }, [
    eligibilityType,
    flowId,
    insurancePayerOptions,
    overrideFlowID,
    secondaryPayerID,
    serviceKeywords,
  ]);

  useEffect(() => {
    setHasServiceType(
      !!servicesForFlow?.length &&
        // The current flow must not have a hard-coded serviceType (don't use finalFlowConfig)
        !flowConfig.serviceType &&
        (isManualFlow || shouldHideServiceSelection ? servicesForFlow.length > 1 : true)
    );
  }, [flowConfig.serviceType, isManualFlow, servicesForFlow, shouldHideServiceSelection]);

  useEffect(() => {
    if (insurancePayerOptions && (overrideFlowID || flowId)) {
      let insurancePayer;
      if (payer?.partnerID) {
        insurancePayer = payer;
      } else {
        insurancePayer = insurancePayerOptions.find((p) => p.flowID === (overrideFlowID || flowId));
      }
      setBhPartnerID(insurancePayer?.partnerID || undefined);

      if (!insuranceDetails?.gediPayerID && insurancePayer) {
        setInsuranceDetails({
          payerName: insurancePayer.label,
          gediPayerID: insurancePayer.value,
        });
      }
    }
  }, [flowId, insurancePayerOptions, overrideFlowID, payer, insuranceDetails, setInsuranceDetails]);

  useEffect(() => {
    if (collectEmployeeInfo && employeeRelationValue) {
      if (employeeRelationValue.value === 'spouse' || employeeRelationValue.value === 'dependent') {
        setDisplayEmployeeInfo(true);
      } else {
        setDisplayEmployeeInfo(false);
      }
    }
  }, [employeeRelationValue, collectEmployeeInfo]);

  useEffect(() => {
    setEmployeeSameAddress(employeeSameAddressCheckboxValue || false);
  }, [employeeSameAddressCheckboxValue]);

  const handleSubmitError = useCallback(
    (shouldSetError = true, error) => {
      if (isZipCodeEligibility) {
        // Eligibility error, go to teen flow
        switchFlowHelper(DEFAULT_FLOWS.TEENS, history, updateAndSave);
        return;
      }
      setIsError(shouldSetError);
      setIsCouldNotBeVerified(true);
      if (error) {
        setGeneralError(error);
      }
      setIsPayerOutage(error?.response?.data?.isPayerOutage);
      setIsSubmitting(false);
      setIsLoadingAutoSubmit(false);
    },
    [
      isZipCodeEligibility,
      history,
      updateAndSave,
      setIsCouldNotBeVerified,
      setIsPayerOutage,
      setIsError,
    ]
  );

  const handleEnableRetry = useCallback(
    (shouldEnableRetry: boolean = false) => {
      setEnableRetry(shouldEnableRetry);

      if (shouldEnableRetry) {
        setIsSubmitting(false);
        setIsLoadingAutoSubmit(false);
      }
      setRetryCounter((count) => count + 1);
    },
    [setEnableRetry, setIsSubmitting, setRetryCounter]
  );

  const handleFlowRedirection = (hasNewCorrectedFlowID = false) => {
    setNewCorrectedFlowID(hasNewCorrectedFlowID);
    setIsSubmitting(false);
    setIsLoadingAutoSubmit(false);
  };

  const onSelectAddress = (parsedAddress) => {
    storeGDPRProps({ isGDPR: isGDPRCountry({ countryCode: parsedAddress.country }) });
    setHomePageState({
      clientCountry: parsedAddress.country,
      clientState: parsedAddress.clientState,
    });
  };

  const onReturnPress = useCallback(
    ({ isManualRequest }: { isManualRequest?: boolean }) => {
      setIsError(false);
      setIsSubmitting(false);
      setIsLoadingAutoSubmit(false);
      if (bhNoInsurance) {
        setEnableRetry(false);
        setRetryCounter(0);
      }
      if (isManualRequest) {
        setIsManualCoverageRequest(true);
      }
    },
    [
      bhNoInsurance,
      setIsManualCoverageRequest,
      setEnableRetry,
      setRetryCounter,
      setIsError,
      setIsSubmitting,
    ]
  );

  const onSubmit = useCallback(
    (formData: OneFormEligibilityFields) => {
      setIsSubmitting(true);
      const oneFormData = JSON.parse(JSON.stringify(formData));

      const { state, country } = countriesHelper.getStateAndCountryOverrides(
        oneFormData.country,
        oneFormData.clientState
      );

      oneFormData.country = country;
      oneFormData.clientState = state;

      if (bhNoInsurance) {
        handleEnableRetryRef.current =
          !enableRetry || retryCounter < MAX_RETRIES ? handleEnableRetry : undefined;
      } else {
        handleEnableRetryRef.current = !enableRetry ? handleEnableRetry : undefined;
      }
      setInsuranceDetails({
        ...oneFormData,
        dependentRelationship: oneFormData.employeeRelation?.label,
        dob: oneFormData.dateOfBirth,
        state: oneFormData.clientState,
      });

      if (isManualCoverageRequestOrPayerOutage) {
        goToNextStep();
        setIsSubmitting(false);
        setIsLoadingAutoSubmit(false);
      } else {
        if (isZipCodeEligibility) {
          // Non-eligible adult, go to regular psychotherapy flow
          if (!isUnder18(oneFormData.dateOfBirth)) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set('clientAge', oneFormData.dateOfBirth);
            history.replace({ search: searchParams.toString() });
            handleServiceSelection('psychotherapy', true);
            return;
          }
        }
        submitOneFormData({
          flowId: overrideFlowID || flowId,
          flowConfig: finalFlowConfig,
          step: { ...finalStepConfig, buttonTarget: step.buttonTarget },
          oneFormData: { ...oneFormData },
          contextData: {
            eligibilityWidgetHasRooms: false,
            isCreateRoomFlow,
            isReactivationFlow,
            isUpdateCoverageFlow,
            isMBHIneligibilityFlow,
            payer,
          },
          history,
          handleSubmitError,
          handleFlowRedirection,
          handleManualRequest: onReturnPress,
          updateStep,
          setError,
          dispatcherKeyword,
          currentFlowID: flowId,
          displayEmployeeInfo,
          bhNoInsurance,
          handleEnableRetry: handleEnableRetryRef.current,
          isMoveCoverageEnabled,
          isAuthCodeExpirationFieldEnabled: authCodeExpirationField,
          registrationAckp0CopayActive,
          bhPartnerID,
        }).then(() => {
          setIsSubmitting(false);
          setIsLoadingAutoSubmit(false);
        });
      }
      // syncs HomePageState.clientDateOfBirth on successful form submit, which is pertinent to flow logic
      setClientDateOfBirth(moment(dateOfBirthValue).format('YYYY-MM-DD'));

      setReferralSource(oneFormData.heardAbout?.value || '');

      // Update sesssion storage with member info on submit
      setSessionBasicInformation(oneFormData, displayEmployeeInfo);
      setSessionMemberMemberDetails(oneFormData, displayEmployeeInfo);
      if (isGDPR) {
        storeGDPRProps({
          consentGDPRProcessing: Boolean(consentGDPRProcessingValue),
          consentGDPRTransferring: Boolean(consentGDPRTransferringValue),
        });
      }
    },
    [
      authCodeExpirationField,
      bhNoInsurance,
      bhPartnerID,
      consentGDPRProcessingValue,
      consentGDPRTransferringValue,
      dateOfBirthValue,
      displayEmployeeInfo,
      enableRetry,
      finalFlowConfig,
      finalStepConfig,
      flowId,
      handleEnableRetry,
      handleServiceSelection,
      history,
      isManualCoverageRequestOrPayerOutage,
      isMoveCoverageEnabled,
      isReactivationFlow,
      isUpdateCoverageFlow,
      onReturnPress,
      overrideFlowID,
      payer,
      setClientDateOfBirth,
      setInsuranceDetails,
      setReferralSource,
      step.buttonTarget,
      updateStep,
      dispatcherKeyword,
      goToNextStep,
      handleSubmitError,
      isCreateRoomFlow,
      isGDPR,
      isMBHIneligibilityFlow,
      isZipCodeEligibility,
      location.search,
      registrationAckp0CopayActive,
      retryCounter,
      setError,
    ]
  );

  useSessionStorage(getValues, setRecoveredField, displayEmployeeInfo);

  useScrollToFirstInvalidField(errors);

  useEffect(() => {
    // If we're in a update coverage flow we should already have the data
    // for OneForm submit. Auto submit and we'll get errors if something is missing.
    if (
      updateCoveragePart2.variant === 'treatment' &&
      isAutoSubmit &&
      isValid &&
      isLoadingAutoSubmit
    ) {
      handleSubmit(onSubmit)();
    }
    return () => {};
    // disabling eslint rule because onSubmit updates during submit
  }, [isAutoSubmit, handleSubmit, updateCoveragePart2, isValid, isLoadingAutoSubmit]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const timerID = setTimeout(() => {
      setIsLoadingAutoSubmit(false);
    }, 500);
    return () => {
      clearTimeout(timerID);
    };
  }, []);

  if (
    updateCoveragePart2.variant === 'treatment' &&
    (isLoadingAutoSubmit || isSubmitting || isUpdatingStep)
  ) {
    return (
      <View justify="center" align="center" row style={{ paddingTop: 250 }}>
        <Spinner isLoading />
      </View>
    );
  }

  const keyword = bhPartnerID ? 'Keyword (optional)' : 'Keyword';
  if (manualCoverageRequestStep === ManualCoverageRequestStep.UPLOAD_ID_IMAGES) {
    return (
      <StyledContainer row justify="center">
        <OneFormEligibilityUploadIDCardImages
          onContinue={goToNextStep}
          onUploadFront={(image) => {
            setIDImageFront(image);
          }}
          onDeleteFront={() => setIDImageFront(undefined)}
          onUploadBack={(image) => {
            setIDImageBack(image);
          }}
          onDeleteBack={() => setIDImageBack(undefined)}
        />
      </StyledContainer>
    );
  }

  if (manualCoverageRequestStep === ManualCoverageRequestStep.SUBMIT) {
    return (
      <StyledContainer row justify="center">
        <OneFormEligibilitySubmitManualRequest
          onChange={(emailInput) => setEmail(emailInput)}
          onSubmit={onSubmitManualCoverageRequest}
          submissionError={manualCoverageRequestError}
          isLoading={!bhPartnerID}
        />
      </StyledContainer>
    );
  }

  if (manualCoverageRequestStep === ManualCoverageRequestStep.SUBMIT_SUCCESS) {
    return (
      <StyledContainer row justify="center">
        <OneFormEligibilitySubmitManualRequestSuccess
          showBackToAccountCTA={
            isCreateRoomFlow || isReactivationFlow || isUpdateCoverageFlow || isMBHIneligibilityFlow
          }
          onBackToAccountCTAPress={() => {
            window.onbeforeunload = null;
            reactFrameService.closePopup();
          }}
          email={email}
        />
      </StyledContainer>
    );
  }
  const NoCoverage = bhNoInsurance ? (
    <OneFormEligibilityNoCoverage
      insuranceInfo={{
        payerName: payer?.label || organizationName || '',
        fullName: lastName ? `${firstName} ${lastName}` : firstName,
        dateOfBirth: dateOfBirth || '',
        memberID: memberID || '',
      }}
      step={step}
      handleReturnPress={onReturnPress}
      updateStep={updateStep}
      setIsCouldNotBeVerified={setIsCouldNotBeVerified}
    />
  ) : (
    <OneFormEligibilityError
      error={generalError}
      handleOnPressReturn={() => onReturnPress({})}
      linkToFAQ={generalError ? 'https://help.talkspace.com/hc/en-us' : undefined}
    />
  );

  return isNonOutageError ? (
    NoCoverage
  ) : (
    <StyledContainer>
      {isManualCoverageRequestOrPayerOutage ? (
        <OneFormEligibilityUploadInsuranceCardImages
          onUploadFront={(image) => {
            setInsuranceImageFront(image);
          }}
          onDeleteFront={() => setInsuranceImageFront(undefined)}
          onUploadBack={(image) => {
            setInsuranceImageBack(image);
          }}
          onDeleteBack={() => setInsuranceImageBack(undefined)}
        />
      ) : (
        <>
          {newCorrectedFlowID && (
            <FlowRedirectionModal
              isVisible
              isLoading={false}
              title="We’re sorry! It looks like you may be using the wrong registration form."
              subTitle="Let’s try to find the correct registration for the plan you’re looking for."
              buttonDataQa="okButton"
              onSubmit={onFlowRedirect}
              onClose={() => handleFlowRedirection()}
              setBlurParentContent={setBlurParentContent}
            />
          )}
          {oneFormConfigSet === OneFormConfigSetType.nycTeen ? (
            <>
              <OneFormNYCTeen style={{ width: '100%', marginBottom: 24 }} />
              <Title>We need some details to get started</Title>
            </>
          ) : (
            <>
              <Title>{heading}</Title>
              {description && <Description>{description}</Description>}
            </>
          )}
        </>
      )}
      <FormProvider {...methods}>
        <StyledForm onSubmit={handleSubmit(onSubmit)}>
          {/* renders for all flows that aren't associated with an organization */}
          {/* isGroupIdRequired is a step attribute that should be passed to validation context */}
          {collectInsuranceDetails && (
            <>
              <RHFInput
                fieldName={OneFormEligibilityFieldNames.memberID}
                label={memberIDGuidance.label}
                tooltip={memberIDGuidance.tooltip}
                placeholder={memberIDGuidance.hint}
                hint={memberIDGuidance.description}
                isRequired
              />
              {!removeGroupID && (
                <RHFInput
                  fieldName={OneFormEligibilityFieldNames.groupID}
                  label={`Group/Plan ID${!isGroupIdRequired ? ' (optional)' : ''}`}
                  tooltip="Usually located on your Health Plan ID card. If available, type the full ID, including letters and numbers."
                  isRequired={isGroupIdRequired}
                />
              )}
            </>
          )}
          {collectFullName && (
            <FullName
              firstNameFieldName={OneFormEligibilityFieldNames.firstName}
              firstNameLabel="First name"
              lastNameFieldName={OneFormEligibilityFieldNames.lastName}
              lastNameLabel="Last name"
              onlyFirstName={onlyFirstName}
            />
          )}
          {collectDateOfBirth && (
            <RHFDateInput
              fieldName={OneFormEligibilityFieldNames.dateOfBirth}
              label="Date of birth"
              isRequired
            />
          )}
          {shouldShowDateOfBirthUnderageAlert && (
            <DateOfBirthUnderageAlert flowId={overrideFlowID || flowId} />
          )}
          {showStateAsDropdown && (
            <RHFSelect
              fieldName={OneFormEligibilityFieldNames.clientState}
              label="State"
              options={states}
              allowMobileSearch
              registerOptionValue
            />
          )}
          {!removePhoneNumber && <RHFPhoneInput fieldName={OneFormEligibilityFieldNames.phone} />}
          {/* required for all flows except organization flows that don't need address */}
          {isAddressRequired && (
            <A11yFullAddress
              states={states}
              stateName="clientState"
              countries={countriesDropdownOptions}
              address1Label={address1Label}
              address1Hint={address1Hint}
              address2Label={address2Label}
              address2Placeholder={address2Placeholder}
              onSelectAddress={onSelectAddress}
            />
          )}
          {!removeEmployeeRelation && (
            <RHFSelect
              fieldName={OneFormEligibilityFieldNames.employeeRelation}
              label="Let us know who you are"
              options={EMPLOYEE_OPTIONS}
              tooltip="Let us know if you are the employee or student with access to the Talkspace benefit, or if you are the spouse/partner or dependent of the person who has prepaid access to Talkspace."
              tooltipLabel="Employee relation tooltip"
            />
          )}
          {displayEmployeeInfo && (
            <>
              <EmployeeInfoStyle>
                <Large>
                  Eligible dependents can access services under the employee’s benefits. Please
                  enter the covered employee’s information below.
                </Large>
              </EmployeeInfoStyle>
              <FullName
                firstNameFieldName={OneFormEligibilityFieldNames.employeeFirstName}
                firstNameLabel="First Name"
                lastNameFieldName={OneFormEligibilityFieldNames.employeeLastName}
                lastNameLabel="Last Name"
              />
              <EmployeeInfoStyle>
                <RHFCheckbox
                  labelStyle={{ fontSize: 14, color: colors.black }}
                  fieldName={OneFormEligibilityFieldNames.employeeSameAddressCheckbox}
                  label="Employee’s address is the same as mine"
                />
              </EmployeeInfoStyle>
              {!employeeSameAddress && (
                <A11yFullAddress
                  addressLine1Name={OneFormEligibilityFieldNames.employeeAddressLine1}
                  addressLine2Name={OneFormEligibilityFieldNames.employeeAddressLine2}
                  cityName={OneFormEligibilityFieldNames.employeeCity}
                  stateName={OneFormEligibilityFieldNames.employeeState}
                  zipcodeName={OneFormEligibilityFieldNames.employeeZipcode}
                  countryName={OneFormEligibilityFieldNames.employeeCountry}
                  states={states}
                  countries={countriesDropdownOptions}
                  qaAttributeUniqueNaming="Employee"
                  onSelectAddress={onSelectAddress}
                />
              )}
            </>
          )}
          {/* optionalOrganization and removeOrganization are step attribute */}
          {/* required for all organization flows with one exception (for now) */}
          {!removeOrganization && (
            <RHFInput
              fieldName={OneFormEligibilityFieldNames.organizationName}
              label={`Organization name${
                !optionalOrganization || bhPartnerID ? '' : ' (optional)'
              } / ${keyword}`}
              tooltip="This is the name / keyword of the employer; school, or other organization making Talkspace services available to you."
              placeholder="Enter name"
              isRequired={!optionalOrganization}
              readOnly={!!dispatcherOrgName && !authCode}
            />
          )}
          {shouldShowHeardAbout && (
            <RHFSelect
              fieldName={OneFormEligibilityFieldNames.heardAbout}
              label="How did you hear about us?"
              options={referralOptionsFull}
            />
          )}
          {collectTeenSchool && (
            <RHFSelect
              fieldName={OneFormEligibilityFieldNames.attendedSchool}
              defaultOptions={schoolList}
              loadOptions={selectLoadOptions(schoolList)}
              isAsync
              cacheOptions
              isLoading={isLoadingSchoolList}
              isCreatable
              isInputReadOnly={false}
              label="School you attend (optional)"
              placeholder="School you attend"
            />
          )}
          {/* used to locate or generate keyword */}
          <RHFInput
            fieldName={OneFormEligibilityFieldNames.email}
            label="Email"
            tooltip="Use a private email that Talkspace communications can be sent to"
            placeholder="Email"
            isRequired
            isDisabled={emailDisabled}
            hint={emailHint}
          />
          {/* only required for some organization flows */}
          {/* hasEmployeeId is a step attribute */}
          {hasEmployeeId && (
            <RHFInput
              fieldName={OneFormEligibilityFieldNames.employeeID}
              label="Employee ID"
              tooltip="Please ask your Googler for their Employee ID"
              placeholder="Enter employee ID"
              isRequired
            />
          )}
          {/* only required for some organization flows */}
          {/* authCodeLabel is step attribute */}
          {/* hasNumberOfSessions is step attribute */}
          {authCodeLabel && (
            <AuthorizationCode
              authorizationCodeFieldName={OneFormEligibilityFieldNames.authorizationCode}
              numberOfSessionsFieldName={OneFormEligibilityFieldNames.numberOfSessions}
              authorizationCodeExpirationFieldName={
                OneFormEligibilityFieldNames.authorizationCodeExpiration
              }
              authCodeExpirationEnabled={authCodeExpirationEnabled}
              authCodeTooltip={authCodeTooltip}
              hasNumberOfSessions={hasNumberOfSessions}
              numberOfSessionsTooltip={numberOfSessionsTooltip}
              flowID={overrideFlowID || flowId}
              authCodeExpirationTooltip={authCodeExpirationTooltip}
            />
          )}
          {hasServiceType && (
            <RHFSelect
              fieldName={OneFormEligibilityFieldNames.serviceType}
              options={servicesForFlow}
              label="What type of service are you looking for?"
              tooltip="Choose therapy for a convenient way to have access to a licensed clinician on a regular basis. Therapy is the space for you to explore your feelings and get tools to manage your experiences and improve your quality of life.\n\nIf you are currently taking psychotropic medication or have been referred to a psychiatrist by another healthcare provider, select Psychiatry. Unsure? Start with therapy and your provider will explore your needs and concerns."
              tooltipLabel="Service type tooltip"
            />
          )}
          {hasSecondaryInsurance && (
            <>
              <RHFSelect
                fieldName={OneFormEligibilityFieldNames.secondaryPayerID}
                label="Supplement insurance"
                placeholder="Find your supplement insurance"
                options={secondaryPayerOptions}
                isLoading={isLoadingInsurancePayers}
                isInputReadOnly={false}
                registerOptionValue
                hasDuplicateOptionValues
                cacheOptions
              />
              <RHFInput
                fieldName={OneFormEligibilityFieldNames.secondaryMemberID}
                label="Supplement Insurance Member/Subscriber ID"
                tooltip="Located on your Health Plan ID card. Type the full ID, including letters and numbers."
                placeholder="Supplement Insurance Member / Subscriber ID"
              />
            </>
          )}
          {/* render if the flow has a service type dropdown, user has selected psychiatry and user not underage */}
          {hasServiceType &&
            serviceTypeSelection?.value === 'psychiatry' &&
            !(errors.serviceType as FieldError)?.message && (
              <PsychiatryRxMessage style={{ maxWidth: 335, marginBottom: 12 }} />
            )}
          {!optionalTruthCheckbox && (
            <RHFCheckbox
              fieldName={OneFormEligibilityFieldNames.verificationCheckbox}
              label={
                <TextDS variant="bodyXs" colorRole="textSubtle">
                  I verify that the above statements are truthful and I am eligible for this benefit
                  or an eligible family member.
                </TextDS>
              }
              containerStyle={{ textAlign: 'start' }}
              isRequired
            />
          )}
          {!isGDPR && showMarketingConsent && (
            <RHFCheckbox
              fieldName={OneFormEligibilityFieldNames.marketingConsent}
              label={<EmailMarketingLabel />}
            />
          )}
          {isGDPR && (
            <>
              <GDPRConsentCheckbox type="processing" />
              <GDPRConsentCheckbox type="transferring" />
            </>
          )}
          {bhNoInsurance && collectInsuranceDetails ? (
            <Button
              dataQa="oneFormEligibilityContinueButton"
              text="Continue"
              roundedFocusStyle
              disabled={
                disableContinue ||
                (isManualCoverageRequestOrPayerOutage &&
                  (!insuranceImageFront || !insuranceImageBack))
              }
              primaryColor={colors.green}
              style={{
                alignSelf: 'center',
                width: '100%',
                fontWeight: isMoveCoverageEnabled ? 'bold' : undefined,
              }}
            />
          ) : (
            <Button
              dataQa="oneFormEligibilityContinueButton"
              text={enableRetry ? 'Retry' : 'Continue'}
              roundedFocusStyle
              disabled={disableContinue}
              isLoading={disableContinue}
              primaryColor={colors.green}
              style={{
                alignSelf: 'center',
                width: '100%',
                fontWeight: isMoveCoverageEnabled ? 'bold' : undefined,
              }}
            />
          )}
        </StyledForm>
      </FormProvider>
      {shouldShowRetryModal ? (
        <OneFormEligibilityRetryModal
          isCheckingCoverage={isSubmitting}
          shouldRetry={enableRetry}
          handleRetryPress={handleSubmit(onSubmit)}
        />
      ) : null}
      {!!step.skipText && (
        <SkipOption
          buttonStyles={{
            color: colors.permaWildBlueYonder,
            '&:hover': {
              textDecorationColor: colors.permaWildBlueYonder,
              color: colors.permaWildBlueYonder,
            },
          }}
          step={step}
          updateStep={updateStep}
          primaryColor={colors.permaWildBlueYonder}
        />
      )}
      <Tiny style={{ maxWidth: 330, alignSelf: 'center', textAlign: 'center', marginTop: 15 }}>
        Talkspace is committed to protecting your privacy and follows HIPAA, state and federal laws.
      </Tiny>
      {errorsNotEmptyAndNotNYCTeen && (
        <StyledAlertBox dataQa="invalidFieldsError">
          One or more fields are missing or invalid. Please check the information and try again.
        </StyledAlertBox>
      )}
      {!!errors.organizationName && (
        <StyledAlertBox dataQa="unrecognizedOrganizationError">
          Organization name was not recognized. Please check the information and try again.
        </StyledAlertBox>
      )}
      {errors.dateOfBirth?.message === ageErrorCopy(flowId) &&
        flowConfig.serviceType !== 'psychiatry' &&
        oneFormConfigSet !== OneFormConfigSetType.nycTeen && (
          <StyledAlertBox dataQa="underageError">
            Talkspace cannot provide service to individuals under the age of{' '}
            {isTeensFlow(flowId) ? '13' : '18'} at this time. If you or anyone you know are in a
            crisis or may be in danger, please use the{' '}
            <Link
              dataQa="helpResourcesLink"
              style={{ textDecoration: 'underline' }}
              roundedFocusStyle
              primaryColor={colors.black}
              href={`https://helpnow.talkspace.com/under-${isTeensFlow(flowId) ? '13' : '18'}`}
            >
              following resources
            </Link>{' '}
            to get immediate help.
          </StyledAlertBox>
        )}
      {(errors.serviceType as FieldError)?.message === PSYCH_TOO_YOUNG_ERROR &&
        flowConfig.serviceType === 'psychiatry' && (
          <StyledAlertBox dataQa="underageError">{PSYCH_TOO_YOUNG_ERROR}</StyledAlertBox>
        )}
    </StyledContainer>
  );
};

const OneFormEligibilityWrapper = (props: Omit<OneFormEligibilityProps, 'overrideFlowID'>) => {
  const {
    organizationFlowID,
    step,
    b2bForkResult,
    partnerFlowID,
    updateStep,
    isCouldNotBeVerified,
  } = props;
  const [overrideFlowID, setOverrideFlowID] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // In case params change
    setIsLoading(true);
    if (b2bForkResult === 'b2c' || (organizationFlowID && step.disableOrganization)) {
      updateStep(step.buttonTarget, undefined, undefined, undefined, {
        removeStepFromHistory: true,
      });
      return;
    }
    if (organizationFlowID || partnerFlowID) {
      setOverrideFlowID((organizationFlowID || partnerFlowID) as number);
    }
    setIsLoading(false);
  }, [
    b2bForkResult,
    organizationFlowID,
    partnerFlowID,
    step.buttonTarget,
    step.disableOrganization,
    updateStep,
    isCouldNotBeVerified,
  ]);

  if (isLoading) return <Spinner isLoading />;

  return <OneFormEligibility {...props} overrideFlowID={overrideFlowID} />;
};

export default OneFormEligibilityWrapper;
