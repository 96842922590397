import { useWatch } from 'react-hook-form';
import { RHFInput, RHFSelect } from '@talkspace/react-toolkit';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { useEffect } from 'react';
import { useContinue, useFormSaver } from './hooks';
import { useEligibilityService, EligibilityServiceFormData } from '@/hooks/useEligibilityService';
import StatusModal from './StatusModal';
import { QuickEligibilityProps } from './types';
import countriesHelper from '@/Helpers/countriesHelper';
import EligibilityForm, {
  AA_TOOLTIP_STYLE,
  AdditionalFieldsParams,
  Styled,
} from './EligibilityForm';
import { OneFormEligibilityFieldNames } from '../../OneFormEligibility/types';
import { EMPLOYEE_OPTIONS_BH_QUICK_ELIGIBILITY } from '../../OneFormEligibility/util';
import useMemberIDGuidance from '@/hooks/useMemberIDGuidance';
import { trackBHEligibilityScreenSeen } from '../../../utils/analytics/events';
import useBHOnboardingImprovements from '../../../hooks/useBHOnboardingImprovements';

const MemberIdEligibility = ({
  updateStep,
  step,
  insurancePayer,
  setReferralSource,
  referralSource,
  partnerFlowID,
  fillContainerBannerHeight,
  insuranceFieldTop,
  isEditInsuranceInfoScreen,
  showEmployeeRelation,
  service,
}: QuickEligibilityProps) => {
  useFormSaver();
  const { bhInsuranceEligibilityError, updateCoveragePart2 } = useFlags();
  const isBHOnboardingImprovements = useBHOnboardingImprovements();

  const { result, done, error, errorType, canRetry, attempts, isSubmitting, submit } =
    useEligibilityService({
      maxAttempts: 3,
    });

  const data = useWatch() as any as EligibilityServiceFormData;

  const memberIDGuidance = useMemberIDGuidance(insurancePayer?.partnerID || '');

  useEffect(() => {
    if (bhInsuranceEligibilityError !== undefined) {
      trackBHEligibilityScreenSeen('Check your coverage Seen', 'check-your-coverage');
    }
  }, [bhInsuranceEligibilityError]);

  useContinue({
    result,
    data,
    done,
    errorType,
    updateStep,
    step,
    attempts,
    insurancePayer,
    error,
    service,
  });

  const statusModalIsVisible = (isSubmitting || (attempts > 0 && canRetry)) && !done;
  const shouldRetry = attempts > 0 && !isSubmitting && canRetry;

  const handleOnSubmit = (formData: EligibilityServiceFormData) => {
    const parsedFormData = JSON.parse(JSON.stringify(formData));

    const { state: clientState, country } = countriesHelper.getStateAndCountryOverrides(
      parsedFormData.country,
      parsedFormData.state
    );

    parsedFormData.country = country;
    parsedFormData.state = clientState;

    return submit(parsedFormData);
  };

  const additionalFields = (props: AdditionalFieldsParams) => {
    if (isBHOnboardingImprovements) {
      return [];
    }
    const { setValue, isManualSignUpFlow } = props;
    const employeeRelationQuestion = showEmployeeRelation
      ? [
          <RHFSelect
            fieldName={OneFormEligibilityFieldNames.employeeRelation}
            label={
              updateCoveragePart2.variant === 'treatment'
                ? 'Relationship to insurance holder'
                : 'Primary insurance holder'
            }
            options={EMPLOYEE_OPTIONS_BH_QUICK_ELIGIBILITY}
            tooltip="Are you the primary person insured, or are you their spouse/partner or child/dependent?"
            tooltipLabel="Employee relation tooltip"
          />,
        ]
      : [];
    return [
      <RHFInput
        fieldName="memberID"
        label={memberIDGuidance.label}
        tooltip={memberIDGuidance.tooltip}
        placeholder={memberIDGuidance.hint}
        hint={memberIDGuidance.description}
        isRequired
        data-qa="member-id-input"
        onChange={(e) => {
          const newValue = e.target.value.replace(/\s/gi, '').toLowerCase();
          if (newValue !== e.target.value) {
            setValue('memberID', newValue);
          }
        }}
        labelStyle={{ alignSelf: 'flex-end' }}
        tooltipStyle={AA_TOOLTIP_STYLE}
        isDisabled={isManualSignUpFlow && 'memberID' in data && !!data.memberID}
      />,
      ...employeeRelationQuestion,
    ];
  };

  const eligibilityForm = EligibilityForm({
    done,
    handleOnSubmit,
    isSubmitting,
    data,
    referralSource,
    setReferralSource,
    additionalFields,
    insuranceFieldTop,
    isEditInsuranceInfoScreen,
    partnerFlowID,
  });

  if (result) {
    return null;
  }

  const topMarginStyle =
    updateCoveragePart2.variant === 'treatment' && fillContainerBannerHeight
      ? { marginTop: fillContainerBannerHeight - 24 }
      : { marginTop: fillContainerBannerHeight };

  return (
    <Styled.View style={fillContainerBannerHeight ? topMarginStyle : {}}>
      <StatusModal
        visible={statusModalIsVisible}
        isCheckingCoverage={isSubmitting}
        showRetryButton={shouldRetry}
        onHandleRetry={() => submit(data)}
      />
      {eligibilityForm}
    </Styled.View>
  );
};

export default MemberIdEligibility;
