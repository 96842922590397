/**
 * Displays a screen that asks the user if they want to quickly check and see
 * if insurance will cover them.
 */
import { useEffect, useCallback } from 'react';
import {
  ExtraHuge,
  StepTip,
  View,
  TextDS,
  Button,
  useEmotionTheme,
  Image,
} from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import { ThemedFlagsProvider } from 'launchDarkly';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { isLDExperimentTreatment } from 'launchDarkly/util';
import { fromPageSource, fromPageToUpdateCoverageSource } from 'ts-analytics/types';
import { trackUpdateCoverageSourceEvent } from 'ts-analytics/mixpanel/events';
import { useLocation } from 'react-router';
import { withRouter } from '@/core/routerLib';
import styled from '@/core/styled';
import handsFlowerInsurance from '@/Assets/hands-flower-insurance.png';
import { Questions } from './Questions';
import { WantInsuranceCheckProps } from './types';
import { useOnSelect } from './hooks';
import { useInsuranceCoverageProps } from '../../Flows/utils';
import { FLOW_232_CONSUMER_THERAPY_V2_BH } from '../../Flows/Flow232';
import switchFlowHelper from '../../Helpers/switchFlowHelper';
import StickyDrawerQM from '../StickyDrawerQM';

const Styled = {
  Heading: styled(ExtraHuge)(() => {
    return {
      marginBottom: 35,
    };
  }),
  Container: styled(View)({
    maxWidth: 430,
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: 40,
  }),
  TipContainer: styled(View)({
    marginTop: 40,
    paddingLeft: 10,
    paddingRight: 10,
  }),
};

const InsuranceCheckConfirmation = ({
  step: { flowId },
  updateStep,
  updateAndSave,
  isUpdateCoverageFlow,
  isLoggedInUser,
  updateCoverageRoomID,
  history,
}: WantInsuranceCheckProps) => {
  const onSelect = useOnSelect({
    updateStep,
  });
  const location = useLocation();
  const { spacing } = useEmotionTheme();
  const { t: tQuickmatchFlow132 } = useTranslation('quickmatch.flow132');

  const flags = useFlags();
  const { insuranceConfirmationStep, onboardingImprovementsMilestone1 } = flags;
  const isInsuranceConfirmationStepTreatment = isLDExperimentTreatment(
    flags,
    'insuranceConfirmationStep',
    {
      isUpdateCoverage: isUpdateCoverageFlow,
    }
  );
  const insuranceConfirmationProps = useInsuranceCoverageProps(
    flowId,
    isUpdateCoverageFlow,
    isLoggedInUser
  );

  useEffect(() => {
    const search = new URLSearchParams(location.search);
    const fromPage = (search.get('fromPage') as fromPageSource) || '';
    if (updateCoverageRoomID && fromPage) {
      const source = fromPageToUpdateCoverageSource[fromPage];
      trackUpdateCoverageSourceEvent(updateCoverageRoomID, source);
    }
  }, [location.search, updateCoverageRoomID]);

  const onSelectInsurance = useCallback(() => {
    if (onboardingImprovementsMilestone1) {
      switchFlowHelper(FLOW_232_CONSUMER_THERAPY_V2_BH, history, updateAndSave, {
        stepId: 15,
      });
    }
    onSelect('check', 15);
  }, [onSelect, onboardingImprovementsMilestone1, history, updateAndSave]);
  const onSelectOutOfPocket = useCallback(() => {
    onSelect('direct', 1);
  }, [onSelect]);

  if (onboardingImprovementsMilestone1) {
    return (
      <ThemedFlagsProvider versionKey="quickmatchOrIntakeBHFlow">
        <Styled.Container>
          <View style={{ marginBottom: spacing('space150') }}>
            <Image
              alt="hands holding flower"
              title="hands holding flower"
              source={handsFlowerInsurance}
              width={273}
              height={218}
              style={{ margin: 'auto' }}
            />
          </View>
          <View style={{ marginBottom: spacing('space150') }}>
            <TextDS variant="decorative/heading2Xl">
              {tQuickmatchFlow132('heading1', 'Therapy might be covered!', undefined)}
            </TextDS>
          </View>
          <TextDS variant="body">
            {tQuickmatchFlow132('heading2', "We're in-network with many insurers", undefined)}
          </TextDS>
          <StickyDrawerQM noBorder>
            <View style={{ marginBottom: spacing('space200') }}>
              <Button
                stretch
                variant="primary"
                text="Check insurance coverage"
                onPress={onSelectInsurance}
                dataQa="insuranceCheckQuestionCheck"
              />
            </View>
            <Button
              stretch
              variant="secondary"
              text="Pay out-of-pocket"
              onPress={onSelectOutOfPocket}
              dataQa="insuranceCheckQuestionOOP"
            />
          </StickyDrawerQM>
        </Styled.Container>
      </ThemedFlagsProvider>
    );
  }

  if (isInsuranceConfirmationStepTreatment) {
    switch (flowId) {
      case insuranceConfirmationStep.flowId:
        return (
          <Styled.Container>
            <Styled.Heading>{insuranceConfirmationProps.title}</Styled.Heading>
            <Questions questions={insuranceConfirmationProps.options} onSelect={onSelect} />
          </Styled.Container>
        );
      default:
        break;
    }
  }

  return (
    <Styled.Container>
      <Styled.Heading>{insuranceConfirmationProps.title}</Styled.Heading>
      <Questions questions={insuranceConfirmationProps.options} onSelect={onSelect} />
      <Styled.TipContainer>
        <StepTip tip="didYouKnow" tipTextContainerStyle={{ marginLeft: 6 }} />
      </Styled.TipContainer>
    </Styled.Container>
  );
};

export default withRouter(InsuranceCheckConfirmation);
