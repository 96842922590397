import {
  Button,
  View,
  useWindowWidthState,
  TextDS,
  Image as OriginalImage,
  IconList,
  Card,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { useTranslation } from '@talkspace/i18n';
import personStacking from '@/Assets/person-stacking.png';

import { YoureOnYourWayStep, UpdateStep } from '../../../Flows';
import StickyDrawerQM from '../../StickyDrawerQM';
import styled from '@/core/styled';

const Styled = {
  Container: styled(View)(({ isMobile }: { isMobile: boolean }) => {
    return {
      maxWidth: 350,
      minHeight: isMobile ? 'calc(100vh - 260px)' : undefined,
      justifyContent: 'space-between',
    };
  }),
};

interface Props {
  step: YoureOnYourWayStep;
  updateStep: UpdateStep;
}
const YoureOnYourWay = ({ step, updateStep }: Props) => {
  const { isMobile } = useWindowWidthState();
  const { t: tQuickmatchFlow232 } = useTranslation('quickmatch.flow232');
  const { spacing } = useEmotionTheme();
  return (
    <Styled.Container isMobile={isMobile}>
      <View align="center">
        <OriginalImage source={personStacking} alt="person happily stacking objects" />
        <TextDS variant="decorative/headingXl">
          {tQuickmatchFlow232(
            'stepYoureOnYourWay.heading1',
            'You’re on your way to matching with a therapist',
            undefined
          )}
        </TextDS>
        <View style={{ maxWidth: 295, marginTop: spacing('space300') }}>
          <Card forceIdleState>
            <IconList
              title="Next steps"
              items={[
                {
                  title: 'Check your coverage',
                  isCompleted: true,
                },
                {
                  title: 'Share your preferences',
                  description: 'We’ll use your preferences to find the right match for you',
                  isCurrent: true,
                },
                {
                  title: 'Complete an intake and get matched with a therapist',
                },
              ]}
            />
          </Card>
        </View>
      </View>
      <StickyDrawerQM noBorder>
        <Button
          text="Continue"
          stretch
          onPress={() => updateStep(step.buttonTarget)}
          dataQa="YoureOnYourWayContinueButton"
        />
      </StickyDrawerQM>
    </Styled.Container>
  );
};
export default YoureOnYourWay;
