import { QMFlow, EligibilityType } from './types';
import { initFlowConfig } from './utils';
import { FLOW_90_SELECT_SERVICE } from './Flow90';

/** AETNA EAP (America only) */
export const FLOW_44_B2B_EAP_AETNA = 44;

const flow: QMFlow = {
  flowId: FLOW_44_B2B_EAP_AETNA,
  flowConfig: {
    ...initFlowConfig,
    isB2B: true,
    isEAP: true,
    specificCountryCodesToSupport: ['US'],
    isTeen: true,
    eligibilityType: EligibilityType.organizationWithAddress,
    removeAsyncMessagingPrompt: true,
  },
  steps: [
    {
      id: 1,
      analyticsId: 56,
      category: 'oneFormEligibility',
      response_category_id: 9,
      optionalOrganization: false,
      skipRedirectToFlow: FLOW_90_SELECT_SERVICE,
    },
  ],
};

export default flow;
