import React, { forwardRef, useEffect, useState, useContext, PropsWithChildren } from 'react';
/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  StickyDrawerContextProvider,
  StickyDrawerStateContext,
  View,
  withContextProvider,
  useWindowWidthState,
  StickyDrawerParentContainer,
} from '@talkspace/react-toolkit';
import { getVWOWidgetsHeight } from 'ts-analytics/VWO/helpers';

import { ThemedFlagsProvider } from 'launchDarkly';
import styled, { EmotionStyle } from '../../core/styled';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { trackEvent } from '../../utils/analytics/events';
import { B2BForkResult, HomePageState } from '../HomePage';
import {
  isBHCopayStep,
  isPresentingProblemsStep,
  isRegisterWithVoucherStep,
  isSelectDynamicStep,
  isSelectStep,
} from '../../Helpers/stepTypeHelper';
import useBHOnboardingImprovements from '../../hooks/useBHOnboardingImprovements';

interface PageProps {
  isLoggedIn: boolean;
  hideHeaderMobile?: boolean | null;
  noFooter?: boolean | null;
  pageStyles?: EmotionStyle;
  stepCategory?: string;
  shouldAnchorFooter?: boolean;
  flowId?: number;
  step?: HomePageState['step'];
  headerRef?: React.RefObject<HTMLDivElement>;
  isRegistrationError?: boolean | null;
  isUpdateCoverageFlow?: boolean | null;
  skipCreditCard?: HomePageState['skipCreditCard'];
  b2bForkResult?: B2BForkResult | undefined;
  insurancePayer?: HomePageState['insurancePayer'];
  service?: HomePageState['service'];
}

const PageContentWrapperComponent = styled(View)<{
  windowHeight?: number;
  drawerHeight?: number | null;
}>(({ windowHeight, drawerHeight }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: windowHeight,
    overflowY: 'auto',
    position: 'relative',
    paddingBottom: drawerHeight || undefined,
  };
});

const shouldHideFooter = (
  step: HomePageState['step'] | undefined,
  isDrawerOpen: boolean,
  noFooter: boolean,
  isRegistrationError?: boolean | null,
  skipCreditCard?: HomePageState['skipCreditCard']
) => {
  if (noFooter) return true;
  if (!step) return false;
  if (step?.noFooter) return true;
  if (skipCreditCard && isBHCopayStep(step)) return true;
  if (isRegistrationError) return false;
  const isButtonsStep =
    isPresentingProblemsStep(step) || isSelectStep(step) || isSelectDynamicStep(step);
  const isRegistrationStep = isRegisterWithVoucherStep(step);
  if (isRegistrationStep) return true;
  if (isButtonsStep) return isDrawerOpen;
  return false;
};

const Page = forwardRef<HTMLDivElement, PropsWithChildren<PageProps>>((props, ref) => {
  const {
    isLoggedIn,
    pageStyles,
    hideHeaderMobile,
    noFooter,
    children,
    stepCategory,
    shouldAnchorFooter,
    flowId,
    step,
    headerRef,
    isRegistrationError,
    isUpdateCoverageFlow,
    skipCreditCard,
    b2bForkResult,
    insurancePayer,
    service,
  } = props;
  const { height } = useWindowWidthState();
  const [VWOWidgetsHeight, setVWOWidgetsHeight] = useState(getVWOWidgetsHeight());
  const windowHeight = height - VWOWidgetsHeight;
  const { isOpen: isDrawerOpen, drawerHeight } = useContext(StickyDrawerStateContext);
  const shouldSeeBHOnboardingImprovements = useBHOnboardingImprovements({
    b2bForkResult,
    insurancePayer,
    service,
  });
  useEffect(() => {
    // This effect hooks an observer to the HTML tag, VWO is pushing margin to the page to avoid
    // overlap with the top bar/footer, in this case we need to adjust the height of the element that manages the scroll

    const observer = new MutationObserver(() => setVWOWidgetsHeight(getVWOWidgetsHeight()));

    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

    return () => {
      observer.disconnect();
    };
  }, []);

  const hasVWOWidget = VWOWidgetsHeight !== 0;

  useEffect(() => {
    if (hasVWOWidget) {
      const VWOBar = document.querySelector<HTMLDivElement>('.vwo-notification-bar');
      if (VWOBar?.innerText) {
        trackEvent('See Promo Bar', {
          Promo: VWOBar.innerText,
        });
      }
    }
  }, [hasVWOWidget]);

  const showFooter = !shouldHideFooter(
    step,
    isDrawerOpen,
    !!noFooter,
    isRegistrationError,
    skipCreditCard
  );

  return (
    <ThemedFlagsProvider
      versionKey={shouldSeeBHOnboardingImprovements ? 'quickmatchOrIntakeBHFlow' : 'default'}
      isHighContrast
    >
      <View style={{ height: windowHeight, ...pageStyles }}>
        <Header
          stepCategory={stepCategory}
          hideHeaderMobile={hideHeaderMobile}
          ref={headerRef}
          flowId={flowId}
          isUpdateCoverageFlow={isUpdateCoverageFlow}
        />
        <PageContentWrapperComponent
          drawerHeight={drawerHeight}
          windowHeight={windowHeight}
          ref={ref}
        >
          {shouldSeeBHOnboardingImprovements ? (
            <StickyDrawerParentContainer>
              {children}
              {showFooter && (
                <Footer
                  isLoggedIn={isLoggedIn}
                  flowId={flowId}
                  stepCategory={stepCategory}
                  isPositionAbsolute={shouldAnchorFooter}
                  isUpdateCoverageFlow={isUpdateCoverageFlow}
                />
              )}
            </StickyDrawerParentContainer>
          ) : (
            <>
              {children}
              {showFooter && (
                <Footer
                  isLoggedIn={isLoggedIn}
                  flowId={flowId}
                  stepCategory={stepCategory}
                  isPositionAbsolute={shouldAnchorFooter}
                  isUpdateCoverageFlow={isUpdateCoverageFlow}
                />
              )}
            </>
          )}
        </PageContentWrapperComponent>
      </View>
    </ThemedFlagsProvider>
  );
});

Page.displayName = 'Page';

const PageWithProvider = withContextProvider(StickyDrawerContextProvider)(Page);

export default PageWithProvider;
