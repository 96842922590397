import { useMemo } from 'react';

import { Button, Link, TextDS, View, styled } from '@talkspace/react-toolkit';
import Accordion from '@talkspace/react-toolkit/src/designSystems/components/Accordion';
import { Trans, useTranslation } from '@talkspace/i18n';
import { trackEvent } from '../../utils/analytics/eventTracker';

const Styled = {
  Container: styled(View)(({ theme: { colorRoles } }) => {
    return {
      width: 375,
      textAlign: 'left',
      backgroundColor: colorRoles.surfaces.surfaceNeutralDefault,
    };
  }),
  HeadingContainer: styled(View)(({ theme: { spacing } }) => {
    return {
      padding: `${spacing('space200')}px ${spacing('space200')}px ${spacing('space100')}px`,
    };
  }),
  GetSupportContainer: styled(View)(({ theme: { spacing } }) => {
    return {
      padding: spacing('space200'),
    };
  }),
  GetSupportTitle: styled(TextDS)(({ theme: { spacing } }) => {
    return {
      marginBottom: spacing('space200'),
    };
  }),
};

const TeenConsenterFAQs = () => {
  const FAQ_DATA: {
    title: string;
    titleTranslationKey: string;
    content: string | React.ReactNode;
    contentTranslationKey: string;
    dataQa: string;
  }[] = useMemo(
    () => [
      {
        title: 'How is Talkspace free?',
        content:
          'Talkspace contracts with educational institutions and state/city entities to provide services free of charge to the end user. Talkspace services are generally funded through grants or budgets for youth mental health services.',
        titleTranslationKey: 'teenConsenterFAQs.howIsTalkspaceFree',
        contentTranslationKey: 'teenConsenterFAQs.howIsTalkspaceFreeDescription',
        dataQa: 'howIsTalkspaceFree',
      },
      {
        title: 'What is Talkspace?',
        content:
          'Talkspace is an online therapy service that connects individuals to a licensed mental health therapist. Members can exchange messages with their dedicated Talkspace therapists via text messaging within the secure Talkspace application and scheduled live sessions from the privacy and convenience of their computer or smartphone.',
        titleTranslationKey: 'teenConsenterFAQs.whatIsTalkspace',
        contentTranslationKey: 'teenConsenterFAQs.whatIsTalkspaceDescription',
        dataQa: 'whatIsTalkspace',
      },
      {
        title:
          'Who are Talkspace therapists? Are the therapists on Talkspace qualified to work with teenagers?',
        content:
          'Talkspace therapists are licensed professionals in social work, counseling, and marriage and family therapy and credentialed within the National Committee for Quality Assurance (NCQA) standards. Talkspace therapists have an average of 7-10 years of post-supervision experience providing therapy and have been trained to work with individuals of all ages, including teenagers. They have the expertise to address a wide range of mental health issues affecting young people.',
        titleTranslationKey: 'teenConsenterFAQs.whoAreTalkspaceTherapists',
        contentTranslationKey: 'teenConsenterFAQs.whoAreTalkspaceTherapistsDescription',
        dataQa: 'whoAreTalkspaceTherapists',
      },
      {
        title: 'Is Talkspace appropriate for my teen?',
        content: (
          <>
            Talkspace provides comprehensive support for a wide range of areas, encompassing but not
            limited to:
            <ul>
              <li>Depression</li>
              <li>Anxiety</li>
              <li>Post-Traumatic Stress Disorder (PTSD)</li>
              <li>Substance and alcohol use/abuse</li>
              <li>Eating disorders</li>
              <li>Bipolar disorder</li>
              <li>Obsessive-Compulsive Disorder (OCD)</li>
              <li>Stress management</li>
              <li>Relationship issues</li>
            </ul>
            Talkspace does not currently offer medication management or psychiatry to teens.
          </>
        ),
        titleTranslationKey: 'teenConsenterFAQs.isTalkspaceAppropriateForMyTeen',
        contentTranslationKey: 'teenConsenterFAQs.isTalkspaceAppropriateForMyTeenDescription',
        dataQa: 'isTalkspaceAppropriateForMyTeen',
      },
      {
        title: 'How can I be sure my teen’s data is protected?',
        content: (
          <>
            Talkspace protects your teen’s data in multiple ways:
            <ul>
              <li>
                The Talkspace platform is designed to meet the requirements of the Health Insurance
                Portability and Accountability Act (HIPAA). To learn more about HIPAA, visit
                <Link target="_blank" href="http://hhs.gov/hipaa/for-individuals/index.html">
                  hhs.gov/hipaa/for-individuals/index.html
                </Link>
                .
              </li>
              <li>
                Client data is encrypted both when sitting in the database or when being transmitted
                to your teen’s device.
              </li>
            </ul>
          </>
        ),
        titleTranslationKey: 'teenConsenterFAQs.isTeenDataProtected',
        contentTranslationKey: 'teenConsenterFAQs.isTeenDataProtectedDescription',
        dataQa: 'isTeenDataProtected',
      },
      {
        title: 'How can I find out if I’m eligible for free therapy?',
        content:
          'Talkspace therapy may be free to you through your city, school, or organization. Please check with your organization for more information.',
        titleTranslationKey: 'teenConsenterFAQs.ifEligibleForFreeTherapy',
        contentTranslationKey: 'teenConsenterFAQs.ifEligibleForFreeTherapyDescription',
        dataQa: 'ifEligibleForFreeTherapy',
      },
      {
        title: 'My teen is having trouble signing up under Talkspace. How can I fix this?',
        content: (
          <>
            Please submit a request
            <Link
              target="_blank"
              href="https://help.talkspace.com/hc/en-us/requests/new?ticket_form_id=29199506694427"
            >
              form
            </Link>
            to our customer support team, and they will promptly help you sign up for services!
          </>
        ),
        titleTranslationKey: 'teenConsenterFAQs.troubleSigningUp',
        contentTranslationKey: 'teenConsenterFAQs.troubleSigningUpDescription',
        dataQa: 'troubleSigningUp',
      },
      {
        title: 'Is parental consent required? If so, how does this work?',
        content:
          'Parental (or legal guardian) consent may be required depending on the laws of your state.  Your teen will be asked to provide contact information for you as a parent/guardian, and Talkspace will send you an email to sign a consent form.',
        titleTranslationKey: 'teenConsenterFAQs.isParentalConsentRequired',
        contentTranslationKey: 'teenConsenterFAQs.isParentalConsentRequiredDescription',
        dataQa: 'isParentalConsentRequired',
      },
      {
        title: 'Are there providers who speak other languages?',
        content:
          'Talkspace has therapists that speak, and can provide therapy in over 30 different languages.',
        titleTranslationKey: 'teenConsenterFAQs.providersSpeakOtherLanguages',
        contentTranslationKey: 'teenConsenterFAQs.providersSpeakOtherLanguagesDescription',
        dataQa: 'providersSpeakOtherLanguages',
      },
      {
        title: 'Can I contact my teen’s provider?',
        content: (
          <>
            Parents/guardians can reach out to the provider at any time if there is an urgent
            concern; however, the provider may not be able to share specific information about your
            teen without your teen’s consent. Your teen can give consent by completing the Privacy
            and Limitations form at
            <Link
              target="_blank"
              href="http://help.talkspace.com/hc/en-us/article_attachments/11096705277723"
            >
              help.talkspace.com/hc/en-us/article_attachments/11096705277723
            </Link>
            and emailing it to recordrequest@talkspace.com.
          </>
        ),
        titleTranslationKey: 'teenConsenterFAQs.canContactTeensProvider',
        contentTranslationKey: 'tennConsenterFAQs.canContactTeensProviderDescription',
        dataQa: 'canContactTeensProvider',
      },
      {
        title: 'What happens if my teen is in crisis?',
        content:
          'If your teen needs immediate assistance or in a life-threatening situation, Talkspace encourages you to call 988 or the local emergency/authorities number. Call or text 988 to reach the National Suicide Prevention Lifeline, a 24/7 free and confidential service. For students using Talkspace services, Talkspace has a crisis provider who is available 24/7. This number is listed throughout the app.',
        titleTranslationKey: 'teenConsenterFAQs.teenInCrisis',
        contentTranslationKey: 'teenConsenterFAQs.teenInCrisisDescription',
        dataQa: 'teenInCrisis',
      },
      {
        title: 'Is online therapy effective?',
        content:
          'Absolutely! Studies demonstrate messaging therapy can be as effective as traditional therapy for the treatment of the most common emotional and behavioral health issues such as depression, anxiety, chemical dependence, stress, PTSD, and more.',
        titleTranslationKey: 'teenConsenterFAQs.isOnlineTherapyEffective',
        contentTranslationKey: 'teenConsenterFAQs.isOnlineTherapyEffectiveDescription',
        dataQa: 'isOnlineTherapyEffective',
      },
      {
        title: 'How soon can my Teen get connected with a therapist?',
        content: 'Your teen will be connected to a Talkspace provider within 24-48 hours.',
        titleTranslationKey: 'teenConsenterFAQs.howSoonTeenConnected',
        contentTranslationKey: 'teenConsenterFAQs.howSoonTeenConnectedDescription',
        dataQa: 'howSoonTeenConnected',
      },
      {
        title: 'How do providers and teens communicate with one another?',
        content:
          'All communication, including live sessions, take place on the Talkspace platform. Teens have the freedom to send messages to their Talkspace provider 24/7.  Talkspace providers are typically available to respond five days a week, ensuring consistent support for your teen’s well-being. Providers will not contact a teen or parent by telephone (unless there is any emergency).  In-person sessions are not available.',
        titleTranslationKey: 'teenConsenterFAQs.howProvidersAndTeensCommunicate',
        contentTranslationKey: 'teenConsenterFAQs.howProvidersAndTeensCommunicateDescription',
        dataQa: 'howProvidersAndTeensCommunicate',
      },
    ],
    []
  );
  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');
  return (
    <Styled.Container>
      <Styled.HeadingContainer>
        <TextDS variant="headingLg">FAQs</TextDS>
      </Styled.HeadingContainer>
      {FAQ_DATA.map(({ title, content, titleTranslationKey, contentTranslationKey, dataQa }) => (
        <Accordion
          dataQa={dataQa}
          title={tQuickmatchCommon(titleTranslationKey, title, undefined)}
          onOpenSideEffect={() => {
            trackEvent('Teen Consenter FAQ Drawer Opened', {
              'FAQ Title': title,
            });
          }}
        >
          <TextDS variant="bodySm">
            <Trans t={tQuickmatchCommon} i18nKey={contentTranslationKey as any}>
              {content}
            </Trans>
          </TextDS>
        </Accordion>
      ))}
      <Styled.GetSupportContainer>
        <Styled.GetSupportTitle variant="headingMd">
          {tQuickmatchCommon(
            'teenConsenterFAQs.getSupportTitle',
            'Have more questions or need support?',
            undefined
          )}
        </Styled.GetSupportTitle>
        <Button
          text={tQuickmatchCommon(
            'teenConsenterFAQs.getSupportButtonText',
            'Get support',
            undefined
          )}
          variant="secondary"
          stretch
          onClick={() => {
            window.open('https://help.talkspace.com/hc/en-us', '_blank');
          }}
        />
      </Styled.GetSupportContainer>
    </Styled.Container>
  );
};

export default TeenConsenterFAQs;
