import NYCDOHLogo from './assets/NYCDOHLogo';
import SeattleLogo from './assets/SeattleLogo';
import BaltimoreCountyLogo from './assets/BaltimoreCountyLogo';
import CheckmarkCircle from './assets/CheckmarkCircle';
import { PartnerLogoSVGProps } from './types';

interface Props {
  partnerID: number;
  size: PartnerLogoSVGProps['size'];
}

const PartnerLogo = ({ partnerID, size }: Props) => {
  switch (partnerID) {
    case 356: // NYC DOH
      return <NYCDOHLogo size={size} />;
    case 451: // Seattle
      return <SeattleLogo size={size} />;
    case 372: // Baltimore County
      return <BaltimoreCountyLogo size={size} />;
    default:
      return <CheckmarkCircle size={size === 'small' ? 32 : 60} />;
  }
};

export default PartnerLogo;
