import { useEffect, useState } from 'react';
import {
  Button,
  ExtraHuge,
  Large,
  View,
  useWindowWidthState,
  ExperimentalComponent,
  Spinner,
} from '@talkspace/react-toolkit';
import { isLDExperimentTreatment } from 'launchDarkly/util';
import { useFlags } from 'launchDarkly/FlagsProvider';
import sessionStorage from '@/core/storage/sessionStorage';

import {
  FLOW_147_TEENSPACE_OPTIMIZED,
  TeenThankYouForSharingStep,
  UpdateStep,
} from '../../../Flows';
import styled from '@/core/styled';
import TeenSharingOnlineIllustration from '../../assets/TeenSharingOnlineIllustration';

const Styled = {
  Container: styled(View)(({ isMobile }: { isMobile: boolean }) => {
    return {
      maxWidth: 350,
      minHeight: isMobile ? 'calc(100vh - 260px)' : undefined,
      justifyContent: 'space-between',
    };
  }),
  Title: styled(ExtraHuge)({ margin: '32px 0 12px' }),
  ContinueButton: styled(Button)({
    marginBottom: 16,
    marginTop: 40,
    width: '100%',
  }),
};

interface Props {
  step: TeenThankYouForSharingStep;
  updateStep: UpdateStep;
  flowId: number;
}
const TeenThankYouForSharing = ({ step, updateStep, flowId }: Props) => {
  const { isMobile } = useWindowWidthState();
  const flags = useFlags();
  const isTeenspaceTreatment = isLDExperimentTreatment(flags, 'teenspaceCrOptimization', {
    flowID: flowId,
    teenspaceFlowID: FLOW_147_TEENSPACE_OPTIMIZED,
  });
  const [isLoading, setIsLoading] = useState(FLOW_147_TEENSPACE_OPTIMIZED === flowId);

  useEffect(() => {
    if (isLoading) {
      const timerID = setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      return () => clearTimeout(timerID);
    }
    return () => {};
  }, [isLoading]);

  useEffect(() => {
    if (flowId === FLOW_147_TEENSPACE_OPTIMIZED) {
      updateStep(step.buttonTarget, {
        isNoMatches: true,
      });
    }
  }, [updateStep, flowId, step.buttonTarget]);

  const { firstName } = JSON.parse(sessionStorage.getItem('TSQM_BasicInformation') || '{}');
  if (isLoading) {
    return (
      <View style={{ height: '100vh' }}>
        <View flex={1}>
          <Spinner isLoading />
        </View>
      </View>
    );
  }
  return (
    <Styled.Container isMobile={isMobile}>
      <View align="center">
        <ExperimentalComponent
          featureFlagBoolean={isTeenspaceTreatment}
          featureFlagVariant={isTeenspaceTreatment ? 'treatment' : 'control'}
          control={
            <>
              <TeenSharingOnlineIllustration />
              <Styled.Title>Thank you for sharing{firstName ? `, ${firstName}` : ''}</Styled.Title>
              <Large>
                You're one step closer to getting support. We'll use your answers to find a
                therapist that's right for you.
              </Large>
            </>
          }
          treatment={
            <>
              <Styled.Title style={{ margin: '0 0 12px' }}>
                Thank you for sharing{firstName ? `, ${firstName}` : ''}
              </Styled.Title>
              <Large variant="largeGrey950">
                Based on your answers, we can begin the search for a therapist that's right for you
              </Large>
              <TeenSharingOnlineIllustration style={{ marginTop: 32 }} />
            </>
          }
        />
      </View>
      <Styled.ContinueButton
        text="Continue"
        onPress={() =>
          updateStep(step.buttonTarget, {
            isNoMatches: true,
          })
        }
        dataQa="continueButton"
      />
    </Styled.Container>
  );
};
export default TeenThankYouForSharing;
