import { QMFlow, EligibilityType } from './types';
import { initFlowConfig } from './utils';

import { ServiceKeywords } from './Flow145';
import { FLOW_90_SELECT_SERVICE } from './Flow90';

/* Medicare Manual BH */
export const FLOW_146_B2B_BH_MANUAL_MEDICARE = 146;

const flow: QMFlow = {
  flowId: FLOW_146_B2B_BH_MANUAL_MEDICARE,
  flowConfig: {
    ...initFlowConfig,
    version: 1,
    isManualFlow: true,
    isB2B: true,
    isTeen: true,
    serviceKeywords: ServiceKeywords.manual,
    eligibilityType: EligibilityType.manual,
    secondaryInsurance: { enabled: true, separatePage: false },
  },
  steps: [
    {
      id: 1,
      analyticsId: 56,
      category: 'oneFormEligibility',
      response_category_id: 9,
      optionalOrganization: true,
      isGroupIdRequired: false,
      skipText: 'Continue without insurance',
      skipRedirectToFlow: FLOW_90_SELECT_SERVICE,
      buttonTarget: 2,
    },
  ],
};

export default flow;
