import { TextDS, View, styled } from '@talkspace/react-toolkit';

const Styled = {
  Container: styled(View)(() => {
    return {
      flexDirection: 'row',
    };
  }),
  Circle: styled(View)(({ theme: { colorRoles, spacing } }) => {
    return {
      width: 40,
      height: 40,
      borderRadius: '50%',
      backgroundColor: colorRoles.surfaces.decorativeGratitudeGreen,
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: spacing('space200'),
    };
  }),
  TextContainer: styled(View)(() => {
    return {
      alignItems: 'flex-start',
      flexDirection: 'column',
    };
  }),
  Description: styled(TextDS)(({ theme: { spacing } }) => {
    return { marginTop: spacing('space100'), width: 300, textAlign: 'left' };
  }),
};

const StepDescriptionItem = ({
  number,
  title,
  description,
}: {
  number: number;
  title: string;
  description: string;
}) => (
  <Styled.Container>
    <Styled.Circle>
      <TextDS variant="decorative/headingLg">{number}</TextDS>
    </Styled.Circle>
    <Styled.TextContainer>
      <TextDS variant="decorative/headingLg">{title}</TextDS>
      <Styled.Description variant="bodySm">{description}</Styled.Description>
    </Styled.TextContainer>
  </Styled.Container>
);

export default StepDescriptionItem;
