import { useEffect } from 'react';
import {
  Button,
  RHFInput,
  RHFDateInput,
  RHFSelect,
  useEmotionTheme,
  ExtraHuge,
  Small,
  Large,
  Link,
  View,
  OptionType,
  StickyDrawer,
  TextDS,
} from '@talkspace/react-toolkit';
import { useWatch, useFormContext } from 'react-hook-form';
import {
  SESSION_STORAGE_MEMBER_DETAILS_KEY,
  getSessionStorageValuesFromJson,
} from 'ts-frontend/utils';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { Trans, useTranslation } from '@talkspace/i18n';
import useMemberIDGuidance from '@/hooks/useMemberIDGuidance';
import useBHOnboardingImprovements from '../../../hooks/useBHOnboardingImprovements';
import { countries } from '@/Helpers/locales';
import styled from '@/core/styled';
import { stateOptions } from './util';
import { useHomePageState } from '../../HomePage';
import { OneFormEligibilityFieldNames } from '../../OneFormEligibility/types';
import { EMPLOYEE_OPTIONS_BH_QUICK_ELIGIBILITY } from '../../OneFormEligibility/util';
import { EligibilityServiceFormData } from '@/hooks/useEligibilityService/types';
import { DiscoveryServiceFormData } from '@/hooks/useDiscoveryService/types';
import { isTeensFlow } from '../../../Helpers/flowsHelper';
import { trackEvent } from '@/utils/analytics/eventTracker';

export const AA_TOOLTIP_STYLE = {
  width: 23,
  height: 15,
  alignItems: 'flex-end',
  display: 'flex',
  marginBottom: 0,
  paddingBottom: 6,
};

export const Styled = {
  Heading: styled(ExtraHuge)(() => {
    return {
      marginBottom: 10,
    };
  }),
  Subheading: styled(Large)(({ theme: { colors } }) => {
    return {
      fontSize: 16,
      color: colors.darkGray,
      marginBottom: 32,
    };
  }),
  View: styled(View)(() => {
    return {
      maxWidth: 430,
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    };
  }),
  Form: styled.form(() => {
    return {
      maxWidth: 335,
      width: '100%',
      outline: 'none',
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      alignItems: 'stretch',
      textAlign: 'center',
    };
  }),
  FieldWrapper: styled(View)({
    width: '100%',
    maxWidth: 165,
    '@media(max-width: 350px)': {
      maxWidth: 335,
    },
  }),
  Row: styled(View)({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 335,
    '@media(max-width: 350px)': {
      display: 'inline-block',
    },
  }),
  Footer: styled(View)({
    maxWidth: 335,
    marginTop: 25,
  }),
  Error: styled(View)(({ theme: { colors } }) => {
    return {
      display: 'block',
      maxWidth: 335,
      marginTop: 12,
      padding: 20,
      backgroundColor: colors.permaProvincialPink,
      borderRadius: 9,
      color: colors.permaOuterSpaceNew,
      textAlign: 'left',
      '> a': {
        color: colors.permaErrorRed,
      },
    };
  }),
};

export interface AdditionalFieldsParams {
  setValue: (key: string, value: string | OptionType) => void;
  isManualSignUpFlow: boolean;
}

interface EligibilityFormParams {
  data: EligibilityServiceFormData | DiscoveryServiceFormData;
  handleOnSubmit: (formData: EligibilityServiceFormData | DiscoveryServiceFormData) => void;
  isSubmitting: boolean;
  done: boolean;
  referralSource: string;
  setReferralSource: (referralSource: string) => void;
  additionalFields?: (props: AdditionalFieldsParams) => JSX.Element[];
  insuranceFieldTop?: (props: AdditionalFieldsParams) => JSX.Element;
  isEditInsuranceInfoScreen?: boolean;
  partnerFlowID?: number;
}

const EligibilityForm = ({
  handleOnSubmit,
  data,
  isSubmitting,
  done,
  referralSource,
  setReferralSource,
  additionalFields,
  insuranceFieldTop,
  partnerFlowID,
  isEditInsuranceInfoScreen,
}: EligibilityFormParams) => {
  const { colors } = useEmotionTheme();
  const { bhInsuranceEligibilityError, updateCoveragePart2 } = useFlags();
  const { t: tQuickmatchFlow132 } = useTranslation('quickmatch.flow132');
  const isBHOnboardingImprovements = useBHOnboardingImprovements();
  const { insurancePayer } = useHomePageState();
  const memberIDGuidance = useMemberIDGuidance(insurancePayer?.partnerID || '');

  const formContext = useFormContext();
  const { handleSubmit, setValue } = formContext;

  const isUnder13 = formContext.formState.errors.dob?.type === 'under13';
  const isManualSignUpFlow = referralSource === 'manualSignUp';

  const state = useWatch({
    name: 'state',
  });
  useEffect(() => {
    const { referralSource: sessionReferralSource } = getSessionStorageValuesFromJson(
      SESSION_STORAGE_MEMBER_DETAILS_KEY,
      ['referralSource']
    );
    if (sessionReferralSource?.value) {
      setReferralSource(sessionReferralSource.value);
    }
  }, [setReferralSource]);

  useEffect(() => {
    if (bhInsuranceEligibilityError !== undefined) {
      trackEvent('TS Experiment Session', {
        experimentName: 'bh-insurance-eligibility-error',
        variantName: bhInsuranceEligibilityError ? 'treatment' : 'control',
      });
    }
  }, [bhInsuranceEligibilityError]);

  return (
    <Styled.Form onSubmit={handleSubmit(handleOnSubmit)}>
      {isBHOnboardingImprovements ? (
        <TextDS variant="heading2xl">
          {tQuickmatchFlow132('step101.titleText', 'Check your coverage', undefined)}
        </TextDS>
      ) : (
        <Styled.Heading>
          {tQuickmatchFlow132('step101.titleText', 'Check your coverage', undefined)}
        </Styled.Heading>
      )}
      {isBHOnboardingImprovements ? (
        <TextDS variant="body" colorRole="textSubtle">
          {tQuickmatchFlow132(
            'step101.subtitleText',
            `We’ll use this to determine your session cost`,
            undefined
          )}
        </TextDS>
      ) : (
        <Styled.Subheading>
          {tQuickmatchFlow132(
            'step101.subtitleText',
            `We’ll use this to determine your session cost`,
            undefined
          )}
        </Styled.Subheading>
      )}

      {isEditInsuranceInfoScreen &&
        insuranceFieldTop &&
        insuranceFieldTop({ setValue, isManualSignUpFlow })}
      <Styled.Row>
        <Styled.FieldWrapper>
          <RHFInput
            fieldName="firstName"
            label={tQuickmatchFlow132(
              updateCoveragePart2.variant === 'treatment'
                ? 'step101.inputLabelFirstName'
                : 'step101.inputLabelPatientFirstName',
              updateCoveragePart2.variant === 'treatment' ? 'First name' : 'Patient first name',
              undefined
            )}
            placeholder={
              isBHOnboardingImprovements
                ? undefined
                : tQuickmatchFlow132(
                    'step101.inputPlaceholderPatientFirstName',
                    'First name',
                    undefined
                  )
            }
            isRequired
            data-qa="first-name-input"
            isDisabled={isManualSignUpFlow && !!data.firstName}
          />
        </Styled.FieldWrapper>
        <Styled.FieldWrapper>
          <RHFInput
            fieldName="lastName"
            label={tQuickmatchFlow132(
              updateCoveragePart2.variant === 'treatment'
                ? 'step101.inputLabelLastName'
                : 'step101.inputLabelPatientLastName',
              updateCoveragePart2.variant === 'treatment' ? 'Last name' : 'Patient last name',
              undefined
            )}
            placeholder={
              isBHOnboardingImprovements
                ? undefined
                : tQuickmatchFlow132(
                    'step101.inputPlaceholderPatientLastName',
                    'Last name',
                    undefined
                  )
            }
            isRequired
            data-qa="last-name-input"
            isDisabled={isManualSignUpFlow && !!data.lastName}
          />
        </Styled.FieldWrapper>
      </Styled.Row>
      <RHFDateInput
        fieldName="dob"
        label={tQuickmatchFlow132('step101.inputLabelDOB', 'Date of birth', undefined)}
        isRequired
        data-qa="dob-input"
        containerStyle={{ marginBottom: 12 }}
        isDisabled={isManualSignUpFlow && !!data.dob}
      />
      {isBHOnboardingImprovements && (
        <RHFInput
          fieldName="memberID"
          label="Benefits Number"
          tooltip={memberIDGuidance.tooltip}
          placeholder={memberIDGuidance.hint}
          hint={memberIDGuidance.description}
          isRequired
          data-qa="member-id-input"
          onChange={(e) => {
            const newValue = e.target.value.replace(/\s/gi, '').toLowerCase();
            if (newValue !== e.target.value) {
              setValue('memberID', newValue);
            }
          }}
          labelStyle={{ alignSelf: 'flex-end' }}
          tooltipStyle={AA_TOOLTIP_STYLE}
          isDisabled={isManualSignUpFlow && 'memberID' in data && !!data.memberID}
        />
      )}
      <RHFSelect
        fieldName="state"
        label={tQuickmatchFlow132(
          updateCoveragePart2.variant === 'treatment'
            ? 'step101.inputLabelState'
            : 'step101.inputLabelLocation',
          updateCoveragePart2.variant === 'treatment' ? 'State' : 'Location',
          undefined
        )}
        options={stateOptions}
        allowMobileSearch
        registerOptionValue
        placeholder={tQuickmatchFlow132(
          'step101.inputPlaceholderLocation',
          updateCoveragePart2.variant === 'treatment' ? 'Select' : 'Select your location',
          undefined
        )}
        tooltip={tQuickmatchFlow132(
          'step101.inputTooltipLocation',
          'Select the location associated with your insurance.',
          undefined
        )}
        labelStyle={{ paddingBottom: 4 }}
        labelContainerStyle={{ alignItems: 'flex-end', marginBottom: 0 }}
        tooltipProps={{
          messageStyle: { marginRight: 10 },
          buttonStyle: AA_TOOLTIP_STYLE,
        }}
        isDisabled={isManualSignUpFlow && !!data.state}
      />
      {state === 'other' && (
        <RHFSelect
          fieldName="country"
          label={tQuickmatchFlow132('step101.inputLabelCountry', 'Country', undefined)}
          options={countries}
          allowMobileSearch
          registerOptionValue
          placeholder={tQuickmatchFlow132(
            'step101.inputPlaceholderCountry',
            'Select your country',
            undefined
          )}
        />
      )}
      {isBHOnboardingImprovements && (
        <RHFSelect
          fieldName={OneFormEligibilityFieldNames.employeeRelation}
          label={
            updateCoveragePart2.variant === 'treatment'
              ? 'Relationship to insurance holder'
              : 'Primary insurance holder'
          }
          options={EMPLOYEE_OPTIONS_BH_QUICK_ELIGIBILITY}
          tooltip="What is your relationship to the primary person who owns the insurance policy? If you are the primary insurance holder, choose “Self.”"
          tooltipLabel="Employee relation tooltip"
        />
      )}
      {additionalFields ? additionalFields({ setValue, isManualSignUpFlow }) : null}
      {isUnder13 && (
        <Styled.Error>
          <Trans t={tQuickmatchFlow132} i18nKey="step101.underageErrorMessage">
            Talkspace cannot provide service to individuals under the age of{' '}
            {{ minimum_age: partnerFlowID && isTeensFlow(partnerFlowID) ? 13 : 18 }} at this time.
            If you or anyone you know are in a crisis or may be in danger, please use the following{' '}
            <Link href="https://google.com">resources</Link> to get immediate help.
          </Trans>
        </Styled.Error>
      )}
      {isBHOnboardingImprovements ? (
        <>
          <Styled.Footer>
            <TextDS variant="bodySm" colorRole="textSubtle">
              {tQuickmatchFlow132(
                'step101.footerText',
                'Talkspace is committed to protecting your privacy and follows HIPAA, state and federal laws.',
                undefined
              )}
            </TextDS>
          </Styled.Footer>
          <StickyDrawer>
            <Button
              text={tQuickmatchFlow132('step101.buttonText', 'Continue', undefined)}
              roundedFocusStyle
              disabled={done}
              isLoading={isSubmitting}
              primaryColor={colors.green}
              dataQa="shortEligibilityCTA"
              style={{ alignSelf: 'center', width: '100%', fontWeight: 'bold' }}
            />
          </StickyDrawer>
        </>
      ) : (
        <>
          <Button
            text={tQuickmatchFlow132('step101.buttonText', 'Continue', undefined)}
            roundedFocusStyle
            disabled={done}
            isLoading={isSubmitting}
            primaryColor={colors.green}
            dataQa="shortEligibilityCTA"
            style={{ alignSelf: 'center', width: '100%', fontWeight: 'bold' }}
          />
          <Styled.Footer>
            <Small>
              {tQuickmatchFlow132(
                'step101.footerText',
                'Talkspace is committed to protecting your privacy and follows HIPAA, state and federal laws.',
                undefined
              )}
            </Small>
          </Styled.Footer>
        </>
      )}
    </Styled.Form>
  );
};

export default EligibilityForm;
