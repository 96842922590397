import { useCallback, useEffect, useState } from 'react';
import { Spinner } from '@talkspace/react-toolkit';
import { SessionModality } from 'ts-frontend/types';
import { mixpanelSessionModalities } from 'ts-analytics/mixpanel/helpers';
import { useHistory } from '@/core/routerLib';
import { Response } from '@/Components/HomePage/types';
import { modalitiesIconAndText } from '@/Helpers/preferredModalityHelper';
import SelectModality from './SelectModality';
import WeRecommend from '@/Components/WeRecommend';
import AsyncSessionCarousel from './AsyncSessionCarousel';
import { trackSelectModalityPreference } from '@/utils/analytics/events';
import useRemoveMessagingNudge from '@/hooks/useRemoveMessagingNudge';
import { useAllowModalitySelection, useForceVideoModality } from './hooks';
import { SelectSessionModalityProps } from './types';
import useBackButton from '../../StepLayout/hooks/useBackButton';

const SelectSessionModality = ({
  allowedModalities,
  step,
  flowConfig,
  updateStep,
  b2bForkResult,
  accountType,
  clientState,
  isLoggedInUser,
}: SelectSessionModalityProps) => {
  const allowModalitySelection = useAllowModalitySelection({
    clientState,
  });

  const [isLoading, setIsLoading] = useState(true);
  const [userSelectedLiveSession, setUserSelectedLiveSession] = useState(false);

  const history = useHistory();

  const removeMessagingNudge = useRemoveMessagingNudge(
    clientState,
    b2bForkResult,
    accountType,
    flowConfig,
    true
  );
  const startScreen = removeMessagingNudge ? 'select-modality' : 'recommend-messaging';
  const { setOverriddenBackButtonBehavior } = useBackButton(step);
  const [currentScreen, setCurrentScreen] = useState<
    'recommend-messaging' | 'select-modality' | 'async-session-carousel'
  >(startScreen);
  const [isMessagingAvailable, setIsMessagingAvailable] = useState<boolean | undefined>(undefined);
  const [isMessagingOnly, setIsMessagingOnly] = useState<boolean | undefined>(undefined);
  const [isFromSelectModalityScreen, setIsFromSelectModalityScreen] = useState(false);

  const onBookLiveSessionClick = () => {
    setCurrentScreen('select-modality');
    setUserSelectedLiveSession(true);
    setIsFromSelectModalityScreen(false);
  };

  const onStartWithMessagingPress = () => {
    setCurrentScreen('async-session-carousel');
    setIsFromSelectModalityScreen(false);
    setUserSelectedLiveSession(false);
    trackSelectModalityPreference({
      preferenceSelected: 'Async Chat',
      isLoggedInUser,
    });
  };

  const submitSelection = async (modality: SessionModality) => {
    const answer: Response = {
      payfirst_step_prompt: 'How do you want to start therapy?',
      response_value: modality,
      response_prompt: modalitiesIconAndText[modality].displayTitle,
      response_self_serve: {
        field: 'sessionModality',
        value: modality,
      },
    };
    await updateStep(step.buttonTarget, answer);
  };

  const onContinuePress = async (modality: SessionModality) => {
    trackSelectModalityPreference({
      preferenceSelected: mixpanelSessionModalities[modality],
      isLoggedInUser,
    });
    setIsFromSelectModalityScreen(true);
    if (modality === 'messaging') {
      setCurrentScreen('async-session-carousel');
    } else {
      await submitSelection(modality);
    }
  };

  useEffect(() => {
    // set custom behavior to back button in HomePage
    switch (currentScreen) {
      case 'select-modality':
        if (isMessagingAvailable) {
          if (currentScreen === startScreen) {
            setOverriddenBackButtonBehavior(undefined);
          } else {
            setOverriddenBackButtonBehavior(() => setCurrentScreen(startScreen));
          }
        }
        break;
      case 'async-session-carousel':
        if (!isMessagingOnly) {
          setOverriddenBackButtonBehavior(() => {
            if (isFromSelectModalityScreen) {
              setCurrentScreen('select-modality');
            } else {
              setCurrentScreen(startScreen);
            }
          });
        }
        break;
      default:
        break;
    }
  }, [
    currentScreen,
    isFromSelectModalityScreen,
    isMessagingAvailable,
    isMessagingOnly,
    setOverriddenBackButtonBehavior,
    startScreen,
    history,
  ]);

  useEffect(() => {
    if (allowedModalities) {
      setIsMessagingAvailable(
        !!allowedModalities.find((modality) => modality.name === 'messaging')
      );
    }
  }, [allowedModalities]);

  useEffect(() => {
    setIsMessagingOnly(isMessagingAvailable === true && allowedModalities?.length === 1);
  }, [allowedModalities?.length, isMessagingAvailable]);

  useEffect(() => {
    setIsLoading(true);
    if (isMessagingAvailable === false) {
      setCurrentScreen('select-modality');
    } else if (isMessagingOnly) {
      setCurrentScreen('async-session-carousel');
    }
    setIsLoading(false);
  }, [isMessagingAvailable, isMessagingOnly]);

  useForceVideoModality({
    allowedModalities,
    clientState,
    onContinuePress,
  });

  const goBackToSelectSessionModality = useCallback(() => setCurrentScreen('select-modality'), []);

  if (isLoading) return <Spinner isLoading />;

  return (
    <>
      {currentScreen === 'select-modality' && allowModalitySelection && (
        <SelectModality
          clientState={clientState}
          b2bForkResult={b2bForkResult}
          accountType={accountType}
          allowedModalities={allowedModalities}
          flowConfig={flowConfig}
          onContinuePress={onContinuePress}
          userSelectedLiveSession={userSelectedLiveSession}
        />
      )}
      {currentScreen === 'recommend-messaging' && (
        <WeRecommend
          onBookALiveSessionPress={onBookLiveSessionClick}
          onStartWithMessagingPress={onStartWithMessagingPress}
        />
      )}
      {currentScreen === 'async-session-carousel' && (
        <AsyncSessionCarousel
          step={step}
          onConfirmSessionButtonPress={() => submitSelection('messaging')}
          goBackToSelectSessionModality={goBackToSelectSessionModality}
        />
      )}
    </>
  );
};

export default SelectSessionModality;
