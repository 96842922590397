/* eslint-disable import/order */
/* eslint-disable import/no-cycle */
import Flow2 from './Flow2';
import Flow7, { FLOW_7_B2B_COPAY_GENERIC } from './Flow7';
import Flow9 from './Flow9';
import Flow11 from './Flow11';
import Flow19 from './Flow19';
import Flow24 from './Flow24';
import Flow25 from './Flow25';
import Flow28 from './Flow28';
import Flow32 from './Flow32';
import Flow33 from './Flow33';
import Flow41 from './Flow41';
import Flow43 from './Flow43';
import Flow44 from './Flow44';
import Flow46 from './Flow46';
import Flow48 from './Flow48';
import Flow59 from './Flow59';
import Flow60 from './Flow60';
import Flow62 from './Flow62';
import Flow63 from './Flow63';
import Flow64 from './Flow64';
import Flow65 from './Flow65';
// This import in particular needs user interaction, therefore, "safe" to be a cycle
import Flow66 from './Flow66';
import Flow67 from './Flow67';
import Flow68 from './Flow68';
import Flow69 from './Flow69';
import Flow70 from './Flow70';
import Flow71 from './Flow71';
import Flow72 from './Flow72';
import Flow73 from './Flow73';
import Flow74 from './Flow74';
import Flow75 from './Flow75';
import Flow76 from './Flow76';
import Flow78 from './Flow78';
import Flow79 from './Flow79';
import Flow80 from './Flow80';
import Flow81 from './Flow81';
import Flow82 from './Flow82';
import Flow83 from './Flow83';
import Flow84 from './Flow84';
import Flow85 from './Flow85';
import Flow86 from './Flow86';
import Flow89 from './Flow89';
import Flow90, { FLOW_90_SELECT_SERVICE } from './Flow90';
import Flow91 from './Flow91';
import Flow92 from './Flow92';
import Flow93 from './Flow93';
import Flow94 from './Flow94';
import Flow95 from './Flow95';
import Flow96 from './Flow96';
import Flow97 from './Flow97';
import Flow98 from './Flow98';
import Flow100 from './Flow100';
import Flow101 from './Flow101';
import Flow102 from './Flow102';
import Flow103 from './Flow103';
import Flow104 from './Flow104';
import Flow105 from './Flow105';
import Flow106 from './Flow106';
import Flow110 from './Flow110';
import Flow111 from './Flow111';
import Flow112 from './Flow112';
import Flow113 from './Flow113';
import Flow115 from './Flow115';
import Flow116 from './Flow116';
import Flow117 from './Flow117';
import Flow118 from './Flow118';
import Flow119 from './Flow119';
import Flow120 from './Flow120';
import Flow200 from './Flow200';
import Flow121 from './Flow121';
import Flow122 from './Flow122';
// eslint-disable-next-line import/no-cycle
import Flow123 from './Flow123';
import Flow124 from './Flow124';
import Flow125 from './Flow125';
import Flow126 from './Flow126';
import Flow127 from './Flow127';
import Flow128 from './Flow128';
import Flow129 from './Flow129';
import Flow130 from './Flow130';
import Flow131 from './Flow131';
import Flow132 from './Flow132';
import Flow133 from './Flow133';
import Flow134 from './Flow134';
import Flow135 from './Flow135';
import Flow136 from './Flow136';
import Flow137 from './Flow137';
import Flow138, { FLOW_138_B2B_VOUCHER_GENERIC_V2 } from './Flow138';
import Flow139 from './Flow139';
import Flow140, { FLOW_140_TEEN_THERAPY } from './Flow140';
import Flow141 from './Flow141';
import Flow142 from './Flow142';
import Flow143 from './Flow143';
import Flow144 from './Flow144';
import Flow145 from './Flow145';
import Flow146 from './Flow146';
import Flow147 from './Flow147';
import Flow148 from './Flow148';
import Flow149 from './Flow149';
import Flow150 from './Flow150';
import Flow151 from './Flow151';
import Flow152 from './Flow152';
import Flow153 from './Flow153';
import Flow154 from './Flow154';
import Flow155 from './Flow155';
import Flow156 from './Flow156';
import Flow232 from './Flow232';
import Flow157 from './Flow157';
import Flow158 from './Flow158';
import Flow167 from './Flow167';

import { FlowConfig, FlowStep, QMFlow } from './types';
import { AllLaunchDarklyExperimentNames, ConditionalResponse } from 'ts-frontend/types';

/**
 * Flow names patterns:
 * FLOW_(number)_CONSUMER_[any description]
 * FLOW_(number)_B2B_(BH/EAP/COPAY/VOUCHER)_(validation: MANUAL/TRIZETTO/FILE)_[company]
 */
export { FLOW_9_B2B_EAP_GENERIC } from './Flow9';
export { FLOW_11_B2B_VOUCHER_GOOGLE } from './Flow11';
export { FLOW_19_B2B_EAP_HUMANA } from './Flow19';
export { FLOW_24_B2B_BH_MANUAL_PHP } from './Flow24';
export { FLOW_25_B2B_BH_MANUAL_PREMERA } from './Flow25';
export { FLOW_28_B2B_COPAY_TRIZETTO_PREMERA } from './Flow28';
export { FLOW_32_B2B_COPAY_TRIZETTO_OPTUM } from './Flow32';
export { FLOW_33_B2B_BH_FILE_GATORCARE } from './Flow33';
export { FLOW_41_B2B_EAP_HUMANA_TEEN } from './Flow41';
export { FLOW_43_B2B_BH_MANUAL_OPTUM } from './Flow43';
export { FLOW_44_B2B_EAP_AETNA } from './Flow44';
export { FLOW_46_CONSUMER_TRAIL } from './Flow46';
export { FLOW_48_CONSUMER } from './Flow48';
export { FLOW_59_B2B_BH_TRIZETTO_CIGNA } from './Flow59';
export { FLOW_60_B2B_BH_MANUAL_CIGNA } from './Flow60';
export { FLOW_62_B2B_EAP_OPTUM } from './Flow62';
export { FLOW_63_CONSUMER_POST_SURVEY_WIZARD } from './Flow63';
export { FLOW_64_CONSUMER_ANXIETY_ASSESSMENT } from './Flow64';
export { FLOW_65_CONSUMER_DEPRESSION_ASSESSMENT } from './Flow65';
// This import in particular needs user interaction, therefore, "safe" to be a cycle
export { FLOW_66_CONSUMER_THERAPY } from './Flow66';
export { FLOW_67_CONSUMER_COUPLES_THERAPY } from './Flow67';
export { FLOW_68_CONSUMER_PSYCHIATRY } from './Flow68';
export { FLOW_69_B2B_LOCATION_ELIGIBILITY } from './Flow69';
export { FLOW_70_B2B_BH_TRIZETTO_AETNA } from './Flow70';
export { FLOW_71_B2B_BH_MANUAL_AETNA } from './Flow71';
export { FLOW_72_CONSUMER_BI_POLAR_ASSESSMENT } from './Flow72';
export { FLOW_73_CONSUMER_OCD_ASSESSMENT } from './Flow73';
export { FLOW_74_CONSUMER_PTSD_ASSESSMENT } from './Flow74';
export { FLOW_75_CONSUMER_SOCIAL_ANXIETY_ASSESSMENT } from './Flow75';
export { FLOW_76_BH_FILE_FAMILY_MEDICAL_CARE } from './Flow76';
export { FLOW_78_B2B_EAP_CIGNA } from './Flow78';
export { FLOW_79_CONSUMER_PANIC_DISORDER_ASSESSMENT } from './Flow79';
export { FLOW_80_CONSUMER_BORDERLINE_PERSONALITY_ASSESSMENT } from './Flow80';
export { FLOW_81_CONSUMER_INSOMNIA_ASSESSMENT } from './Flow81';
export { FLOW_82_DTE_FILE_FAMILY_MEDICAL_CARE } from './Flow82';
export { FLOW_83_DTE_FILE_GATORCARE } from './Flow83';
export { FLOW_84_B2B_BH_TRIZETTO_REGENCE } from './Flow84';
export { FLOW_85_B2B_BH_MANUAL_REGENCE } from './Flow85';
export { FLOW_86_B2B_BH_TRIZETTO_CIGNA_ALLEGIANCE } from './Flow86';
export { FLOW_89_B2B_EAP } from './Flow89';
export { FLOW_90_SELECT_SERVICE } from './Flow90';
export { FLOW_91_B2B_COPAY_TRIZETTO_OPTUM_UMR } from './Flow91';
export { FLOW_92_B2B_BH_TRIZETTO_RGA } from './Flow92';
export { FLOW_93_B2B_BH_MANUAL_RGA } from './Flow93';
export { FLOW_94_B2B_BH_TRIZETTO_HMA } from './Flow94';
export { FLOW_95_B2B_BH_MANUAL_UMR } from './Flow95';
export { FLOW_96_B2B_BH_MANUAL_HMA } from './Flow96';
export { FLOW_97_TEEN_THERAPY } from './Flow97';
export { FLOW_98_B2B_EAP_BEACON } from './Flow98';
export { FLOW_100_ELIGIBILITY_QUESTIONS_THERAPY } from './Flow100';
export { FLOW_101_ELIGIBILITY_QUESTIONS_COUPLES_THERAPY } from './Flow101';
export { FLOW_102_ELIGIBILITY_QUESTIONS_PSYCHIATRY } from './Flow102';
export { FLOW_103_ELIGIBILITY_QUESTIONS_TEEN_THERAPY } from './Flow103';
export { FLOW_104_B2B_FIRST_CHOICE_BH_MANUAL } from './Flow104';
export { FLOW_105_B2B_FIRST_CHOICE_BH } from './Flow105';
export { FLOW_106_CONSUMER_WALMART } from './Flow106';
export { FLOW_110_B2B_BH_TRIZETTO_GATORCARE } from './Flow110';
export { FLOW_111_B2B_BH_MANUAL_GATORCARE } from './Flow111';
export { FLOW_112_B2B_BH_TRIZETTO_BEACON } from './Flow112';
export { FLOW_113_B2B_BH_MANUAL_BEACON } from './Flow113';
export { FLOW_115_B2B_BH_TRIZETTO_SUREST } from './Flow115';
export { FLOW_116_B2B_BH_MANUAL_SUREST } from './Flow116';
export { FLOW_117_B2B_BH_TRIZETTO_MERITAIN } from './Flow117';
export { FLOW_118_B2B_BH_MANUAL_MERITAIN } from './Flow118';
export { FLOW_119_B2B_BH_MANUAL_TYSON } from './Flow119';
export { FLOW_120_B2B_BH_TRIZETTO_TYSON } from './Flow120';
export { FLOW_200_CONSUMER_THERAPY_EXPERIMENT_HUB } from './Flow200';
export { FLOW_121_B2B_BH_TRIZETTO_EMBLEM } from './Flow121';
export { FLOW_122_B2B_BH_MANUAL_EMBLEM } from './Flow122';
// eslint-disable-next-line import/no-cycle
export { FLOW_123_CONSUMER_THERAPY } from './Flow123';
export { FLOW_124_B2B_BH_TRIZETTO_UNICARE } from './Flow124';
export { FLOW_125_B2B_BH_MANUAL_UNICARE } from './Flow125';
export { FLOW_126_B2B_BH_TRIZETTO_PROVIDENCE } from './Flow126';
export { FLOW_127_B2B_BH_MANUAL_PROVIDENCE } from './Flow127';
export { FLOW_128_NYC_TEENS } from './Flow128';
export { FLOW_129_B2B_TEEN_POSTAL_CODE } from './Flow129';
export { FLOW_130_B2B_BH_TRIZETTO_ANTHEM } from './Flow130';
export { FLOW_131_B2B_BH_MANUAL_ANTHEM } from './Flow131';
export { FLOW_132_CONSUMER_THERAPY } from './Flow132';
export { FLOW_133_ISRAEL } from './Flow133';
export { FLOW_134_B2B_BH_TRIZETTO_ASURIS } from './Flow134';
export { FLOW_135_B2B_BH_MANUAL_ASURIS } from './Flow135';
export { FLOW_136_B2B_BH_TRIZETTO_BRIDGESPAN } from './Flow136';
export { FLOW_137_B2B_BH_MANUAL_BRIDGESPAN } from './Flow137';
export { FLOW_138_B2B_VOUCHER_GENERIC_V2 } from './Flow138';
export { FLOW_139_TEMP_MEDICARE } from './Flow139';
export { FLOW_140_TEEN_THERAPY } from './Flow140';
export { FLOW_141_NEW_USER_HOLD } from './Flow141';
export { FLOW_142_NEW_USER_HOLD_EXPIRED } from './Flow142';
export { FLOW_143_B2B_BH_TRIZETTO_DISTRICT9 } from './Flow143';
export { FLOW_144_B2B_BH_MANUAL_DISTRICT9 } from './Flow144';
export { FLOW_145_B2B_BH_TRIZETTO_MEDICARE } from './Flow145';
export { FLOW_146_B2B_BH_MANUAL_MEDICARE } from './Flow146';
export { FLOW_147_TEENSPACE_OPTIMIZED } from './Flow147';
export { FLOW_148_NEW_USER_JWT_EXPIRED } from './Flow148';
export { FLOW_149_B2B_BH_TRIZETTO_TRICARE_EAST } from './Flow149';
export { FLOW_150_B2B_BH_OPTUM_MA } from './Flow150';
export { FLOW_151_B2B_BH_TRIZETTO_HORIZON_MA } from './Flow151';
export { FLOW_152_B2B_BH_TRIZETTO_HORIZON } from './Flow152';
export { FLOW_153_B2B_BH_TRIZETTO_TRICARE_WEST } from './Flow153';
export { FLOW_154_B2B_BH_TRIZETTO_BCBS_OF_MA } from './Flow154';
export { FLOW_155_THERAPY_GENERAL_SHORT } from './Flow155';
export { FLOW_156_PRE_REGISTERED_PSYCHIATRY } from './Flow156';
export { FLOW_232_CONSUMER_THERAPY_V2_BH } from './Flow232';
export { FLOW_157_B2B_BH_TRIZETTO_REGENCE_MA } from './Flow157';
export { FLOW_158_B2B_BH_TRIZETTO_ASURIS_MA } from './Flow158';
export { FLOW_167_CONSENTER_TEEN_REFERRAL } from './Flow167';

const flows = [
  Flow2,
  Flow7,
  Flow9,
  Flow11,
  Flow19,
  Flow24,
  Flow25,
  Flow28,
  Flow32,
  Flow33,
  Flow41,
  Flow43,
  Flow44,
  Flow46,
  Flow48,
  Flow59,
  Flow60,
  Flow62,
  Flow63,
  Flow64,
  Flow65,
  Flow66,
  Flow67,
  Flow68,
  Flow69,
  Flow70,
  Flow71,
  Flow72,
  Flow73,
  Flow74,
  Flow75,
  Flow76,
  Flow78,
  Flow79,
  Flow80,
  Flow81,
  Flow82,
  Flow83,
  Flow84,
  Flow85,
  Flow86,
  Flow89,
  Flow90,
  Flow91,
  Flow92,
  Flow93,
  Flow94,
  Flow95,
  Flow96,
  Flow97,
  Flow98,
  Flow100,
  Flow101,
  Flow102,
  Flow103,
  Flow104,
  Flow105,
  Flow106,
  Flow110,
  Flow111,
  Flow112,
  Flow113,
  Flow115,
  Flow116,
  Flow117,
  Flow118,
  Flow119,
  Flow120,
  Flow200,
  Flow121,
  Flow122,
  Flow123,
  Flow124,
  Flow125,
  Flow126,
  Flow127,
  Flow128,
  Flow129,
  Flow130,
  Flow131,
  Flow132,
  Flow133,
  Flow134,
  Flow135,
  Flow136,
  Flow137,
  Flow138,
  Flow139,
  Flow140,
  Flow141,
  Flow142,
  Flow143,
  Flow144,
  Flow145,
  Flow146,
  Flow147,
  Flow148,
  Flow149,
  Flow150,
  Flow151,
  Flow152,
  Flow153,
  Flow154,
  Flow155,
  Flow156,
  Flow232,
  Flow157,
  Flow158,
  Flow167,
];

export default flows;

export const getClientFlow = (flowID: number, includeDeleted: boolean = true) => {
  let flowsArr = [...flows.filter((flow) => !flow.flowConfig.disabled)];

  if (includeDeleted) {
    /* Flows used for configuration, but accessing them directly is not allowed */
    const deletedFlows = flows.filter((flow) => flow.flowConfig.disabled);

    flowsArr = flowsArr.concat(deletedFlows);
  }

  return flowsArr.find((f) => f.flowId === flowID);
};

export const getMergedFlowConfig = (flowID: number, updatedValues?: Record<string, any>) => {
  const updatedFlow = flows.find((flow) => flow.flowId === flowID) || ({} as QMFlow);

  if (updatedFlow?.flowConfig) {
    updatedFlow.flowConfig = { ...updatedFlow.flowConfig, ...updatedValues };
  }

  return updatedFlow;
};

export const getClientFlowConfig = (
  flowID: number,
  includeDeleted?: boolean
): FlowConfig | null => {
  const flow = getClientFlow(flowID, includeDeleted);
  if (!flow) return null;
  return flow.flowConfig;
};

export const getStepFromFlowID = <TStep extends FlowStep, FindMany extends boolean = false>(
  flowID: number,
  isParticularStep: (step: FlowStep) => step is TStep,
  findMany?: FindMany
): ConditionalResponse<FindMany, TStep[], TStep | null> => {
  const flow = getClientFlow(flowID);
  if (findMany)
    return (flow?.steps?.filter((s) => isParticularStep(s)) ?? []) as ConditionalResponse<
      FindMany,
      TStep[],
      TStep | null
    >;
  const step = flow && flow.steps.find((s) => isParticularStep(s));
  if (!step) return null as ConditionalResponse<FindMany, TStep[], TStep | null>;
  return step as ConditionalResponse<FindMany, TStep[], TStep | null>;
};

export const getStepFromFlowIDByID = <TStep extends FlowStep = FlowStep>(
  flowID: number,
  stepID: number
): TStep | null => {
  const flow = getClientFlow(flowID);
  const step = flow && flow.steps.find((s) => s.id === stepID);
  if (!step) return null;
  return step as TStep;
};

export const FLOW_200_EXPERIMENTS: AllLaunchDarklyExperimentNames[] = [
  'experimentalCoverageVerification',
  'removeOnboardingQuestions',
];

export const DEFAULT_FLOWS = {
  CONSUMER: FLOW_90_SELECT_SERVICE,
  NON_ELIGIBLE: -1,
  B2B: FLOW_138_B2B_VOUCHER_GENERIC_V2,
  COPAY: FLOW_7_B2B_COPAY_GENERIC,
  TEENS: FLOW_140_TEEN_THERAPY,
  DTE: Flow147.flowId,
};

export * from './types';

export * from './constants';
