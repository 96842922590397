import { useTranslation } from '@talkspace/i18n';

import {
  Button,
  EmergencyDisclaimer,
  EmotionThemeProvider,
  SpacingView,
  StickyDrawer,
  TextDS,
  View,
  styled,
} from '@talkspace/react-toolkit';
import TeenConsenterFAQs from '../../TeenConsenterFAQs';
import { UpdateStep, TeenConsenterReferralGetStartedStep } from '../../../Flows';
import StepDescriptionItem from '../../StepDescriptionItem';
import TeenBanner from '../../TeenBanner';
import NeedHelpPhone from '../../NeedHelpPhone';

const Styled = {
  Container: styled(View)({}),
  Title: styled(TextDS)(({ theme: { spacing } }) => {
    return {
      marginTop: spacing('space400'),
    };
  }),
  Description: styled(TextDS)(({ theme: { spacing } }) => {
    return {
      marginTop: spacing('space150'),
    };
  }),
  StepsContainer: styled(View)(({ theme: { spacing } }) => {
    return {
      marginTop: spacing('space400'),
    };
  }),
  CTAButton: styled(Button)({ minWidth: 345 }),
};

const stepsData = [
  {
    number: 1,
    title: 'You give consent',
    description: 'Give consent for your teen to get therapy and share a link to sign up',
    titleTranslationKey: 'teenStepDescriptions.youGiveConsentTitle',
    descriptionTranslationKey: 'teenStepDescriptions.youGiveConsentDescription',
  },
  {
    number: 2,
    title: 'Your teen signs up',
    description: 'Your teen completes sign up and matches with a therapist',
    titleTranslationKey: 'teenStepDescriptions.yourTeenSignsUpTitle',
    descriptionTranslationKey: 'teenStepDescriptions.yourTeenSignsUpDescription',
  },
];

interface Props {
  step: TeenConsenterReferralGetStartedStep;
  updateStep: UpdateStep;
}
const TeenConsenterReferralGetStarted = ({ step, updateStep }: Props) => {
  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');
  const onPressCTA = () => {
    updateStep(step.buttonTarget);
  };
  return (
    <EmotionThemeProvider version="2.0.0">
      <TeenBanner type="eligibleConsenter" />
      <Styled.Container align="center">
        <Styled.Title variant="heading2xl">
          {tQuickmatchCommon(
            'teenConsenterReferralGetStartedStep.title',
            'Get your teen started with Talkspace',
            undefined
          )}
        </Styled.Title>
        <Styled.Description>
          {tQuickmatchCommon(
            'teenConsenterReferralGetStartedStep.subtitle',
            'You’ll begin the process, then hand it off to your teen to complete their sign up',
            undefined
          )}
        </Styled.Description>
        <Styled.StepsContainer>
          <SpacingView itemSpacing="space300">
            {stepsData.map((stepData) => (
              <StepDescriptionItem {...stepData} />
            ))}
          </SpacingView>
        </Styled.StepsContainer>
        <NeedHelpPhone />
        <TeenConsenterFAQs />
        <StickyDrawer>
          <Styled.CTAButton
            text={tQuickmatchCommon(
              'teenConsenterReferralGetStartedStep.ctaText',
              'Get started',
              undefined
            )}
            onPress={onPressCTA}
            dataQa="getStartedButton"
          />
          <EmergencyDisclaimer />
        </StickyDrawer>
      </Styled.Container>
    </EmotionThemeProvider>
  );
};

export default TeenConsenterReferralGetStarted;
