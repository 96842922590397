import {
  Button,
  View,
  useWindowWidthState,
  TextDS,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { HandHoldingHeart } from '@talkspace/react-toolkit/src/designSystems/illustrations';
import { useTranslation } from '@talkspace/i18n';

import { YoureInGoodHandsStep, UpdateStep } from '../../../Flows';
import StickyDrawerQM from '../../StickyDrawerQM';
import styled from '@/core/styled';

const Styled = {
  Container: styled(View)(({ isMobile }: { isMobile: boolean }) => {
    return {
      maxWidth: 350,
      minHeight: isMobile ? 'calc(100vh - 260px)' : undefined,
      justifyContent: 'space-between',
    };
  }),
};

interface Props {
  step: YoureInGoodHandsStep;
  updateStep: UpdateStep;
}
const YoureInGoodHands = ({ step, updateStep }: Props) => {
  const { isMobile } = useWindowWidthState();
  const { spacing } = useEmotionTheme();
  const { t: tQuickmatchFlow232 } = useTranslation('quickmatch.flow232');

  const selectedGoal = true;
  return (
    <Styled.Container isMobile={isMobile}>
      <View align="center">
        <HandHoldingHeart />
        <TextDS
          variant="heading2xl"
          style={{ marginTop: spacing('space200'), marginBottom: spacing('space200') }}
        >
          {tQuickmatchFlow232('stepYoureInGoodHands.heading1', 'You’re in good hands', undefined)}
        </TextDS>
        <TextDS>
          {selectedGoal
            ? tQuickmatchFlow232(
                'stepYoureInGoodHands.bodySelectedGoal',
                'Talkspace has licensed therapists who can support your unique needs. Together, you’ll work towards your therapy goals in a safe and compassionate space.',
                undefined
              )
            : tQuickmatchFlow232(
                'stepYoureInGoodHands.bodySkippedGoal',
                'Talkspace has licensed therapists who can support your unique needs and help you define your goals. Together, you’ll work towards them in a safe and caring space.',
                undefined
              )}
        </TextDS>
      </View>
      <StickyDrawerQM noBorder>
        <Button
          stretch
          text="Continue"
          onPress={() => updateStep(step.buttonTarget)}
          dataQa="YoureInGoodHandsContinueButton"
        />
      </StickyDrawerQM>
    </Styled.Container>
  );
};
export default YoureInGoodHands;
