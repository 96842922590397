import { Trans, useTranslation } from '@talkspace/i18n';

import { Link, TextDS, View, styled } from '@talkspace/react-toolkit';

const Styled = {
  HelpPhoneContainer: styled(View)(({ theme: { spacing } }) => {
    return {
      width: '100%',
      margin: `${spacing('space400')}px`,
    };
  }),
  HelpPhoneLink: styled(Link)(({ theme: { colorRoles } }) => {
    return {
      color: colorRoles.typography.textInteractiveDefault,
    };
  }),
};
const NeedHelpPhone = () => {
  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');
  return (
    <Styled.HelpPhoneContainer>
      <TextDS>
        <Trans t={tQuickmatchCommon} i18nKey="teenHelp.helpPhoneRow">
          Need help signing up? Call
          <Styled.HelpPhoneLink href="tel:8888500986" dataQa="helpPhoneNumber">
            <TextDS variant="linkMd" inline inheritColor>
              {{ phone_number: '(888) 850-0986' }}
            </TextDS>
          </Styled.HelpPhoneLink>
        </Trans>
      </TextDS>
    </Styled.HelpPhoneContainer>
  );
};
export default NeedHelpPhone;
