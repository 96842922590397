import { useCallback, useEffect, useState } from 'react';

import View from '../../../components/View';
import { styled } from '../../../hooks/EmotionThemeProvider';
import { ChevronDown, ChevronUp } from '../../icons';

const CHEVRON_SIZE = 20;

const Styled = {
  Container: styled(View)(({ theme: { colorRoles } }) => {
    return {
      borderBottom: `1px solid ${colorRoles.borders.borderSubtleDefault}`,
      '&:hover': {
        backgroundColor: colorRoles.surfaces.defaultHovered,
      },
      '&:pressed': {
        backgroundColor: colorRoles.surfaces.defaultPressed,
      },
    };
  }),
  HeaderContainer: styled(View)(({ theme: { spacing } }) => {
    return {
      cursor: 'pointer',
      padding: spacing('space200'),
      flexDirection: 'row',
      justifyContent: 'space-between',
    };
  }),
  ChevronContainer: styled(View)(({ theme: { spacing } }) => {
    return {
      width: CHEVRON_SIZE,
      height: CHEVRON_SIZE,
      marginLeft: spacing('space050'),
    };
  }),
  ChildrenContainer: styled(View)(({ theme: { spacing } }) => {
    return {
      padding: `0 calc(${spacing('space200')}px + ${spacing(
        'space050'
      )}px + ${CHEVRON_SIZE}px) ${spacing('space200')}px  ${spacing('space200')}px`,
    };
  }),
};

interface Props {
  title: React.ReactNode;
  children: React.ReactNode;
  dataQa: string;
  onOpenSideEffect?: () => void;
}
const Accordion = ({ title, children, dataQa, onOpenSideEffect }: Props) => {
  const [isOpened, setIsOpened] = useState(false);
  const handleToggle = useCallback(() => {
    setIsOpened((value) => !value);
  }, []);
  useEffect(() => {
    if (isOpened && onOpenSideEffect) {
      onOpenSideEffect();
    }
  }, [isOpened, onOpenSideEffect]);
  return (
    <Styled.Container data-qa={dataQa}>
      <Styled.HeaderContainer onClick={handleToggle}>
        {title}
        <Styled.ChevronContainer>
          {isOpened ? <ChevronUp /> : <ChevronDown />}
        </Styled.ChevronContainer>
      </Styled.HeaderContainer>
      {isOpened && <Styled.ChildrenContainer>{children}</Styled.ChildrenContainer>}
    </Styled.Container>
  );
};

export default Accordion;
