import { useRef, useEffect, useContext, useCallback, createContext } from 'react';
import { useObjectState } from '@talkspace/react-toolkit';
import * as React from 'react';
import { Location } from 'history';
import { AxiosError } from 'axios';
import { ServiceType } from 'ts-frontend/types';
import { useHistory, useLocation } from '@/core/routerLib';
import useDispatcherRouting, { getDispatcherStepID, STEPS_TO_ROUTES } from './useDispatcherRouting';
import {
  checkEligibility,
  DispatcherGoToResponse,
  verifyEmailCode,
  validateKeyword,
  resendCode,
} from '../Helpers/apiService';
import { useRecoveredSessionState, useRecoveredSessionActions } from './recoveredSessionContext';
import { trackEvent } from '../utils/analytics/events';
import getParamByName from '../utils/queryString';
import { DEFAULT_FLOWS } from '../Flows';
import { useHomePageActions } from '@/Components/HomePage';

const DISPATCHER_FLOW_VERSION = 2;

const DispatcherStateContext = createContext<DispatcherState | undefined>(undefined);

const DispatcherActionsContext = createContext<DispatcherActions | undefined>(undefined);

const trackDispatcherResponse = (currentStep: DispatcherStep, response: string | boolean = '') => {
  trackEvent('Answer Questionnaire', {
    Flow: 'Dispatcher',
    Question: STEPS_TO_ROUTES[currentStep],
    Response: response,
  });
};

const isEligibilitySource = (location: Location) =>
  getParamByName('source', location) === 'eligibility';
const getQMFlowDispatcherStep = (location: Location) =>
  getParamByName('initialDispatcherStep', location) as DispatcherStep;

const getFirstStep = (location: Location): DispatcherStep => {
  if (getQMFlowDispatcherStep(location)) {
    return getQMFlowDispatcherStep(location);
  }
  return 'organizationEmail';
};

const DispatcherContextProvider: React.FC<{
  goToFlowIDIntercept?: GoToFlowIDInterceptFunction;
  serviceType?: ServiceType;
}> = ({ goToFlowIDIntercept, serviceType, children }) => {
  const history = useHistory();
  const location = useLocation();
  const hashParamsRef = useRef({
    firstStep: getFirstStep(location),
    isEligibilitySource: isEligibilitySource(location),
    QMFlowDispatcherStep: getQMFlowDispatcherStep(location),
  });
  const [state, setState] = useObjectState<DispatcherState>({
    isReady: false,
    restoringState: false,
    organizationEmail: null,
    organizationName: null,
    hasAccessCode: null,
    currentStep: hashParamsRef.current.firstStep,
    goToFlowID: null,
    accessCode: null,
    accessCodeType: null,
    saveMetadataKeyword: null,
    isCheckingEligibility: false,
    isError: false,
    isPartOfQMFlow: !!getQMFlowDispatcherStep(location),
    isEligibilitySource: isEligibilitySource(location),
    allowedModalities: undefined,
    totalSessions: 0,
    accountType: null,
  });

  const stateRef = useRef(state);
  stateRef.current = state;

  const handleDispatcherResponse = useCallback(
    (data: DispatcherGoToResponse | null) => {
      if (!data) {
        setState({ isCheckingEligibility: false, isError: true });
        return;
      }
      const { goto, params } = data;
      switch (goto) {
        case 'EMAIL_VERIFICATION':
          setState({ currentStep: 'verifyEmail', isCheckingEligibility: false });
          break;
        case 'NOT_VERIFIED':
          if (params.isMismatchingService) {
            // Skip rest of dispatcher flow if mismatching service
            setState({
              hasAccessCode: false,
              currentStep: 'goToFlowID',
              goToFlowID: DEFAULT_FLOWS.NON_ELIGIBLE,
            });
          } else {
            setState({ currentStep: 'keywordCheck', isCheckingEligibility: false });
          }
          break;
        case 'QM_FLOW':
          setState({
            currentStep: 'goToFlowID',
            goToFlowID: params.qmFlowID,
            accessCode: params.accessCode || null,
            accessCodeType: params.accessCodeType || 'qmPartnerCode',
            isCheckingEligibility: false,
            allowedModalities: params.allowedModalities,
            totalSessions: params.totalSessions || 0,
            accountType: params.accountType || null,
          });
          break;
        default:
          setState({ isCheckingEligibility: false });
          break;
      }
    },
    [setState]
  );

  const handleCheckEligibilityError = useCallback(
    (error) => {
      // eslint-disable-next-line no-console
      console.log(`API Error ${error.message}`, error);
      if ((error as AxiosError)?.response?.status === 400) {
        const errorMessage = (error as AxiosError<{ error: string }>)?.response?.data.error;
        if (errorMessage?.includes('Redeems reached for email')) {
          setState({ currentStep: 'redeemsReachedForEmailError', isCheckingEligibility: false });
        } else if (errorMessage?.includes('Redeems reached for organization')) {
          return { goto: 'NOT_VERIFIED', params: {} } as DispatcherGoToResponse;
        }
      }
      return null;
    },
    [setState]
  );

  const setCurrentStep = useCallback(
    (currentStep: DispatcherStep, flowID?: number) => {
      setState({ isError: false, currentStep, ...(flowID && { goToFlowID: flowID }) });
    },
    [setState]
  );

  const setOrganizationName = useCallback(
    (organizationName: string) => {
      setState({
        organizationName,
        currentStep: 'organizationEmail',
        // TODO: @Luis - Edge case warning: User completes dispatcher with a valid keyword / accessCode, goes to flow, uses back arrow to go back to this step,
        // enters again a non-whitelisted organizationName and refreshes the page, being taken again to regular quickmatch but now has an invalid value for the
        // saveMetadataKeyword since this will override the `submitAccessCode` call. Not sure how to solve this without having other edge cases come up.
        // However, this is good if the new organization name is a valid keyword.
        saveMetadataKeyword: organizationName,
      });
      if (!stateRef.current.restoringState)
        trackDispatcherResponse(stateRef.current.currentStep, organizationName);
    },
    [setState]
  );

  const setOrganizationEmail = useCallback(
    async (organizationEmail: string) => {
      const { currentStep } = stateRef.current;
      setState({ organizationEmail, isCheckingEligibility: true });
      const data = await checkEligibility({
        email: organizationEmail,
        organization: stateRef.current.organizationName as string,
        serviceType: serviceType as string,
      }).catch(handleCheckEligibilityError);
      handleDispatcherResponse(data);
      if (!stateRef.current.restoringState) trackDispatcherResponse(currentStep);
    },
    [handleCheckEligibilityError, handleDispatcherResponse, serviceType, setState]
  );

  const submitEmailVerificationCode = useCallback(
    async (verificationCode: number) => {
      const { currentStep } = stateRef.current;
      setState({ isError: false, isCheckingEligibility: true });
      const data = await verifyEmailCode({ pinCode: verificationCode }).catch(
        handleCheckEligibilityError
      );
      handleDispatcherResponse(data);
      if (!stateRef.current.restoringState) trackDispatcherResponse(currentStep);
    },
    [handleCheckEligibilityError, handleDispatcherResponse, setState]
  );

  const resendVerificationCode = useCallback(async () => {
    if (!stateRef.current.organizationEmail) return; // Should not happen
    await resendCode({ email: stateRef.current.organizationEmail });
  }, []);

  const setHasAccessCode = useCallback(
    (hasAccessCode: boolean) => {
      if (hasAccessCode) setState({ hasAccessCode, currentStep: 'eligibilityCode' });
      else
        setState({
          hasAccessCode,
          currentStep: 'goToFlowID',
          goToFlowID: DEFAULT_FLOWS.NON_ELIGIBLE,
        });
      if (!stateRef.current.restoringState)
        trackDispatcherResponse(stateRef.current.currentStep, hasAccessCode);
    },
    [setState]
  );

  const submitAccessCode = useCallback(
    async (accessCode: string) => {
      const { currentStep } = stateRef.current;
      setState({
        isError: false,
        isCheckingEligibility: true,
        saveMetadataKeyword: accessCode,
      });
      const data = await validateKeyword({
        keyword: accessCode,
        email: stateRef.current.organizationEmail as string,
        serviceType: serviceType as string,
      }).catch(handleCheckEligibilityError);
      handleDispatcherResponse(data);
      if (!stateRef.current.restoringState) trackDispatcherResponse(currentStep);
    },
    [handleCheckEligibilityError, handleDispatcherResponse, serviceType, setState]
  );

  const session = useRecoveredSessionState();
  const { setSessionState, updateAndSave } = useRecoveredSessionActions();
  const { getFlow } = useHomePageActions();

  // Session restore
  useEffect(() => {
    if (!state.isReady) {
      if (session.recoveredSession) {
        const dispatcherState: DispatcherState = session.dispatcherState || state;
        let newCurrentStep: DispatcherStep;
        if (dispatcherState.currentStep === 'goToFlowID') {
          newCurrentStep = 'goToFlowID';
        } else {
          newCurrentStep = hashParamsRef.current.firstStep;
        }
        setState({
          ...dispatcherState,
          ...hashParamsRef.current,
          currentStep: newCurrentStep,
          isPartOfQMFlow: !!hashParamsRef.current.QMFlowDispatcherStep,
          restoringState: !!session.dispatcherState,
          isReady: true,
        });
      } else {
        let flowData: Partial<Pick<RecoveredSessionState, 'flowId' | 'stepId' | 'flowVersion'>> =
          {};
        if (!state.isPartOfQMFlow) {
          flowData = { flowId: 0, stepId: 1, flowVersion: DISPATCHER_FLOW_VERSION };
        }
        setSessionState({
          ...flowData,
          recoveredSession: false,
          dispatcherState: state,
        });
        setState({
          isReady: true,
        });
      }
    }
  }, [session.dispatcherState, session.recoveredSession, setSessionState, setState, state]);

  // Saving the session
  useEffect(() => {
    if (state.isReady && !stateRef.current.restoringState) {
      const flowData: Partial<Pick<RecoveredSessionState, 'flowId' | 'stepId' | 'flowVersion'>> =
        {};
      if (stateRef.current.currentStep === 'goToFlowID') {
        flowData.flowId = stateRef.current.goToFlowID;
        const flow = flowData.flowId ? getFlow(flowData.flowId, false) : null;
        flowData.stepId = 1;
        flowData.flowVersion = flow ? flow.flowConfig.version : 0;
        // If coming from QM, don't override the flow information
      } else if (!state.isPartOfQMFlow) {
        flowData.flowId = 0;
        flowData.stepId = getDispatcherStepID(stateRef.current.currentStep);
        flowData.flowVersion = DISPATCHER_FLOW_VERSION;
      }
      updateAndSave({ dispatcherState: stateRef.current, ...flowData });
    }
    // Only save data when the step changes
  }, [state.isReady, state.currentStep, state.isPartOfQMFlow, updateAndSave, getFlow]);

  // Restoring the session
  useEffect(() => {
    if (state.restoringState) {
      switch (state.currentStep) {
        case 'organizationName':
          if (stateRef.current.organizationName === null) setState({ restoringState: false });
          else setOrganizationName(stateRef.current.organizationName);
          break;
        case 'organizationEmail':
          if (stateRef.current.organizationEmail === null) setState({ restoringState: false });
          else setOrganizationEmail(stateRef.current.organizationEmail);
          break;
        case 'verifyEmail':
          setState({ restoringState: false });
          break;
        case 'keywordCheck':
          if (stateRef.current.hasAccessCode === null) setState({ restoringState: false });
          else setHasAccessCode(stateRef.current.hasAccessCode);
          break;
        case 'eligibilityCode':
          setState({ restoringState: false });
          break;
        default:
          setState({ restoringState: false });
          break;
      }
    }
  }, [
    setState,
    state.currentStep,
    state.restoringState,
    setOrganizationName,
    setOrganizationEmail,
    setHasAccessCode,
  ]);

  const actions = {
    updateDispatcherState: setState,
    resendVerificationCode,
    setCurrentStep,
    setOrganizationEmail,
    setOrganizationName,
    submitEmailVerificationCode,
    setHasAccessCode,
    submitAccessCode,
  };

  // In charge of routing
  useDispatcherRouting(
    {
      isReady: state.isReady,
      accessCode: state.accessCode,
      accessCodeType: state.accessCodeType,
      goToFlowID: state.goToFlowID,
      currentStep: state.currentStep,
      restoringState: state.restoringState,
      setCurrentStep: actions.setCurrentStep,
      goToFlowIDIntercept,
      allowedModalities: state.allowedModalities,
      totalSessions: state.totalSessions,
      accountType: state.accountType,
    },
    location,
    history
  );

  return (
    <DispatcherStateContext.Provider value={state}>
      <DispatcherActionsContext.Provider value={actions}>
        {children}
      </DispatcherActionsContext.Provider>
    </DispatcherStateContext.Provider>
  );
};

export default DispatcherContextProvider;

export const useDispatcherState = (): DispatcherState => {
  const context = useContext(DispatcherStateContext);
  if (context === undefined)
    throw new Error('DispatcherState must be used within DispatcherContextProvider');
  return context;
};

export const useDispatcherActions = (): DispatcherActions => {
  const context = useContext(DispatcherActionsContext);
  if (context === undefined)
    throw new Error('DispatcherActions must be used within DispatcherContextProvider');
  return context;
};
