import {
  Standard,
  View,
  HeartShield,
  EmotionThemeProvider,
  TextDS,
} from '@talkspace/react-toolkit';
import styled from '@/core/styled';
import { BHPayerDetailsProps } from './types';
import { useEstimatedCost } from '../../../hooks';

const Styled = {
  BHPayerDetails: styled(Standard)(({ theme: { colorRoles } }) => {
    return {
      backgroundColor: colorRoles.surfaces.brandSubtleDefault,
      padding: 15,
      borderRadius: 10,
    };
  }),
  BHPayerTitle: styled(Standard)(() => {
    return {
      fontSize: 16,
      marginTop: -1,
      marginLeft: 5,
      marginBottom: 5,
      fontWeight: 'bold',
    };
  }),
  BHSubTitle: styled(Standard)(() => {
    return {
      fontSize: 20,
      marginTop: 15,
      marginBottom: 5,
      fontWeight: 'bold',
    };
  }),
};

const BHPayerDetails = ({ insuranceDetails, isDirectFlow }: BHPayerDetailsProps) => {
  const { perSession, perSessionMin } = useEstimatedCost();

  const maskedID = insuranceDetails?.memberID
    ? '•'.repeat(insuranceDetails.memberID.length - 4) + insuranceDetails.memberID.slice(-4)
    : undefined;

  let name = insuranceDetails?.firstName;
  if (insuranceDetails?.lastName) {
    name = name ? `${name}, ${insuranceDetails.lastName}` : insuranceDetails.lastName;
  }

  let details = insuranceDetails?.dob;
  if (insuranceDetails?.state) {
    details = details ? `${details}, ${insuranceDetails.state}` : insuranceDetails.state;
  }
  if (insuranceDetails?.dependentRelationship) {
    details = details
      ? `${details}, ${insuranceDetails.dependentRelationship}`
      : insuranceDetails.dependentRelationship;
  }

  let copay = (Number(insuranceDetails?.copayCents || 0) / 100).toString();

  if (insuranceDetails?.gediPayerID === 'ELCMS') {
    // medicare require range of copay
    copay = (perSessionMin !== undefined ? `${perSessionMin}-$` : '$') + perSession;
  }

  return (
    <>
      <EmotionThemeProvider version="1.0.0">
        <Styled.BHPayerDetails>
          {!isDirectFlow && (
            <View row>
              <HeartShield />
              <Styled.BHPayerTitle dataQa="BHPayerNameTitle">
                {insuranceDetails?.payerName}
              </Styled.BHPayerTitle>
            </View>
          )}
          {maskedID && (
            <TextDS variant="bodySm" colorRole="textSubtle">
              Member ID: {maskedID}
            </TextDS>
          )}
          {name && (
            <TextDS variant="bodySm" colorRole="textSubtle">
              {name}
            </TextDS>
          )}
          {details && (
            <TextDS variant="bodySm" colorRole="textSubtle">
              {details}
            </TextDS>
          )}
          <Styled.BHSubTitle>${copay} copay per session</Styled.BHSubTitle>
        </Styled.BHPayerDetails>
      </EmotionThemeProvider>
    </>
  );
};

export default BHPayerDetails;
