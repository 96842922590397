/**
 * Informs the user with regards to their insurance coverage status (quick coverage check).
 */
import Covered from './Covered';
// import OutOfNetwork from './OutOfNetwork';
// import Uncovered from './Uncovered';
import NotVerified from './NotVerified';
import { QuickEligiblityStatusProps } from './types';

const QuickStatus = (props: QuickEligiblityStatusProps) => {
  const { quickEligibilityInfo, loggedInUserHasPaymentMethod } = props;
  const isEligible = quickEligibilityInfo?.insuranceEligibility?.isEligible;
  const verificationSucceeded = quickEligibilityInfo?.insuranceEligibility?.verificationSucceeded;

  // verification not successful
  if (verificationSucceeded === false) {
    return <NotVerified {...props} />;
  }

  if (isEligible) {
    return <Covered {...props} loggedInUserHasPaymentMethod={!!loggedInUserHasPaymentMethod} />;
  }
  // return <OutOfNetwork {...props} />;

  // including verificaition succeeded, isEligible is false
  return <NotVerified {...props} />;
  // return <Uncovered {...props} />;
};

export default QuickStatus;
