import { useEffect, useState, useMemo } from 'react';
import { Trans } from '@talkspace/i18n';

import useTranslation from '@talkspace/i18n/hooks/useTranslation';
import {
  Button,
  EmergencyDisclaimer,
  SelectList,
  StickyDrawer,
  useEmotionTheme,
} from '@talkspace/react-toolkit';
import { EmotionStyle } from '../../core/styled';
import {
  InternalTarget,
  ExternalTarget,
  UpdateStepObj,
  SelectButton,
  SelectStep,
  SelectMultiStep,
  PresentingProblemsStep,
  SelectDynamicStepResolved,
} from '../../Flows/types';

interface FinalButton {
  label: string;
  value: string;
  internalTarget?: InternalTarget;
  externalTarget?: ExternalTarget;
  answerValue?: string;
}

export interface ButtonsProps {
  updateStep: (target: InternalTarget | ExternalTarget, updateStepObj: UpdateStepObj) => void;
  style?: EmotionStyle;
  value?: string;
  step: SelectStep | PresentingProblemsStep | SelectMultiStep | SelectDynamicStepResolved;
  buttons: Array<SelectButton>;
  isLoggedIn: boolean;
  fieldStyle?: EmotionStyle;
}

const getResponseOptions = (buttons: Array<SelectButton>) =>
  buttons.map((button) => {
    return {
      label: button.text,
      labelTranslationKey: button.translationKey,
      value: String(button.internalTarget),
      internalTarget: button.internalTarget,
      externalTarget: button.externalTarget,
      answerValue: button.answerValue,
    };
  });

const Buttons = ({
  updateStep,
  step,
  buttons,
  value,
  style,
  isLoggedIn,
  fieldStyle,
}: ButtonsProps) => {
  const { response_category_id: responseCategoryID } = step;

  const { t: tQuickmatchCommon } = useTranslation('quickmatch.common');
  const responseOptions = useMemo(() => getResponseOptions(buttons), [buttons]);

  const [selection, setSelection] = useState<FinalButton[]>([]);

  const defaultSelection = useMemo(() => {
    const selectionValues = value?.split(/,(?!\s)/); // NOTE: Some values may have commas in them, need to only split by commas that have no space after them
    return responseOptions.filter((button) => selectionValues?.includes(button.label));
  }, [responseOptions, value]);

  const { category, analyticsId, heading2 } = step;
  const { colors } = useEmotionTheme();
  const isMulti = 'isMulti' in step && step.isMulti;

  useEffect(() => {
    setSelection(defaultSelection as FinalButton[]);
  }, [defaultSelection]);

  const onQuestionPress = (button: FinalButton | FinalButton[]) => {
    // Due to new DS 2.0 SelectList returning `[button]` instead of `button`,
    // check if button is an array and has a single element
    const isSingleSelection = Array.isArray(button) && button.length === 1;

    if (!isSingleSelection || isMulti) {
      setSelection(button as FinalButton[]);
    } else {
      const option = button[0];
      updateStep(`${option.externalTarget ? option.externalTarget : option.internalTarget}`, {
        payfirst_step_id: analyticsId,
        payfirst_step_prompt: heading2,
        response_category_id: responseCategoryID,
        response_prompt: option.label,
        response_value: option.answerValue,
      });
    }
  };

  const onButtonPress = () => {
    // Button press is only for step.isMulti
    if (isMulti && selection.length) {
      updateStep(step.buttonTarget, {
        payfirst_step_id: analyticsId,
        payfirst_step_prompt: heading2,
        response_category_id: responseCategoryID,
        response_prompt: selection.map(({ label }) => label).join(','),
        response_value: selection.map(({ answerValue }) => answerValue).join(','),
      });
    }
  };

  return (
    <>
      <SelectList
        style={style}
        value={value}
        optionsList={responseOptions}
        selectedOptions={selection}
        isMultiSelect={isMulti}
        radioGroupLegend={
          heading2
            ? `Choose one answer for the following prompt: ${heading2}`
            : 'Choose one of the following answers'
        }
        dataQa={category}
        defaultSelection={defaultSelection}
        onSubmitCallback={onQuestionPress}
        wrapperStyle={{
          maxWidth: 430,
          width: '100%',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
        fieldSetStyle={{
          paddingLeft: 0,
          paddingRight: 0,
        }}
        fieldStyle={fieldStyle}
      />
      {!!selection?.length && isMulti && (
        <StickyDrawer>
          <Button
            dataQa={`${step.category}ButtonsMultiContinueButton`}
            type="submit"
            primaryColor={colors.green}
            roundedFocusStyle
            onPress={onButtonPress}
            style={{ width: 335, minHeight: 55, alignSelf: 'center' }}
          >
            <Trans t={tQuickmatchCommon} i18nKey="buttonsComponent.buttonTextMulti">
              Continue
            </Trans>
          </Button>
          <EmergencyDisclaimer isLoggedIn={isLoggedIn} style={{ marginTop: 16 }} />
        </StickyDrawer>
      )}
    </>
  );
};
export default Buttons;
