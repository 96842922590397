import { FunctionComponent } from 'react';

import { TranslationNamespaceQuickmatchFlow } from '@talkspace/i18n/types';
import {
  View,
  Tooltip,
  Button as AccessibleButton,
  useEmotionTheme,
  ChatBubble3Dots,
  CalendarCheckMark,
  Big,
  useUniqueID,
  useWindowWidthState,
} from '@talkspace/react-toolkit';
import useStringTranslation from '@talkspace/i18n/hooks/useStringTranslation';
import styled from '@/core/styled';
import type { ExplanationStep } from '@/Flows/types';

interface ExplainerProps {
  step: ExplanationStep;
  updateStep: (arg: any) => Promise<unknown>;
  i18nNamespace?: TranslationNamespaceQuickmatchFlow;
}

const ExplanationBlock = styled(View)(({ theme: { colors } }) => {
  return {
    height: 128,
    width: '100%',
    maxWidth: 428,
    border: `1px solid ${colors.permaMystic}`,
    boxShadow: '0px 10px 10px rgba(0, 0, 0, 0.03)',
    borderRadius: 9,
    marginBottom: 8,
    position: 'relative',
  };
});

const svgPositionStyles = (isMobile?: boolean) => {
  return { position: 'absolute', left: isMobile ? 20 : 40, top: 40 };
};
const explanationTextStyles = {
  textAlign: 'left',
  fontWeight: 400,
  maxWidth: 250,
  position: 'absolute',
  left: 120,
  top: 30,
};

const toolTipStyles = { position: 'absolute', right: 12, top: 12, borderRadius: 5 };
const tooltipMessageStyle = {
  maxWidth: 300,
  borderRadius: 10,
  padding: 10,
  textAlign: 'center',
};

const Explainer: FunctionComponent<ExplainerProps> = ({ step, updateStep, i18nNamespace }) => {
  const { colors } = useEmotionTheme();
  const { isMobile } = useWindowWidthState();
  const explanation1Id = useUniqueID('explanation1Id');
  const explanation2Id = useUniqueID('explanation2Id');
  const explanationModalityText = useStringTranslation(
    step.explanationGroups[0].text,
    i18nNamespace,
    `step${step.id}.explanationModalityText`
  );
  const explanationModalityTooltipText = useStringTranslation(
    step.explanationGroups[0].tooltipText,
    i18nNamespace,
    `step${step.id}.explanationModalityTooltipText`
  );
  const explanationAvailabilityText = useStringTranslation(
    step.explanationGroups[1].text,
    i18nNamespace,
    `step${step.id}.explanationAvailabilityText`
  );
  const explanationAvailabilityTooltipText = useStringTranslation(
    step.explanationGroups[1].tooltipText,
    i18nNamespace,
    `step${step.id}.explanationAvailabilityTooltipText`
  );
  const buttonText = useStringTranslation(
    step.buttonText!,
    i18nNamespace,
    `step${step.id}.buttonText`
  );
  return (
    <View style={{ display: 'contents', width: '100%' }}>
      <ExplanationBlock>
        <ChatBubble3Dots style={svgPositionStyles(isMobile)} />
        <Big id={explanation1Id} style={explanationTextStyles}>
          {explanationModalityText}
        </Big>
        <Tooltip
          ariaDescribedBy={explanation1Id}
          buttonStyle={toolTipStyles}
          toggleSize={26}
          tip={explanationModalityTooltipText}
          messageStyle={tooltipMessageStyle}
          label="messaging tooltip"
          roundedFocusStyle
          dataQa={`${step.category || ''}MessagingTooltip`}
        />
      </ExplanationBlock>
      <ExplanationBlock>
        <CalendarCheckMark style={svgPositionStyles(isMobile)} />
        <Big id={explanation2Id} style={explanationTextStyles}>
          {explanationAvailabilityText}
        </Big>
        <Tooltip
          ariaDescribedBy={explanation2Id}
          buttonStyle={toolTipStyles}
          toggleSize={26}
          tip={explanationAvailabilityTooltipText}
          messageStyle={tooltipMessageStyle}
          label="provider availability tooltip"
          roundedFocusStyle
          dataQa={`${step.category || ''}AvailabilityTooltip`}
        />
      </ExplanationBlock>
      <AccessibleButton
        roundedFocusStyle
        primaryColor={colors.green}
        onClick={() => updateStep(step.buttonTarget)}
        style={{ width: '100%', maxWidth: 407, height: 55 }}
        dataQa={`${step.category || ''}Continue`}
      >
        {buttonText}
      </AccessibleButton>
    </View>
  );
};

export default Explainer;
