import { useFlags } from 'launchDarkly/FlagsProvider';
import { HomePageState } from '../Components/HomePage';

const useInsuranceEligibilityExperiment = (
  insuranceEligibility: HomePageState['insuranceEligibility']
): HomePageState['insuranceEligibility'] => {
  const { oonDiscount } = useFlags();
  if (
    insuranceEligibility &&
    oonDiscount?.experimentActive &&
    oonDiscount.variant !== 'control' &&
    oonDiscount.coupon &&
    oonDiscount.couponValue
  ) {
    return {
      ...insuranceEligibility,
      ineligiblePromo: oonDiscount.couponValue,
      ineligiblePromoCouponCode: oonDiscount.coupon,
    };
  }
  return insuranceEligibility;
};

export default useInsuranceEligibilityExperiment;
