import { useCallback, useEffect, useState } from 'react';

import {
  Button,
  EmotionThemeProvider,
  ExtraHuge,
  Large,
  View,
  useWindowWidthState,
  spacing,
  TextDS,
} from '@talkspace/react-toolkit';

import { useTranslation } from '@talkspace/i18n';
import { useFlags } from 'launchDarkly/FlagsProvider';
import { TeensHereToHelpStep, UpdateStep } from '../../../Flows';
import styled from '@/core/styled';
import SeparatingThreeDots from './SeparatingThreeDots';
import NeedHelpPill from '../../NeedHelpPill';
import TeenBanner from '../../TeenBanner';
import appConfigs from '../../../utils/configs';
import { useHomePageActions } from '../../HomePage';
import { getAccessCodeByZipCode } from '../../../Helpers/apiService';
import { checkIfConsentReferral, checkIfValidConsenterReferral } from '../../../Flows/utils';
import { trackEvent } from '../../../utils/analytics/eventTracker';

const Styled = {
  Container: styled(View)(({ isMobile }: { isMobile: boolean }) => {
    return {
      maxWidth: 350,
      minHeight: isMobile ? 'calc(100vh - 260px)' : undefined,
      justifyContent: 'space-between',
    };
  }),
  Title: styled(ExtraHuge)({ marginBottom: 12 }),
  BannerContainer: styled(View)({
    marginBottom: spacing.space400,
  }),
  CTAContainer: styled(View)({
    marginTop: spacing.space400,
    width: '100%',
    alignItems: 'center',
  }),
  ContinueButton: styled(Button)({
    margin: `${spacing.space150}px 0 ${spacing.space300}px`,
    width: '100%',
  }),
  LoginButton: styled(Button)(({ theme: { colors } }) => {
    return {
      marginTop: spacing.space100,
      width: '100%',
    };
  }),
  BulletPointsContainer: styled(View)({
    flexDirection: 'column',
  }),
  BulletPointsAndTextContainer: styled(View)({
    flexDirection: 'row',
    gap: spacing.space150,
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  BulletPointsTextBackground: styled(View)(({ theme: { colors } }) => {
    return {
      width: 38,
      height: 38,
      borderRadius: 19,
      backgroundColor: colors.permaAtlantisNew,
      justifyContent: 'center',
    };
  }),
  BulletPointsText: styled(Large)(({ theme: { colors } }) => {
    return {
      fontFamily: 'SF Pro Display',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 18,
      lineHeight: '21.48px',
      textAlign: 'center',
      color: colors.white,
    };
  }),
  BulletPoint: ({
    number,
    text,
    showDots,
  }: {
    number: string;
    text: string;
    showDots?: boolean;
  }) => (
    <Styled.BulletPointsContainer>
      <Styled.BulletPointsAndTextContainer>
        <Styled.BulletPointsTextBackground>
          <Styled.BulletPointsText>{number}</Styled.BulletPointsText>
        </Styled.BulletPointsTextBackground>
        <Large style={{ flex: 1, textAlign: 'start' }}>{text}</Large>
      </Styled.BulletPointsAndTextContainer>
      {showDots && <SeparatingThreeDots />}
    </Styled.BulletPointsContainer>
  ),
};

interface Props {
  flowId: number;
  step: TeensHereToHelpStep;
  updateStep: UpdateStep;
}

const TeensHereToHelp = ({ flowId, step, updateStep }: Props) => {
  const { isMobile } = useWindowWidthState();
  const { t: quickmatchFlow147t } = useTranslation('quickmatch.flow147');
  const flags = useFlags();

  const { setHomePageState } = useHomePageActions();

  const {
    clientAgeNumber,
    country: clientCountry,
    state: clientState,
    zipCode: clientZipcode,
  } = JSON.parse(sessionStorage.getItem('TSQM_BasicInformation') || '{}');

  const isConsenterReferral = flags.parentGuardianReferralExperience && checkIfConsentReferral();
  const isValidConsenterReferralLink = checkIfValidConsenterReferral();

  const [isReferralError, setIsReferralError] = useState(false);

  useEffect(() => {
    if (isConsenterReferral) {
      trackEvent('Teen Consenter Referral Link Verified', {
        isValid: isValidConsenterReferralLink,
      });
      if (!isValidConsenterReferralLink) {
        setIsReferralError(true);
      }
    }
  }, [isConsenterReferral, isValidConsenterReferralLink]);

  const onSubmit = useCallback(async () => {
    if (isConsenterReferral && isValidConsenterReferralLink) {
      const response = await getAccessCodeByZipCode({
        flowId,
        zipCode: clientZipcode,
        email: undefined,
        metadata: {
          age: clientAgeNumber,
          country: clientCountry,
          clientState,
        },
      }).catch(() => {
        setIsReferralError(true);
      });

      if (!isReferralError && response) {
        const {
          planID,
          accessCode,
          accountType,
          accessCodeType,
          partnerID,
          partnerName,
          includesTalkspaceGo,
          creditMinutes,
          allowedModalities,
          totalSessions,
        } = response;

        // update home page according to the eligibility response
        setHomePageState({
          eligiblePlan: {
            planID,
            partnerID,
            partnerName,
            includesTalkspaceGo,
            totalSessions,
            creditMinutes,
            allowedModalities,
          },
        });
        // navigate next with corresponding values passed
        updateStep(step.buttonTarget, {
          oneFormClientState: clientState,
          invalidVoucher: !accessCode,
          voucher: accessCode,
          accessCode,
          accessCodeType,
          insuranceEligibility: undefined,
          allowedModalities,
          totalSessions,
          accountType,
        });
      }
    } else {
      updateStep(step.buttonTarget);
    }
  }, [
    clientAgeNumber,
    clientCountry,
    clientState,
    clientZipcode,
    flowId,
    isConsenterReferral,
    isReferralError,
    isValidConsenterReferralLink,
    setHomePageState,
    step.buttonTarget,
    updateStep,
  ]);
  return (
    <EmotionThemeProvider version="2.0.0">
      <Styled.Container isMobile={isMobile}>
        <View align="stretch">
          {isReferralError && (
            <Styled.BannerContainer>
              <TeenBanner type="ineligibleConsenterReferralLink" />
            </Styled.BannerContainer>
          )}
          <Styled.Title>
            {isValidConsenterReferralLink
              ? quickmatchFlow147t(
                  'step1.titleReferred',
                  'You’ve been referred to free therapy',
                  undefined
                )
              : quickmatchFlow147t('step1.title', 'We’re here to help', undefined)}
          </Styled.Title>
          <>
            {flags.ageLocationEligibility ? (
              <TextDS>
                {quickmatchFlow147t(
                  'step1.descriptionOneGeneric',
                  "Millions of people have access to free therapy through their city. It's easy to get started.",
                  undefined
                )}
              </TextDS>
            ) : (
              <Large variant="largeGrey950">
                {quickmatchFlow147t(
                  'step1.description',
                  'Over 10,310 teens have already accessed free therapy. It only takes 2 minutes.',
                  undefined
                )}
              </Large>
            )}
          </>
          <View style={{ marginTop: spacing.space300 }} align="stretch" justify="center">
            {isValidConsenterReferralLink ? (
              <Styled.BulletPoint
                showDots
                number="1"
                text={quickmatchFlow147t(
                  'step1.textOneReferred',
                  'Tell us a bit about you',
                  undefined
                )}
              />
            ) : (
              <Styled.BulletPoint
                showDots
                number="1"
                text={quickmatchFlow147t('step1.textOne', 'Find out if you’re eligible', undefined)}
              />
            )}
            <Styled.BulletPoint
              showDots
              number="2"
              text={quickmatchFlow147t(
                'step1.textTwo',
                'Create your profile and account',
                undefined
              )}
            />
            <Styled.BulletPoint
              number="3"
              text={quickmatchFlow147t('step1.textThree', 'Connect with a therapist', undefined)}
            />
          </View>
        </View>
        <Styled.CTAContainer>
          {flags.ageLocationEligibility ? null : <NeedHelpPill />}
          <Styled.ContinueButton
            stretch
            text={quickmatchFlow147t('step1.button', 'Get started', undefined)}
            onPress={onSubmit}
            dataQa="getStartedButton"
          />
          {isValidConsenterReferralLink && (
            <Styled.LoginButton
              stretch
              variant="tertiary"
              text={quickmatchFlow147t('step1.loginButton', 'Log in', undefined)}
              dataQa="loginButton"
              onPress={() => {
                window.location.href = `${appConfigs.endpoints.clientWebEndpoint}/login`;
              }}
            />
          )}
        </Styled.CTAContainer>
      </Styled.Container>
    </EmotionThemeProvider>
  );
};
export default TeensHereToHelp;
